import { Fragment, useContext, useEffect, useState } from "react";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { AppContext } from "../../../context/AppContext";

import ConnectionError from "../../../components/connection-error/connectionError";

import Spinner from "../../../components/spinner/spinner";
import PageNavigator from "../pageNavigator/pageNavigator";

const SequencesTable = ({
    headerHeight,
    loading,
    results,
    onClickLink,
    showConnectionError,
    onTryAgain,
    pageNumber,
    navControlers,
    showPageNav,
    onClickNext,
    onClickPrevious,
}) => {
    const {
        appTheme,
        appLanguage,
        dropDownCartOpen,
        dropDownProfileMenuOpen,
        isTouchEnabled,
        getDateWithMonthShort,
    } = useContext(AppContext);

    const { desktopView } = useContext(SideMenuContext);

    const [tableHeight, setTableHeight] = useState("calc(100vh - 320px)");

    useEffect(() => {
        if (headerHeight) {
            setTableHeight(`calc(100vh - ${headerHeight + 90}px)`)
        }
    }, [headerHeight, desktopView]);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownCartOpen, dropDownProfileMenuOpen]);

    const borderDark = "2px solid rgba(255, 255, 255, 0.075)";
    // const borderLight = "2px solid rgba(0, 0, 0, 0.075)";

    const border2Dark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Light = "2px solid rgba(0, 0, 0, 0.075)";

    const widthPrefix = "80px";
    const widthNextNumber = "170px";
    const widthAvailable = "130px";
    const widthCreation = "130px";
    const widthExpiration = "130px";

    const isNotExpired = (time) => {
        const expirationDate = new Date(time);
        const today = new Date();
        return expirationDate > today;
    }
    
    const getNextNCF = (sequence) => {
        if (sequence && isNotExpired(sequence.expirationDate)) {
            if (sequence.ncfCanceled && sequence.ncfCanceled.length > 0 ) {
                return sequence.ncfCanceled[0];
            } else {
                if (sequence.currentNcf) {
                    return sequence.currentNcf;
                } else {
                    return appLanguage.en ? "None" : "Ninguno";
                }
            }
        } else {
            return appLanguage.en ? "None" : "Ninguno";
        }
    }

    return (
        <div
            className={`inventory-table-container unselectable ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
            style={{
                maxHeight: tableHeight,
                height: tableHeight,
                colorScheme: appTheme.dark ? "dark" : "light",
                borderRadius: "10px"
            }}
        >
            <div className="inventory-table-section-1" style={{ overflow: loading ? "hidden" : "auto" }}>
                {loading ?
                    <div style={{ marginTop: "50px", display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <Spinner />
                    </div>
                    :
                    (showConnectionError ?
                        <ConnectionError onTryAgain={onTryAgain} />
                        : <Fragment>
                            <table className="inventory-table-select-table">
                                <thead>
                                    <tr>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthPrefix, maxWidth: widthPrefix }}>
                                            <p>{appLanguage.en ? "Prefix" : "Prefijo"}</p>
                                        </th>

                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthNextNumber, maxWidth: widthNextNumber }}>
                                            <p>{appLanguage.en ? "Next NCF" : "Siguiente NCF"}</p>
                                        </th>

                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthAvailable, maxWidth: widthAvailable }}>
                                            <p>{appLanguage.en ? "Available" : "Disponibles"}</p>
                                        </th>

                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthCreation, maxWidth: widthCreation }}>
                                            <p>{appLanguage.en ? "Creation" : "Creación"}</p>
                                        </th>

                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthExpiration, maxWidth: widthExpiration, borderTopRightRadius: "10px" }}>
                                            <p>{appLanguage.en ? "Expiration" : "Vencimiento"}</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {results ?
                                        results.map((sequence, index) => {
                                            return (
                                                <tr
                                                    className={appTheme.dark ? (isTouchEnabled() ? "inventory-table-select-tr-dark-no-hover " : "inventory-table-select-tr-dark-hover") : (isTouchEnabled() ? "inventory-table-select-tr-light-no-hover" : "inventory-table-select-tr-light-hover")}
                                                    key={index}
                                                    onClick={() => onClickLink(sequence)}
                                                    style={{ cursor: "pointer" }}

                                                >
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthPrefix, maxWidth: widthPrefix }}>
                                                        <p style={{ whiteSpace: "nowrap", textAlign: "center" }}>{`${sequence.ncfFrom[0]}${sequence.ncfFrom[1]}${sequence.ncfFrom[2]}`}</p>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthNextNumber, maxWidth: widthNextNumber }}>
                                                        <p style={{ whiteSpace: "nowrap", textAlign: "center" }}>{getNextNCF(sequence)}</p>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthAvailable, maxWidth: widthAvailable }}>
                                                        <p style={{ whiteSpace: "nowrap", textAlign: "center" }}>{sequence.ncfQuantity}</p>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthCreation, maxWidth: widthCreation }}>
                                                        <p style={{ whiteSpace: "nowrap", textAlign: "center" }}>{getDateWithMonthShort(new Date(sequence.time))}</p>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthExpiration, maxWidth: widthExpiration }}>
                                                        <p style={{ whiteSpace: "nowrap", textAlign: "center" }}>{getDateWithMonthShort(new Date(sequence.expirationDate))}</p>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : null}
                                </tbody>
                            </table>
                        </Fragment>)}
            </div>
            <div>
                {/** Aquí va la navegacion */}
                {loading || showConnectionError ? null :
                    <PageNavigator
                        pageNumber={pageNumber}
                        navControlers={navControlers}
                        onChangePageNumber={null}
                        showPageNav={showPageNav}
                        onClickNext={onClickNext}
                        onClickPrevious={onClickPrevious}
                        empty={false}
                        elemMarginTo={"5px"}
                        elemmarginBottom={"5px"}
                    />
                }
            </div>
        </div>
    )
}

export default SequencesTable;