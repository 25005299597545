import "./successfulSale.page.scss";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';
import jsPDF from 'jspdf';

import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import completedIcon from "../../../icons/sale-completed.png";
import pendingIcon from "../../../icons/sale-pending.png";

import arrowIconDark from '../../../icons/arrow-small-right-white.png';

import crossIcon from "../../../icons/cross-white.png";
import crossIconLight from "../../../icons/cross.png";
import printIconDark from "../../../icons/printer-dark.png";

import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";

import sendIconDark from "../../../icons/share-with-dark.png";
import sendIconLight from "../../../icons/share-with-light.png";

import downloadIconDark from "../../../icons/download-dark.png";
import copyIconDark from "../../../icons/copy.png";
import emailIconDark from "../../../icons/email-dark.png";
import whatsAppIconDark from "../../../icons/whatsapp-icon-dark.png";

import copyIconLight from "../../../icons/copyLight.png";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

import SaleReceipt from "../../../receipts/saleReceipt";
import ShareViaExternalApp from "../../../appTools/appToolsComponents/shareViaExternalApp/shareViaExternalApp";
import { ShareViaExternalAppContext } from "../../../appTools/appToolsComponents/shareViaExternalApp/shareViaExternalAppContext";
import SaleReceiptPDF from "../../../receipts/saleReceiptPDF";
import { SoftLoadingContext } from "../../../components/soft-loading/softLoadingContext";

import firebase from "../../../firebase";
import { onSnapshot, doc } from "firebase/firestore";

const SuccessfulSalePage = () => {
    const { appTheme, appLanguage, isTouchEnabled, formatRationalNumber } = useContext(AppContext);
    const { setSoftLoadingActive } = useContext(SoftLoadingContext);
    const { accountData, accessTo, shift } = useContext(AuthContext);

    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const { setContactType, setShareViaExternalAppActive } = useContext(ShareViaExternalAppContext);

    let shareRef = useRef();

    const navigate = useNavigate();
    const location = useLocation();

    const [sale, setSale] = useState(null);
    const [ saleId, setSaleId ] = useState(null);
    const [customer, setCustomer] = useState(null);

    const [receiptData, setReceiptData] = useState(null);

    const [shareIcon, setShareIcon] = useState(appTheme.dark ? sendIconDark : sendIconLight);

    const [dropDownShareOpen, setDropDownShareOpen] = useState(false);

    useEffect(() => {
        if (dropDownShareOpen) {
            setShareIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setShareIcon(appTheme.dark ? sendIconDark : sendIconLight);
        }
    }, [dropDownShareOpen, appTheme]);

    const onClickShareButton = () => {
        dropDownShareOpen ? setDropDownShareOpen(false) : setDropDownShareOpen(true);
    }

    useEffect(() => {
        if (dropDownShareOpen && shareRef) {
            if (shareRef.current) {
                let handler = (e) => {
                    if (!shareRef.current.contains(e.target)) {
                        setDropDownShareOpen(false);
                    };
                };
                document.addEventListener("mousedown", handler);
                return () => {
                    document.removeEventListener("mousedown", handler);
                }
            }
        }
    });

    let pageObserverRef = useRef(null);

    const [pageWidth, setPageWidth] = useState(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return windowDimension.width;
            } else {
                try {
                    const width = pageObserverRef.current.offsetWidth
                    return width;
                } catch {
                    return windowDimension.width;
                }
            }
        } else {
            return windowDimension.width;
        }
    });

    useEffect(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return;
            }
            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (pageObserverRef.current.offsetWidth !== pageWidth) {
                        setPageWidth(pageObserverRef.current.offsetWidth);
                    }
                } catch { }
            });
            resizeObserver.observe(pageObserverRef.current);
            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [pageObserverRef.current, windowDimension, isSideMenuMinimized]);

    const copySaleId = () => {
        try {
            const text = sale.id;
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The code has been copied',
                    es: 'El código ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const onClose = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }

            if (accountData.useShifts) {
                if (shift !== null) {
                    if (!shift) {
                        navigate("/gestionar-turnos", { replace: true })
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [accountData, shift]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.sales)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        if (location.state) {
            setSale(location.state.sale);
            setCustomer(location.state.customer);
            if (location.state.sale) {
                setSaleId(location.state.sale.id);
            }
        } else {
            if (window.history.state && window.history.state.idx > 0) {
                navigate(-1);
            } else {
                navigate('/', { replace: true });
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (sale) {
            setReceiptData({
                ...sale.invoice,
                "comprobante": sale.comprobante ? sale.comprobante : null,
                "items": sale.items,
                "subtotal": sale.subtotal,
                "desc": sale.desc,
                "itbis": sale.itbis,
                "total": sale.total,
                "payments": sale.payments
            })
        } else {
            setReceiptData(null);
        }
        // eslint-disable-next-line
    }, [sale]);

    const receiptRef = useRef();
    const receiptRefPDF = useRef();

    const onClickShareOption = (option) => {
        if (sale && sale.invoiceLinkId) {
            setContactType(option)
            setShareViaExternalAppActive(true);
            setDropDownShareOpen(false);
        } else {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Link not found',
                    es: 'Enlace no encontrado',
                }
            });
        }
    }

    const onCopyInvoiceLink = () => {
        if (sale && sale.invoiceLinkId) {
            const invoiceLink = `https://factura.hazcuentas.com/?id=${sale.invoiceLinkId}`;
            try {
                navigator.clipboard.writeText(invoiceLink);
                setSoftAlertActive(true);
                setSoftAlertData({
                    type: 'sucess',
                    text: {
                        en: 'Invoice link has been copied',
                        es: 'El enlace de la factura ha sido copiado',
                    }
                });
                setDropDownShareOpen(false);
            } catch {
                setSoftAlertActive(true);
                setSoftAlertData({
                    type: 'error',
                    text: {
                        en: 'Copy error',
                        es: 'Error al copiar',
                    }
                });
            }
        } else {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Link not found',
                    es: 'Enlace no encontrado',
                }
            });
        }
    }

    const generatePDF = async () => {
        blinkSoftLoading();
        setDropDownShareOpen(false);
        try {
            const doc = new jsPDF('portrait', 'pt', 'letter');
            doc.html(receiptRefPDF.current, {
                callback: function (pdf) {
                    pdf.save(`Venta_${receiptData.saleId}.pdf`);
                },
                x: 65,
                y: 0,
                html2canvas: {
                    scale: 0.74
                }
            });

        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const onClickOpenSale = () => {
        if (sale) {
            navigate("/ver-detalles-de-venta", {state: {id: sale.id}})
        }
    }

    const blinkSoftLoading = () => {
        setSoftLoadingActive(true);
        setTimeout(() => {
            setSoftLoadingActive(false);
        }, 1000);
    }

    useEffect(() => {
        if (saleId) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/sales`, saleId), (doc) => {
                const saleData = {
                    ...doc.data(),
                    "id": doc.id,
                };

                if (doc.data()) {
                    setSale(saleData);
                }
            }, err => {
                console.error(err);
            });
            return () => unsub();
        }
    // eslint-disable-next-line
    }, [saleId]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Successful sale " : "Venta exitosa"} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef} className={`page-choose-account-item`}>
                            {accessTo.sales && sale ? <Fragment>
                                <ShareViaExternalApp
                                    pickerWidth={pageWidth}
                                    sale={sale}
                                    customer={customer}
                                />

                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Successful sale " : "Venta exitosa"}
                                    />
                                    : null}

                                <div
                                    style={{
                                        height: desktopView ? "calc(100vh - 107px)" : "calc(100vh - 65px)",
                                        colorScheme: appTheme.dark ? "dark" : "light",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                    }}
                                    className="customer-details-page-main-container"
                                >

                                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>

                                        <div className="sales-filter-botton-main unselectable" style={{ display: "flex", width: "100%", maxWidth: "622px", marginLeft: "30px" }}>

                                            <div ref={shareRef} className="notification-filter-botton-container unselectable">
                                                <button
                                                    style={{
                                                        marginTop: "20px",
                                                        minWidth: "150px"
                                                    }}
                                                    className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                    onClick={onClickShareButton}
                                                >
                                                    <img
                                                        src={shareIcon}
                                                        style={{
                                                            width: "18px",
                                                            height: "18px",
                                                            marginRight: "8px"
                                                        }}
                                                        alt="Share"
                                                    />
                                                    <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Share" : "Compartir"}</p>
                                                </button>

                                                <span
                                                    className={`notification-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownShareOpen ? 'active' : 'inactive'}`}
                                                    style={{
                                                        marginLeft: "80px",
                                                    }}
                                                />

                                                <div
                                                    style={{
                                                        boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                        marginLeft: "0px",
                                                        width: "220px",

                                                    }}
                                                    className={`dropdown-menu-notification ${appTheme.dark ? "dropdown-menu-notification-dark border-box-dark" : "dropdown-menu-notification-light border-box-light"} ${dropDownShareOpen ? 'active' : 'inactive'}`}
                                                >
                                                    <div
                                                        style={{
                                                            minHeight: "220px",
                                                            paddingTop: "13px"
                                                        }}
                                                    >
                                                        <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                            <button
                                                                className="invent-add-menu-btn"
                                                                onClick={generatePDF}
                                                                style={{
                                                                    justifyContent: "flex-start"
                                                                }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        marginRight: "10px",
                                                                        marginLeft: "18px"
                                                                    }}
                                                                    src={downloadIconDark}
                                                                    alt="Download"
                                                                />
                                                                <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "Download PDF" : "Descargar PDF"} </p>
                                                            </button>

                                                            <SaleReceiptPDF receiptData={receiptData} receiptRef={receiptRefPDF} />
                                                        </div>
                                                        <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                            <button
                                                                className="invent-add-menu-btn"
                                                                onClick={onCopyInvoiceLink}
                                                                style={{
                                                                    justifyContent: "flex-start"
                                                                }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        marginRight: "10px",
                                                                        marginLeft: "18px"
                                                                    }}
                                                                    src={copyIconDark}
                                                                    alt="Copy"
                                                                />
                                                                <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "Copy link" : "Copiar enlace"} </p>
                                                            </button>
                                                        </div>
                                                        <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                            <button
                                                                className="invent-add-menu-btn"
                                                                onClick={() => onClickShareOption("email")}
                                                                style={{
                                                                    justifyContent: "flex-start"
                                                                }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        marginRight: "10px",
                                                                        marginLeft: "18px"
                                                                    }}
                                                                    src={emailIconDark}
                                                                    alt="Email"
                                                                />
                                                                <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "Email" : "Correo"} </p>
                                                            </button>
                                                        </div>
                                                        <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                            <button
                                                                className="invent-add-menu-btn"
                                                                onClick={() => onClickShareOption("whatsapp")}
                                                                style={{
                                                                    marginBottom: "15px",
                                                                    justifyContent: "flex-start"
                                                                }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        marginRight: "10px",
                                                                        marginLeft: "18px"
                                                                    }}
                                                                    src={whatsAppIconDark}
                                                                    alt="WhatsApp"
                                                                />
                                                                <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "WhatsApp" : "WhatsApp"} </p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", width: "100%", justifyContent: "center", height: windowDimension.width > 550 ? "359px" : "570px" }}>
                                        <div
                                            style={{ display: "flex", width: "100%", maxWidth: "550px", marginTop: "25px" }}
                                            className={`settings-security ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}
                                        >
                                            <div style={{display: "flex", width: "100%", justifyContent: "flex-end"}}>
                                                <button
                                                    onClick={onClose}
                                                    className="successfull-sale-button-close"
                                                    style={{
                                                        maxWidth: "100px", 
                                                        marginTop: "-10px", 
                                                        marginRight: "-10px",
                                                        zIndex: 2,
                                                    }}
                                                >
                                                    <img src={appTheme.dark ? crossIcon : crossIconLight} alt="Close" />
                                                </button>
                                            </div>
                                            
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    flexWrap: windowDimension.width > 550 ? "nowrap" : "wrap",
                                                    marginTop: "-15px"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: windowDimension.width > 550 ? "50%" : "100%",
                                                        borderBottom: "1px solid rgba(128, 128, 128, 0.315)"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: "65px", height: "65px" }}
                                                        src={sale.status === "completed" ? completedIcon : pendingIcon}
                                                        alt="Sales status"
                                                    />

                                                    {sale.status === "completed" ?
                                                        <p style={{ marginTop: "15px", fontWeight: "bold" }}>{appLanguage.en ? "Sale completed" : "Venta completa"}</p>
                                                        :
                                                        <p style={{ marginTop: "15px", fontWeight: "bold" }}>{appLanguage.en ? "Pending sale" : "Venta pendiente"}</p>
                                                    }

                                                    <span
                                                        onClick={copySaleId}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                        className={`sale-copy-id-successful ${isTouchEnabled() ? "sale-copy-id-successful-no-hover" : "sale-copy-id-successful-hover"}`}
                                                    >

                                                        <p style={{ marginRight: "10px" }}>{sale.id}</p>

                                                        <img
                                                            src={appTheme.dark ? copyIconDark : copyIconLight}
                                                            style={{ width: "16px", height: "16px" }}
                                                            alt="Copy"
                                                        />
                                                    </span>

                                                    {windowDimension.width <= 550 ?
                                                        <button
                                                            className="invent-add-menu-btn"
                                                            onClick={onClickOpenSale}
                                                            style={{ margin: "0px", padding: "7px", width: "150px", marginBottom: "10px" }}
                                                        >
                                                            <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Open" : "Abrir"} </p>
                                                            <img src={arrowIconDark} style={{marginLeft: "10px", width: "16px", height: "16px"}} alt="Open sale" />
                                                        </button> 
                                                    : null}
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: windowDimension.width > 550 ? "50%" : "100%",
                                                        borderBottom: "1px solid rgba(128, 128, 128, 0.315)",
                                                        paddingBottom: "15px"
                                                    }}
                                                >

                                                    <p style={{ marginBottom: "10px", marginTop: "20px" }}><b>{appLanguage.en ? "Summary" : "Resumen"}</b></p>

                                                    <table cellPadding="10" cellSpacing="8" width="215px">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>Subtotal: </td>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", minWidth: "100px" }}>$ {formatRationalNumber(sale.subtotal)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap", }}>Desc{sale.discount ? (sale.discount.type === "percentage" ? ` ${sale.discount.value}%` : "") : ""}: </td>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", minWidth: "100px" }}>$ {formatRationalNumber(sale.desc)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>Itbis: </td>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", minWidth: "100px" }}>$ {formatRationalNumber(sale.itbis)}</td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>Total: </td>
                                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", minWidth: "100px" }}>$ {formatRationalNumber(sale.total)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    width: "100%",
                                                    justifyContent: "space-around",
                                                    marginTop: "30px",
                                                    marginBottom: "20px"
                                                }}
                                            >
                                                {windowDimension.width > 550 ?
                                                    <button
                                                        className="invent-add-menu-btn"
                                                        onClick={onClickOpenSale}
                                                        style={{ margin: "0px", width: "150px", padding: "10px" }}
                                                    >
                                                        <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "Open sale" : "Abrir venta"} </p>
                                                        <img src={arrowIconDark} style={{marginLeft: "10px", width: "16px", height: "16px"}} alt="Open sale" />
                                                    </button>
                                                : null}

                                                {receiptData && (<div onClick={() => blinkSoftLoading()}>
                                                    <ReactToPrint
                                                        trigger={() =>
                                                            <button
                                                                onClick={null}
                                                                style={{padding: "10px", width: "150px"}}
                                                                className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            >
                                                                <img src={printIconDark} alt="Print" />

                                                                {appLanguage.en ?
                                                                    <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>Print</p>
                                                                    :
                                                                    <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>Imprimir</p>
                                                                }
                                                            </button>
                                                        }
                                                        content={() => receiptRef.current}
                                                        documentTitle={`Venta_${receiptData.saleId}`}
                                                    />
                                                </div>)}
                                                <SaleReceipt receiptData={receiptData} receiptRef={receiptRef} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default SuccessfulSalePage;