import { Fragment, useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../context/AppContext';
import { SideMenuContext } from '../../../context/SideMenuContext';

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';

import SettingsTitle from '../../../components/settings-title/settingsTitle';
import pageIcon from '../../../img/logo192.png';

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import dominicanIcon from '../../../icons/dominican-republic.png';
import americanIcon from '../../../icons/united-states.png';

import './changeLanguage.page.scss';
import '../../../components/settings-options/settingsOption.scss';
import '../settings.page.scss';

const ChangeLanguagePage = () => {

    const { appLanguage, appTheme, setAppLanguaje, getCurrentLanguage, isTouchEnabled } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);

    useEffect(() => {
        setLanguageFormState(getFormLanguageState());
    // eslint-disable-next-line
    }, [appLanguage]);

    // Data to control Language
    let formLanguageTemp = {};
    const getFormLanguageState = () => {
        formLanguageTemp = {
            asInTheSystme : appLanguage.asInTheSystem,
            en: appLanguage.asInTheSystem ? false : appLanguage.en,
            es : appLanguage.asInTheSystem ? false : (appLanguage.en ? false : true)
        }
        return formLanguageTemp;
    }
    const [ languageformState, setLanguageFormState ] = useState(getFormLanguageState());

    const handleChangeLanguage = value => {
        if(value === "en") {
            formLanguageTemp.asInTheSystme = false;
            formLanguageTemp.en = true;
            formLanguageTemp.es = false;
        } else {
            if(value === "es") {
                formLanguageTemp.asInTheSystme = false;
                formLanguageTemp.en = false;
                formLanguageTemp.es = true;
            } else {
                formLanguageTemp.asInTheSystme = true;
                formLanguageTemp.en = false;
                formLanguageTemp.es = false;
            }
        }

        setLanguageFormState(formLanguageTemp);

        if(formLanguageTemp.asInTheSystme === true) {
            setAppLanguaje(getCurrentLanguage());
        } else {
            localStorage.setItem("language", JSON.stringify({
                en: formLanguageTemp.en,
                asInTheSystem: formLanguageTemp.asInTheSystme
            }));
            setAppLanguaje({
                en: formLanguageTemp.en,
                asInTheSystem: formLanguageTemp.asInTheSystme
            })
        }
    }

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Change language' : 'Cambiar idioma' } </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ? 
                        <SettingsHeader2 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "More options" : "Más opciones"}
                            textLink={"/ajustes"}
                            text2={appLanguage.en ? "Language" : "Idioma"}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitle
                                icon={pageIcon}
                                firstText={"HazCuentas v2.1"}
                                secondText={"República Dominicana 2025"}
                            />

                            <div className='change-language-title-container'>
                                <h4>{appLanguage.en ? "Choose preferred language:" : "Elija el idioma preferido:"}</h4>
                            </div>  
                            
                            <div
                                onClick={() => handleChangeLanguage("es")}
                                className={`settiongs-individual-option ${appTheme.dark ? `${isTouchEnabled() ? "change-language-btn-dark-no-hover" : "change-language-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "change-language-btn-light-no-hover" : "change-language-btn-light"} border-box-light`}`}
                            >
                                <div className='settings-individual-option-icons'>
                                    <img src={dominicanIcon}alt='Option' />
                                    <p htmlFor="systemLanguageDesktop" >{"Español"}</p>
                                </div>
                                <input 
                                    className='language-selection-input-sett'
                                    type="radio" 
                                    id="spanishLanguageDesktop" 
                                    name="fav_language" 
                                    checked={languageformState.es}
                                    value="es"
                                    onChange={() => handleChangeLanguage("es")}
                                />
                            </div>

                            <div 
                                onClick={() => handleChangeLanguage("en")}
                                className={`settiongs-individual-option ${appTheme.dark ? `${isTouchEnabled() ? "change-language-btn-dark-no-hover" : "change-language-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "change-language-btn-light-no-hover" : "change-language-btn-light"} border-box-light`}`}
                            >
                                <div className='settings-individual-option-icons'>
                                    <img src={americanIcon}alt='Option' />
                                    <p htmlFor="systemLanguageDesktop" >{"English"}</p>
                                </div>
                                <input 
                                    className='language-selection-input-sett'
                                    type="radio" 
                                    id="englishLanguageDesktop" 
                                    name="fav_language"
                                    checked={languageformState.en} 
                                    value="en"
                                    onChange={() => handleChangeLanguage("en")}
                                />
                            </div>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ChangeLanguagePage;