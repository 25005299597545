import "./collectPayment.scss";

import { Fragment, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";

import closeLightIcon from '../../../icons/cross.png';
import closeDarkIcon from '../../../icons/cross-white.png';

import checkIconDark from "../../../icons/check-dark.png";

import { AlertContext } from "../../../components/alert/alertContext";
import { AuthContext } from "../../../firebase/context";

import Spinner from "../../../components/spinner/spinner";
import { CollectPaymentContext } from "./collectPaymentContext";
import { useNavigate } from "react-router-dom";
import firebase from "../../../firebase";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

const CollectPaymentFromCart = ({ pickerWidth }) => {
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { appTheme, appLanguage, formatRationalNumber, getNumberFromCustomString } = useContext(AppContext);
    const { alertActive, setAlertData, setAlertActive, setOnAgree, getErrorDescription } = useContext(AlertContext);
    const { shoppingCart, accountData, deleteShoppingCart, userData, setSoldByInShoppingCart } = useContext(AuthContext);

    const {
        collectPaymentActive,
        setCollectPaymentActive,
        cashAmount,
        cashToCharge,
        setCashAmount,
        setCashToCharge,
        loading,
        setLoading,
        isCash,
        setIsCash,
        paymentMethod,
        setPaymentMethod,
        paymentNotes,
        setPaymentNotes,
        cashAmountAlert,
        amountToChargeAlert,
        setAmountToChargeAlert,
        setCashAmountAlert,
        amountSelected,
        getPendingAmount,
    } = useContext(CollectPaymentContext);

    let menuRef = useRef();

    const navigate = useNavigate();

    let inputRef = null;

    const setStartReference = (inputElement) => {
        inputRef = inputElement;
    }

    useEffect(() => {
        if (collectPaymentActive) {
            try {
                if (inputRef) {
                    // console.log(inputRef)
                    inputRef.focus();
                }
            } catch (error) {
                console.warn(error);
            }
        }
        // eslint-disable-next-line
    }, [collectPaymentActive]);

    useEffect(() => {
        if (collectPaymentActive) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    if ((!alertActive) && (!loading)) {
                        setCollectPaymentActive(false);
                    }
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickClose = () => {
        setCollectPaymentActive(false);
    }

    // cashToCharge
    const onChangeAmountToCharge = (e) => {
        setAmountToChargeAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setCashAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        const text = e.target.value;
        let amount = "";
        let isPoint = false;
        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if (isCash) {
                        if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                            if (isPoint) {
                                amount += "0";
                            } else {
                                amount += text[i];
                            }
                        }
                    } else {
                        if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                            amount += text[i];
                        }
                    }
                }
            }
        }

        setCashAmount(amount.trim() === "" ? "" : formatRationalNumber(amount));
        setCashToCharge(amount);
    }

    const onChangeCashAmount = (e) => {
        setCashAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        const text = e.target.value;
        let amount = "";
        let isPoint = false;
        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        if (isPoint) {
                            amount += "0";
                        } else {
                            amount += text[i];
                        }
                    }
                }
            }
        }
        setCashAmount(amount);
    }

    const formatAmount = () => {
        if (cashAmount && cashAmount.trim() !== "") {
            setCashAmount(formatRationalNumber(cashAmount));
        }
    }

    const formatAmountToCharge = () => {
        if (cashToCharge && cashToCharge.trim() !== "") {
            setCashToCharge(formatRationalNumber(cashToCharge));
        }
    }

    const getCustomerChange = () => {
        const change = getNumberFromCustomString(cashAmount) - getNumberFromCustomString(cashToCharge);
        if (getNumberFromCustomString(cashAmount) < getNumberFromCustomString(cashToCharge)) {
            return "0.00"
        } else {
            if (Number.isNaN(change)) {
                return "0.00"
            } else {
                return formatRationalNumber(change);
            }
        }
    }

    const getPaymentMethod = () => {
        if (isCash) {
            return appLanguage.en ? "in cash" : "en efectivo";
        } else {
            switch (paymentMethod) {
                case "card":
                    return appLanguage.en ? "with a credit or debit card" : "con tarjeta de crédito o débito";
                case "bankTransfer":
                    return appLanguage.en ? "via bank transfer" : "vía transferencia bancaria";
                case "link":
                    return appLanguage.en ? "through a payment link" : "a través de un enlace de pago";
                case "check":
                    return appLanguage.en ? "through a check" : "a través de un cheque";
                case "paypal":
                    return appLanguage.en ? "through PayPal" : "a través de PayPal";
                case "other":
                    return appLanguage.en ? "" : "";
                default:
                    return appLanguage.en ? "in cash" : "en efectivo";
            }
        }
    }

    const processPayment = async () => {
        setLoading(true);
        setAlertActive(false);

        try {
            let res = null;

            for (let i = 0; i < shoppingCart.items.length; i++) {
                const itemQuantity = shoppingCart.items[i].quantity;
                const productQuantity = shoppingCart.items[i].product.quantity;

                if (productQuantity !== null) {
                    if (itemQuantity > productQuantity) {
                        res = { msg: "thereAreNotEnough" };
                    }
                }
            }

            if (!res) {
                const paymentDetails = {
                    "cashAmount": getNumberFromCustomString(cashToCharge),
                    "paymentMethod": paymentMethod,
                    "paymentNotes": paymentNotes,
                    "amountSelected": amountSelected,
                }

                res = await firebase.useProcessSalePayment({
                    "shoppingCart": shoppingCart,
                    "businessId": accountData.id,
                    "paymentDetails": paymentDetails
                });
            }

            switch (res.msg) {
                case "ncfNotAvailable":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `NCFs not available`,
                            es: `NCFs agotados`,
                        },
                        code: "",
                        description: {
                            en: `There are no NCFs available for the selected comprobante fiscal. To add a new sequence go to: Invoices > Comprobantes.`,
                            es: `No hay NCFs disponibles para el comprobantes fiscal seleccionado. Para agregar nueva secuencia ir a: Facturas > Comporbantes.`,
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                case "unauthorizedDiscount":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Without authorization to apply discounts`,
                            es: `Sin autorización para aplicar descuentos`,
                        },
                        code: "",
                        description: {
                            en: `You are not authorized to apply a discount. Please refresh the page and try again.`,
                            es: `No tienes autorización para aplicar un descuento. Por favor, refresque la página y trate de nuevo. `
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                case "customerDoesNotExist":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Client does not exist`,
                            es: `Cliente no existe`
                        },
                        code: "",
                        description: {
                            en: `The customer selected for this sale does not exist. Please refresh the page, check the shopping cart and try again.`,
                            es: `El cliente seleccionado para esta venta no existe. Por favor, refresque la página, revisa el carrito de compras y trate de nuevo.`
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                case "productDoesNotExist":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Product does not exist`,
                            es: `Producto no existe`
                        },
                        code: "",
                        description: {
                            en: `One or more of the products you are trying to sell does not exist. Please refresh the page and try again.`,
                            es: `Uno o más de los productos que intenta vender no existen. Por favor, refresque la página e inténtelo de nuevo.`
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                case "productOutOfStock":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Product out of stock`,
                            es: `Producto agotado`
                        },
                        code: "",
                        description: {
                            en: `One or more of the items in your shopping cart are out of stock. Please refresh the page, return to cart and then remove those items.`,
                            es: `Uno o más de los artículos del carrito de compras están agotados. Por favor, refresque la página, regrese al carrito y luego remueva dichos artículos.`
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                case "thereAreNotEnough":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `There are not enough in inventory`,
                            es: `No hay suficientes en el inventario`
                        },
                        code: "",
                        description: {
                            en: `There are not enough units of one or more items. Please refresh the page, return to cart and adjust the quantity of said item if it is in red.`,
                            es: `No hay suficientes unidades de uno o más artículos. Por favor, refresque la página, regrese al carrito y ajuste la cantidad de dichos artículo si está en rojo.`
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                case "inconsistentPrice":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Inconsistent prices`,
                            es: `Precios inconsistentes`,
                        },
                        code: "",
                        description: {
                            en: `The data you are sending and the data processed by the server are inconsistent, please refresh the page, check the prices and try again.`,
                            es: `Los datos que estás enviando y los datos procesados ​​por el servidor son inconsistentes, por favor refresque la página, verifique los precios y trate de nuevo.`
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                case "authorizedUserNotFound":
                    setSoldByInShoppingCart(userData ? userData.id : null);
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Authorized user not found`,
                            es: `Usuario autorizado no encontrado`,
                        },
                        code: "",
                        description: {
                            en: `The salesperson you are trying to assign this sale to was not found in this business account. Please refresh the page, ensure you select a valid authorized user, and try again.`,
                            es: `El vendedor al que intentas asignar esta venta no fue encontrado en esta cuenta de negocios. Por favor, refresca la página, asegúrate de seleccionar un usuario autorizado válido y vuelve a intentarlo.`
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                    break;
                default:
                    setLoading(false);

                    setCollectPaymentActive(false);
                    const newState = {
                        sale: res.sale,
                        customer: shoppingCart.customer,
                    }

                    deleteShoppingCart();
                    navigate("/venta-exitosa", { state: newState });

                    setSoftAlertActive(true);
                    setSoftAlertData({
                        type: 'sucess',
                        text: {
                            en: 'Payment successful.',
                            es: 'Pago exitoso.',
                        }
                    });

                    break;
            }
        } catch (error) {
            const code = "error";
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to process payment`,
                    es: `Error al intentar procesar el pago`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    const onSaveProcess = () => {
        if (!(getNumberFromCustomString(cashToCharge) === 0 && getMaxAmountToCharge(shoppingCart) === 0)) {
            if (
                cashToCharge.trim() === "" ||
                (getNumberFromCustomString(cashToCharge) > getMaxAmountToCharge(shoppingCart)) ||
                (getNumberFromCustomString(cashToCharge) <= 0)
            ) {
                setAmountToChargeAlert({
                    alert: true,
                    enText: 'Required field',
                    esText: 'Campo requerido'
                });
                return;
            }

            if (isCash) {
                if (cashAmount.trim() === "" || (getNumberFromCustomString(cashAmount) < getNumberFromCustomString(cashToCharge))) {
                    setCashAmountAlert({
                        alert: true,
                        enText: 'Required field',
                        esText: 'Campo requerido'
                    });
                    return;
                }
            }
        }

        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        setAlertData({
            type: 'question',
            title: {
                en: `Process payment?`,
                es: `¿Procesar pago?`
            },
            code: '',
            description: {
                en: `Are you sure you want to save a payment ${getPaymentMethod()} for RD$ ${formatRationalNumber(cashToCharge)}?`,
                es: `¿Estás seguro de que deseas guardar un pago ${getPaymentMethod()} por RD$ ${formatRationalNumber(cashToCharge)}?`
            }
        });
        setOnAgree(() => processPayment);
        setAlertActive(true);
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSaveProcess();
        }
    }

    const onClickCash = () => {
        if (!isCash) {
            setIsCash(true);
            setCashAmountAlert({
                alert: false,
                enText: '',
                esText: ''
            });
            setAmountToChargeAlert({
                alert: false,
                enText: '',
                esText: ''
            });

            // Round
            if (shoppingCart) {
                if (cashToCharge.trim() !== "") {
                    setCashAmount(formatRationalNumber(Math.round(getNumberFromCustomString(cashToCharge))));
                    setCashToCharge(formatRationalNumber(Math.round(getNumberFromCustomString(cashToCharge))));
                }
            }
        }
    }

    const onClickAnother = () => {
        if (isCash) {
            setIsCash(false);
            setCashAmountAlert({
                alert: false,
                enText: '',
                esText: ''
            });
            setAmountToChargeAlert({
                alert: false,
                enText: '',
                esText: ''
            });

            if (amountSelected === "fullAmount") {
                // Remove rounding
                setCashAmount(formatRationalNumber(getPendingAmount(shoppingCart)));
                setCashToCharge(formatRationalNumber(getPendingAmount(shoppingCart)));
            }
        }
    }

    const onChangePaymentMethod = (e) => {
        setPaymentMethod(e.target.value);
    }

    const onChangePaymentNotes = (e) => {
        setPaymentNotes(e.target.value);
    }

    const getMaxAmountToCharge = (shoppingCart) => {
        if (isCash) {
            return Math.round(getPendingAmount(shoppingCart));
        } else {
            return getPendingAmount(shoppingCart)
        }
    }

    return (
        <div
            style={{
                WebkitUserSelect: "auto",
                WebkitTouchCallout: "auto",
                MozUserSelect: "auto",
                MsUserSelect: "auto",
                userSelect: "auto",
            }}
        >
            {collectPaymentActive ? <div className='picker-date-lock-screen' style={{ width: pickerWidth, minHeight: "calc(100vh - 65px)" }} /> : null}
            <div ref={menuRef} className={`picker-date-genaral-card ${collectPaymentActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pickerWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                {shoppingCart ? (shoppingCart.items.length > 0 ?
                    <div className='picker-date-general-despcrition' style={{ display: "flex", justifyContent: "space-between", minHeight: "280px" }}>
                        {loading ?
                            <div style={{ marginTop: "40px" }}><Spinner /></div>
                            :
                            <Fragment>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "5px" }}>
                                    <h4 style={{ marginLeft: "15px" }}>
                                        <span style={{ fontWeight: "600" }}>{appLanguage.en ? "You must charge" : "Debes cobrar"}:</span>
                                    </h4>

                                    <button
                                        onClick={onClickClose}
                                        className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                    >
                                        <img
                                            className="see-details-transations-img"
                                            src={appTheme.dark ? closeDarkIcon : closeLightIcon}
                                            alt="close"
                                        />
                                    </button>
                                </div>

                                <p
                                    style={{
                                        fontSize: "20px",
                                        textAlign: "center",
                                        fontWeight: "600",
                                        marginBottom: "35px"
                                    }}
                                >
                                    RD$ {formatRationalNumber(cashToCharge)}
                                </p>

                                <div className="unselectable">
                                    <div className="coll-payme-me-cont" style={{ marginBottom: "20px" }}>
                                        <button onClick={onClickCash} className={`coll-payme-me-btn-left ${isCash ? (appTheme.dark ? "coll-payme-me-btn-active-dark" : "coll-payme-me-btn-active-light") : (appTheme.dark ? "coll-payme-me-btn-inactive-dark" : "coll-payme-me-btn-inactive-light")}`}>{appLanguage.en ? "Cash" : "Efectivo"}</button>
                                        <button onClick={onClickAnother} className={`coll-payme-me-btn-right ${!isCash ? (appTheme.dark ? "coll-payme-me-btn-active-dark" : "coll-payme-me-btn-active-light") : (appTheme.dark ? "coll-payme-me-btn-inactive-dark" : "coll-payme-me-btn-inactive-light")}`}>{appLanguage.en ? "Other" : "Otro"}</button>
                                    </div>
                                </div>

                                {amountSelected !== "fullAmount" ?
                                    <div style={{ margin: "8px 15px", marginBottom: "20px" }}>
                                        <p>
                                            {appLanguage.en ? "Amount to be charged " : "Monto a cobrar "}
                                            (<b>{appLanguage.en ? `max:` : `máx: `} {formatRationalNumber(getMaxAmountToCharge(shoppingCart))}</b>)
                                        </p>
                                        <input
                                            value={cashToCharge}
                                            onChange={(e) => onChangeAmountToCharge(e)}
                                            onKeyDown={e => handleKeyPress(e)}
                                            onBlur={formatAmountToCharge}
                                            inputMode="numeric"
                                            maxLength={10}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            placeholder="RD$ 0.00"
                                            style={{
                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                color: appTheme.dark ? "white" : "black"
                                            }}
                                            className={`cash-in-cash-reg-input ${amountToChargeAlert.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                        />
                                    </div>
                                    : null}

                                {isCash ?
                                    <div style={{ margin: "8px 15px" }}>
                                        <p>{appLanguage.en ? "Cash received" : "Efectivo recibido"}</p>
                                        <input
                                            value={cashAmount}
                                            onChange={(e) => onChangeCashAmount(e)}
                                            onKeyDown={e => handleKeyPress(e)}
                                            onBlur={formatAmount}
                                            inputMode="numeric"
                                            maxLength={10}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            placeholder="RD$ 0.00"
                                            style={{
                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                color: appTheme.dark ? "white" : "black"
                                            }}
                                            className={`cash-in-cash-reg-input ${cashAmountAlert.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                        />

                                        <p style={{ marginTop: "25px" }}>{appLanguage.en ? "Customer Change" : "Devuleta del cliente"}:</p>
                                        <p style={{ marginTop: "5px" }}><b>RD$ {formatRationalNumber(getCustomerChange())}</b></p>
                                    </div>
                                    :
                                    <div style={{ margin: "8px 15px" }}>
                                        <p>{appLanguage.en ? "Payment method" : "Método de pago"}</p>
                                        <select
                                            className={`cash-in-cash-reg-input ${appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light"}`}
                                            style={{
                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                color: appTheme.dark ? "white" : "black",
                                                width: "100%"
                                            }}
                                            name="paymentMethod"
                                            id="paymentMethod"
                                            value={paymentMethod}
                                            onChange={(e) => onChangePaymentMethod(e)}
                                        >
                                            <option value="card">{appLanguage.en ? "Credit/debit card" : "Tarjeta de crédito/débito"}</option>
                                            <option value="bankTransfer">{appLanguage.en ? "Bank transfer" : "Transferencia bancaria"}</option>
                                            <option value="link">{appLanguage.en ? "Payment link" : "Enlace de pago"}</option>
                                            <option value="check">{appLanguage.en ? "Check" : "Cheque"}</option>
                                            <option value="paypal">PayPal</option>
                                            <option value="other">{appLanguage.en ? "None of the above" : "Ninguno de los anteriores"}</option>
                                        </select>

                                        <p style={{ marginTop: "25px" }}>{appLanguage.en ? "Note (optional)" : "Nota (opcional)"}:</p>
                                        <input
                                            ref={setStartReference}
                                            value={paymentNotes}
                                            onChange={(e) => onChangePaymentNotes(e)}
                                            onKeyDown={e => handleKeyPress(e)}
                                            inputMode="text"
                                            maxLength={35}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            placeholder={appLanguage.en ? "Reference number or description" : "Número de referencia o descripción"}
                                            style={{
                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                color: appTheme.dark ? "white" : "black"
                                            }}
                                            className={`cash-in-cash-reg-input ${appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light"}`}
                                        />
                                    </div>
                                }

                                <div className="unselectable" style={{ display: "flex", flexDirection: "column" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "30px",
                                            marginBottom: "15px"
                                        }}
                                    >
                                        <button
                                            onClick={onSaveProcess}
                                            className="cash-in-cash-reg-bottons-button-save"
                                            style={{ width: "190px", maxWidth: "190px" }}
                                        >
                                            <img src={checkIconDark} style={{ marginRight: "10px" }} alt="End shift" />
                                            {appLanguage.en ? <p>Confirm</p> : <p>Confirmar</p>}
                                        </button>
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </div>
                    : null) : null}
            </div>
        </div>
    )
}

export default CollectPaymentFromCart;