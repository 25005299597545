import "./shoppingCart.page.scss";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { AlertContext } from "../../../components/alert/alertContext";

import crossIcon from "../../../icons/cross.png";
import crossWhiteIcon from "../../../icons/cross-white.png";

import magnifyingIcon from "../../../icons/magnifying-glass.png";
import magnifyingIconBlack from "../../../icons/magnifying-glass-back.png";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import addImgDark from "../../../icons/plus-mini-dark.png";
import addImgLight from "../../../icons/plus-mini-light.png";

import arrowSmallWhiteIcon from "../../../icons/arrow-small-right-white.png";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import plusIconDark from "../../../icons/plus-dark.png";
import plusIconLight from "../../../icons/plus-light.png";

import minusIconDark from "../../../icons/minus-dark.png";
import minusIconLight from "../../../icons/minus-light.png";

import emptyBox from "../../../img/empty-box.svg";

import deleteIcon from "../../../icons/closed-dark.png";
import deleteIconLight from "../../../icons/closed-light.png";
import dollarSighDarkIcon from "../../../icons/dollar-sign-dark.png";

import noPicture from "../../../img/no-picture.png";

import shiftDark from "../../../icons/shift-dark.png";

import { ref, getDownloadURL } from "firebase/storage";
import firebase from "../../../firebase";
import SalesPicker from "../../../appTools/appToolsComponents/salesPicker/salesPicker";
import CollectPaymentFromCart from "../../../appTools/appToolsComponents/collectPayment/collectPaymentFromCart";
import { CollectPaymentContext } from "../../../appTools/appToolsComponents/collectPayment/collectPaymentContext";

const ShoppingCartPage = () => {

    let pageObserverRef = useRef(null);
    const location = useLocation();

    const { appTheme, appLanguage, isTouchEnabled, formatRationalNumber } = useContext(AppContext);
    const { accountData, accessTo, shift, shoppingCart, deleteShoppingCart, removeCustomerFromShoppingCart, updateShoppingCartInfoFromServer } = useContext(AuthContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);
    const { setCashAmount, setCashToCharge, setCollectPaymentActive, setAmountSelected } = useContext(CollectPaymentContext);

    const { setAlertData, setAlertActive, setOnAgree } = useContext(AlertContext);

    // This prevent the page send a request twice to the backend
    const [generalState, setGeneralState] = useState(false);

    useEffect(() => {
        if (accountData && generalState) {
            updateShoppingCartInfoFromServer();
        }
        // eslint-disable-next-line
    }, [accountData, generalState]);

    const navigate = useNavigate();

    const [showingSearch, setShowingSearch] = useState(false);

    useEffect(() => {
        if (location.state) {
            setShowingSearch(true);
        }
        setGeneralState(true);
        // eslint-disable-next-line 
    }, []);

    const [pageWidth, setPageWidth] = useState(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return windowDimension.width;
            } else {
                try {
                    const width = pageObserverRef.current.offsetWidth
                    return width;
                } catch {
                    return windowDimension.width;
                }
            }
        } else {
            return windowDimension.width;
        }
    });

    useEffect(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (pageObserverRef.current.offsetWidth !== pageWidth) {
                        setPageWidth(pageObserverRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(pageObserverRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [pageObserverRef.current, windowDimension, isSideMenuMinimized]);

    const getFlexWrapForSearch = () => {
        if (pageWidth > 940) {
            return "nowrap";
        } else {
            if (pageWidth > 540) {
                if (pageWidth >= 760) {
                    return "wrap"
                } else {
                    return "nowrap";
                }
            } else {
                return "wrap"
            }
        }
    }

    const isCartEmpty = () => {
        if (shoppingCart) {
            if (shoppingCart.items.length > 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    const isCustomerSelected = () => {
        if (shoppingCart) {
            if (shoppingCart.customer) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const deleteCart = () => {
        setAlertActive(false);
        deleteShoppingCart();
    }

    const onDeleteCart = () => {
        setAlertData({
            type: 'question',
            title: {
                en: `Delete shopping cart?`,
                es: `¿Eliminar carrito de compras?`
            },
            code: '',
            description: {
                en: `Are you sure you want to remove all items from the shopping cart?`,
                es: `¿Está seguro de que desea eliminar todos los artículos del carrito de compras?`
            }
        });
        setOnAgree(() => deleteCart);
        setAlertActive(true);
    }


    const onShowSearch = () => {
        setShowingSearch(!showingSearch);
    }

    const onRemoveCustomer = () => {
        removeCustomerFromShoppingCart();
    }

    const onOpenCustomer = () => {
        if (isCustomerSelected()) {
            navigate("/ver-cliente", { state: shoppingCart.customer })
        }
    }

    const onManageShifts = () => {
        navigate("/gestionar-turnos")
    }

    const onClickProcessSale = () => {
        navigate("/resumen-de-venta")
    }

    const onClickCreateCustomer = () => {
        const newState = {
            "task": "shoppingCart"
        }
        navigate("/agregar-cliente", { state: newState })
    }

    const onClickCreateProduct = () => {
        const newState = {
            "task": "shoppingCart"
        }
        navigate("/agregar-producto", { state: newState });
    }

    const onCollectPayment = () => {
        setCollectPaymentActive(true);
        if (shoppingCart) {
            setCashAmount(formatRationalNumber(Math.round(shoppingCart.total)));
            setCashToCharge(formatRationalNumber(Math.round(shoppingCart.total)));
            setAmountSelected("fullAmount");
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }

            if (accountData.useShifts) {
                if (shift !== null) {
                    if (!shift) {
                        navigate("/gestionar-turnos", { replace: true })
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [accountData, shift]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.sales)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        if (!showingSearch) {
            navigate(null, { replace: true, state: null });
        }
        // eslint-disable-next-line
    }, [showingSearch])

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Shopping cart" : "Carrito de compras"} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef} className={`page-choose-account-item`}>
                            {accessTo.sales ? <Fragment>
                                <CollectPaymentFromCart pickerWidth={pageWidth} />

                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Shopping cart" : "Carrito de compras"}
                                    />
                                : null}

                                {/** Si es vista mobile mostar boton grande aquí */}
                                {pageWidth < 760 ?
                                    <div
                                        className={`${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                        style={{
                                            display: "flex",
                                            marginTop: desktopView ? "10px" : "",
                                            padding: "15px",
                                            width: "calc(100% - 30px)",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        {accountData.useShifts ?
                                            <button
                                                onClick={onManageShifts}
                                                className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                style={{
                                                    margin: "0px 10px",
                                                    padding: "8px 15px",
                                                    height: "37.6px",
                                                    minHeight: "37.6px",
                                                }}
                                            >
                                                <img style={{ width: "20px", height: "20px", marginLeft: pageWidth <= 480 ? "10px" : "" }} src={shiftDark} alt="Shifts" />
                                                {pageWidth > 480 ?
                                                    appLanguage.en ? <p style={{ whiteSpace: "nowrap" }}>Shifts</p> : <p style={{ whiteSpace: "nowrap" }}>Turnos</p>
                                                    : null}
                                            </button>
                                            : null}

                                        <button
                                            onClick={onDeleteCart}
                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                            disabled={isCartEmpty()}
                                            style={isCartEmpty() ? {
                                                margin: "0px 10px",
                                                backgroundColor: "#3f526698",
                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                cursor: "not-allowed",
                                                padding: "8px 15px",
                                                height: "37.6px",
                                                minHeight: "37.6px",
                                            } : {
                                                margin: "0px 10px",
                                                padding: "8px 15px",
                                                height: "37.6px",
                                                minHeight: "37.6px",
                                            }}
                                        >
                                            <img style={{ marginLeft: pageWidth <= 480 ? "10px" : "" }} src={deleteIcon} alt="Delete" />
                                            {pageWidth > 480 ?
                                                appLanguage.en ? <p style={{ whiteSpace: "nowrap" }}>Delete cart</p> : <p style={{ whiteSpace: "nowrap" }}>Borrar carrito</p>
                                                : null}
                                        </button>

                                        <button onClick={onShowSearch} className={`shopping-cart-search-for-btn ${isTouchEnabled() ? (appTheme.dark ? "shopping-cart-search-for-btn-dark-no-hover" : "shopping-cart-search-for-btn-light-no-hover") : (appTheme.dark ? "shopping-cart-search-for-btn-dark-hover" : "shopping-cart-search-for-btn-light-hover")}`}>
                                            <p>{appLanguage.en ? "Search" : "Buscar"}</p>
                                            <img src={showingSearch ? (appTheme.dark ? crossWhiteIcon : crossIcon) : (appTheme.dark ? magnifyingIcon : magnifyingIconBlack)} alt="Search box" />
                                        </button>
                                    </div>
                                    : null}

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%",
                                        height: desktopView ? `calc(100vh - 107px ${pageWidth < 760 ? "- 78px" : ""} )` : `calc(100vh - 66px ${pageWidth < 760 ? "- 67px" : ""})`,
                                    }}
                                >
                                    <div style={{ display: "flex", width: "100%" }}>
                                        {showingSearch || pageWidth >= 760 ?
                                            <SalesPicker
                                                getFlexWrapForSearch={getFlexWrapForSearch}
                                                pageWidth={pageWidth}
                                                showingSearch={showingSearch}
                                                setShowingSearch={setShowingSearch}
                                            />
                                            : null}

                                        {(!showingSearch) || pageWidth >= 760 ?
                                            <div
                                                className={`shopping-cart-cart-section`}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    width: pageWidth < 760 ? "100%" : "400px",
                                                    maxWidth: pageWidth < 760 ? "100%" : "400px",
                                                    minWidth: pageWidth < 760 ? "100%" : "400px",
                                                    height: "100%",
                                                    borderLeft: pageWidth >= 760 ? "solid 2px rgba(128, 128, 128, 0.568)" : "",
                                                }}
                                            >
                                                {pageWidth >= 760 ?
                                                    <div
                                                        className="unselectable"
                                                        style={{
                                                            display: "flex",
                                                            paddingLeft: "20px",
                                                            justifyContent: "flex-start",
                                                            marginTop: "10px"
                                                        }}
                                                    >
                                                        {accountData.useShifts ?
                                                            <button
                                                                onClick={onManageShifts}
                                                                className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                style={{
                                                                    margin: "10px 22px 10px 0px",
                                                                    padding: "8px 15px"
                                                                }}
                                                            >
                                                                <img style={{ width: "20px", height: "20px" }} src={shiftDark} alt="Shifts" />
                                                                {appLanguage.en ? <p style={{ whiteSpace: "nowrap" }}>Shifts</p> : <p style={{ whiteSpace: "nowrap" }}>Turnos</p>}
                                                            </button>
                                                            : null}

                                                        <button
                                                            onClick={onDeleteCart}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            disabled={isCartEmpty()}
                                                            style={isCartEmpty() ? {
                                                                margin: "10px 5px 10px 0px",
                                                                backgroundColor: "#3f526698",
                                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                cursor: "not-allowed",
                                                                padding: "8px 15px"
                                                            } : {
                                                                margin: "10px 5px 10px 0px",
                                                                padding: "8px 15px"
                                                            }}
                                                        >
                                                            <img src={deleteIcon} alt="Delete" />
                                                            {appLanguage.en ? <p style={{ whiteSpace: "nowrap" }}>Delete cart</p> : <p style={{ whiteSpace: "nowrap" }}>Borrar carrito</p>}
                                                        </button>
                                                    </div>
                                                    : null}

                                                <div
                                                    className={`nav-container-cart-items ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
                                                    style={{
                                                        borderLeft: "none",
                                                        borderRight: "none",
                                                        height: desktopView ? `calc(100vh - 107px ${pageWidth < 760 ? "- 78px" : ""} )` : `calc(100vh - 65px ${pageWidth < 760 ? "- 78px" : ""})`,
                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                        overflow: "auto",
                                                        overflowY: "scroll",
                                                        marginBottom: 0,
                                                        paddingBottom: "10px"
                                                    }}
                                                >
                                                    {/** Customer information */}
                                                    <div
                                                        className="unselectable"
                                                        style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            marginTop: "15px",
                                                            flexDirection: "column"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                width: "calc(100% - 40px)",
                                                                alignItems: "center",
                                                                padding: "10px 20px",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <p><b>{appLanguage.en ? "Customer" : "Cliente"}</b></p>
                                                            <button
                                                                onClick={onClickCreateCustomer}
                                                                className={`${isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"} ${isTouchEnabled() ? (appTheme.dark ? "shopping-cart-search-for-btn-dark-no-hover" : "shopping-cart-search-for-btn-light-no-hover") : (appTheme.dark ? "shopping-cart-search-for-btn-dark-hover" : "shopping-cart-search-for-btn-light-hover")}`}
                                                                style={{ padding: "8px 10px", marginLeft: "20px" }}
                                                            >
                                                                <img src={appTheme.dark ? addImgDark : addImgLight} alt="Delete" />
                                                                {appLanguage.en ? <p style={{ whiteSpace: "nowrap" }}>Create customer</p> : <p style={{ whiteSpace: "nowrap" }}>Crear cliente</p>}
                                                            </button>
                                                        </div>

                                                        {isCustomerSelected() ?
                                                            <div
                                                                className="shoppo-cart-cust-card-div"
                                                                style={{
                                                                    display: "flex",
                                                                    margin: "10px 20px 0 20px",
                                                                    width: "calc(100% - 40px)",
                                                                    justifyContent: "space-between",
                                                                }}
                                                            >

                                                                <div
                                                                    onClick={onOpenCustomer}
                                                                    className={`${appTheme.dark ? (isTouchEnabled() ? "shoppo-cart-cust-card-div-dark-NOHOVER" : "shoppo-cart-cust-card-div-dark-HOVER") : (isTouchEnabled() ? "shoppo-cart-cust-card-div-light-NOHOVER" : "shoppo-cart-cust-card-div-light-HOVER")}`}
                                                                    style={{
                                                                        display: "flex",
                                                                        borderTopLeftRadius: "10px",
                                                                        borderBottomLeftRadius: "10px",
                                                                        paddingLeft: "10px",
                                                                        width: "100%",
                                                                        alignItems: "center",
                                                                        minHeight: "40px",
                                                                    }}
                                                                >
                                                                    <p
                                                                        style={{
                                                                            "display": "-webkit-box",
                                                                            "WebkitBoxOrient": "vertical",
                                                                            "WebkitLineClamp": "1",
                                                                            "lineClamp": "1",
                                                                            "overflow": "hidden",
                                                                            "textOverflow": "ellipsis"
                                                                        }}
                                                                    >
                                                                        {shoppingCart.customer.id} • {shoppingCart.customer.name}
                                                                    </p>
                                                                </div>

                                                                <div
                                                                    onClick={onRemoveCustomer}
                                                                    className={`${appTheme.dark ? (isTouchEnabled() ? "shoppo-cart-cust-card-div-dark-NOHOVER" : "shoppo-cart-cust-card-div-dark-HOVER") : (isTouchEnabled() ? "shoppo-cart-cust-card-div-light-NOHOVER" : "shoppo-cart-cust-card-div-light-HOVER")}`}
                                                                    style={{
                                                                        display: "flex",
                                                                        borderTopRightRadius: "10px",
                                                                        borderBottomRightRadius: "10px",
                                                                        width: "50px",
                                                                        minWidth: "70px",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        minHeight: "40px",
                                                                    }}
                                                                >
                                                                    <img style={{ width: "20px", height: "20px" }} src={appTheme.dark ? deleteIcon : deleteIconLight} alt="Delete" />
                                                                </div>

                                                            </div>
                                                            :
                                                            <div
                                                                className={`shoppo-cart-cust-card-div ${appTheme.dark ? "shoppo-cart-cust-card-div-dark" : "shoppo-cart-cust-card-div-light"}`}
                                                                style={{
                                                                    display: "flex",
                                                                    margin: "10px 20px 0 20px",
                                                                    paddingLeft: "10px",
                                                                    width: "calc(100% - 40px - 10px)"
                                                                }}
                                                            >
                                                                <p>{appLanguage.en ? "End consumer (default)" : "Consumidor final (predeterminado)"}</p>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div
                                                        className="unselectable"
                                                        style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            marginTop: "22px",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                width: "calc(100% - 40px)",
                                                                alignItems: "center",
                                                                padding: "10px 20px",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <p><b>{appLanguage.en ? "Items" : "Artículos"} {isCartEmpty() ? "" : `(${shoppingCart.items.length})`}</b></p>

                                                            {accessTo.inventory ?
                                                                <button
                                                                    onClick={onClickCreateProduct}
                                                                    className={`${isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"} ${isTouchEnabled() ? (appTheme.dark ? "shopping-cart-search-for-btn-dark-no-hover" : "shopping-cart-search-for-btn-light-no-hover") : (appTheme.dark ? "shopping-cart-search-for-btn-dark-hover" : "shopping-cart-search-for-btn-light-hover")}`}
                                                                    style={{ padding: "8px 10px", marginLeft: "20px" }}
                                                                >
                                                                    <img src={appTheme.dark ? addImgDark : addImgLight} alt="Delete" />
                                                                    {appLanguage.en ? <p style={{ whiteSpace: "nowrap" }}>Create product</p> : <p style={{ whiteSpace: "nowrap" }}>Crear producto</p>}
                                                                </button>
                                                                : null}
                                                        </div>

                                                        {isCartEmpty() ?
                                                            <div className="nav-container-cart-empty">
                                                                <img src={emptyBox} alt="Empty box" />
                                                                <p>{appLanguage.en ? "Empty shopping cart" : "Carrito de compras vacío"}</p>
                                                            </div>
                                                            :
                                                            (shoppingCart.items.map((item) => {
                                                                return (
                                                                    <Item
                                                                        key={item.product.id}
                                                                        product={item.product}
                                                                        quantity={item.quantity}
                                                                        optimizedForMobile={false}
                                                                    />
                                                                );
                                                            }))
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    className={`${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                                    style={{
                                                        display: "flex",
                                                        width: "calc(100% - 30px)",
                                                        justifyContent: "center",
                                                        padding: "15px",
                                                        paddingBottom: "30px",
                                                        flexDirection: "column",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    {isCartEmpty() ? null : <p style={{ marginBottom: "10px", fontSize: "18px" }}>Total: <b>RD$ {formatRationalNumber(shoppingCart.total)}</b></p>}

                                                    <div style={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
                                                        <button
                                                            onClick={onCollectPayment}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            disabled={isCartEmpty()}
                                                            style={isCartEmpty() ? {
                                                                backgroundColor: "#3f526698",
                                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                cursor: "not-allowed",
                                                                margin: "0px 22px 0px 0px",
                                                                height: "40px",
                                                                minWidth: "150px"
                                                            } : {
                                                                margin: "0px 22px 0px 0px",
                                                                height: "40px",
                                                                minWidth: "150px"
                                                            }}
                                                        >
                                                            <img src={dollarSighDarkIcon} alt="Charge" />

                                                            {appLanguage.en ? "Charge Total" : "Cobrar Total"}
                                                        </button>
                                                        <button
                                                            onClick={onClickProcessSale}
                                                            className={`unselectable ${isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}`}
                                                            disabled={isCartEmpty()}
                                                            style={isCartEmpty() ? {
                                                                backgroundColor: "#3f526698",
                                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                cursor: "not-allowed",
                                                                padding: "10px",
                                                                margin: "0px 0px 0px 22px",
                                                                width: "120px",
                                                                height: "40px",
                                                            } : {
                                                                padding: "10px",
                                                                margin: "0px 0px 0px 22px",
                                                                width: "120px",
                                                                height: "40px"
                                                            }}
                                                        >
                                                            {appLanguage.en ? <p style={{ whiteSpace: "nowrap" }}>More</p> : <p style={{ whiteSpace: "nowrap" }}>Más</p>}
                                                            <img style={{ width: "20px", height: "20px", marginLeft: "15px", marginRight: 0 }} src={arrowSmallWhiteIcon} alt="Process sale" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </Fragment> : null}
                        </div>
                    : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

const Item = ({ product, quantity, optimizedForMobile }) => {

    const { appTheme, appLanguage, formatRationalNumber, setDropDownCartOpen } = useContext(AppContext);
    const { setPictureInMemory, changeItemQuantityShoppingCart, removeFromShoppingCart } = useContext(AuthContext);

    const [pictureFile, setPictureFile] = useState(null);

    const [tempQuantity, setTemQuantity] = useState(quantity);

    const navigate = useNavigate();

    useEffect(() => {
        if (product.picturesLocation) {
            if (product.picturesLocation[0]) {
                checkIfImageExistInPr(product.picturesLocation[0]);
            }
        }
        // eslint-disable-next-line
    }, []);

    const checkIfImageExistInPr = (path) => {
        const dimensions = "d140x140";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pr-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pr-pictures-store")) {
                db.createObjectStore("hazcuentas-pr-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pr-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pr-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureFile(request.result.key[dimensions]);
                        } else {
                            checkIfImageExistInGe(path);
                        }
                    } else {
                        checkIfImageExistInGe(path);
                    }
                } else {
                    checkIfImageExistInGe(path);
                }
            }

            requestToCheck.onerror = () => {
                checkIfImageExistInGe(path);
            }
        }
    }

    const checkIfImageExistInGe = (path) => {
        const dimensions = "d140x140";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureFile(request.result.key[dimensions]);
                        } else {
                            getPictureFile(path);
                        }
                    } else {
                        getPictureFile(path);
                    }
                } else {
                    getPictureFile(path);
                }
            }

            requestToCheck.onerror = () => {
                getPictureFile(path);
            }
        }
    }

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions 
        // of a picture store in firebase store

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setPictureFile(urlData);
                                    setPictureInMemory(dbKey, product.id);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                console.error(error);
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    const handleChange = e => {
        const text = e.target.value;

        if (text.trim() === "") {
            setTemQuantity("");
        } else {
            let amount = "";
            let isPoint = false;

            for (let i = 0; i < text.length; i++) {
                if (text[i - 3] !== ".") {
                    if (text[i] === ".") {
                        if (!isPoint) {
                            amount += text[i];
                            isPoint = true;
                        }
                    } else {
                        if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                            amount += text[i];
                        }
                    }
                }
            }

            if ((Number(amount) >= 0) && (Number(amount) < 100000)) {
                changeItemQuantityShoppingCart(product.id, Number(amount));
                setTemQuantity(amount);
            }
        }
    }

    const handleCLick = (type) => {
        let newQuantity = Number(tempQuantity).toFixed(2);

        if (type === "+") {
            newQuantity++;
        } else {
            newQuantity--;
        }

        newQuantity = Number(newQuantity).toFixed(2);

        if (Number(newQuantity)) {
            if ((Number(newQuantity) >= 0) && (Number(newQuantity) < 100000)) {
                changeItemQuantityShoppingCart(product.id, Number(newQuantity));
                setTemQuantity(Number(newQuantity));
            }
        }
    }

    const handleDelete = () => {
        const res = removeFromShoppingCart(product.id);
        if (res !== null) {
            if (res === 0 && optimizedForMobile) {
                setDropDownCartOpen(false)
            }
        }
    }

    const formatQuantity = () => {
        setTemQuantity(quantity);
    }

    const [rate, setRate] = useState("");

    useEffect(() => {
        switch (product.rate) {
            case "perHour":
                setRate(appLanguage.en ? " / hour" : " / hora");
                break;
            case "perEvent":
                setRate(appLanguage.en ? " / event" : " / evento");
                break;
            case "perSession":
                setRate(appLanguage.en ? " / session" : " / sesión");
                break;
            case "perDay":
                setRate(appLanguage.en ? " / day" : " / día");
                break;
            default:
                setRate("");
                break;
        }

    }, [product, appLanguage]);

    const onClickPicture = () => {
        navigate("/ver-producto", { state: product })
    }

    const areNotThereEnough = () => {
        if (product) {
            if (product.quantity === null) {
                return false;
            } else {
                if (tempQuantity > product.quantity) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    }

    return (
        <div
            className={`cart-btn-item-comp ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
            style={{
                borderLeft: "none",
                borderRight: "none",
                borderBottom: "none",
            }}
        >
            <div style={{ marginLeft: "10px", display: "flex", flexDirection: "column" }}>
                <div>
                    {pictureFile ?
                        <div
                            onClick={onClickPicture}
                            className={`products-individual-mini-product-no-img-cont shopp-prod-pictu-hover ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
                            style={{ backgroundColor: appTheme.dark ? "#343434ec" : "rgba(128, 128, 128, 0.171)", cursor: "pointer" }}
                        >
                            <img className="products-individual-mini-product-yes-img" src={pictureFile} alt="Product" />
                        </div>
                        :
                        <div
                            onClick={onClickPicture}
                            className={`products-individual-mini-product-no-img-cont shopp-prod-pictu-hover ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
                            style={{ backgroundColor: appTheme.dark ? "#343434ec" : "rgba(128, 128, 128, 0.171)", cursor: "pointer" }}
                        >
                            <img className="products-individual-mini-product-no-img" src={noPicture} alt="Product" />
                        </div>
                    }
                </div>

                {typeof product.quantity === 'number' ? product.quantity === 0 ?
                    <span
                        className="prod-card-info-out-of-stock-span-cont"
                        style={{
                            marginTop: "-18.7px",
                            borderBottomLeftRadius: "5px",
                            borderBottomRightRadius: "5px",
                            fontSize: "11px",
                            width: "60px",
                            maxWidth: "60px",
                            minWidth: "60px",
                            height: "16px",
                            maxHeight: "16px"
                        }}
                    >
                        {appLanguage.en ? "Unavailable" : "Agotado"}
                    </span>
                    : null : null}

            </div>

            <div style={{ width: "280px", marginRight: "10px" }}>
                <div className="cart-btn-item-comp-section">
                    <div className="cart-btn-item-comp-section-div-price-and-name">
                        <p><b>{product.name}</b></p>
                        <p>RD$ {formatRationalNumber(product.finalPrice)}{rate}</p>
                    </div>
                </div>
                <div style={{ justifyContent: "space-between", marginLeft: "10px" }} className="cart-btn-item-comp-section">
                    <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                        <button
                            onClick={() => handleCLick("-")}
                            className={`cart-btn-input-item ${appTheme.dark ? "border-box-dark  cart-btn-input-item-dark" : "border-box-light cart-btn-input-item-light"}`}
                            style={{
                                width: "35px",
                                height: "35px",
                            }}
                        >
                            <img className="quatity-btn" src={appTheme.dark ? minusIconDark : minusIconLight} alt="Minus" />
                        </button>
                        <input
                            onChange={e => handleChange(e)}
                            onBlur={formatQuantity}
                            className={`cart-quan-input-item ${areNotThereEnough() ? "new-customer-input-alert" : (appTheme.dark ? "border-box-dark dar-pri-bg" : "border-box-light lig-pri-bg")}`}
                            style={{
                                width: "60px",
                                height: "35px",
                            }}
                            value={tempQuantity}
                        />
                        <button
                            onClick={() => handleCLick("+")}
                            className={`cart-btn-input-item ${appTheme.dark ? "border-box-dark cart-btn-input-item-dark" : "border-box-light cart-btn-input-item-light"}`}
                            style={{
                                width: "35px",
                                height: "35px",
                            }}
                        >
                            <img className="quatity-btn" src={appTheme.dark ? plusIconDark : plusIconLight} alt="Plus" />
                        </button>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <button
                            onClick={handleDelete}
                            className={`cart-btn-input-item ${appTheme.dark ? "border-box-dark  cart-btn-input-item-dark" : "border-box-light cart-btn-input-item-light"}`}
                            style={{
                                width: "35px",
                                height: "35px",
                            }}
                        >
                            <img className="delete-btn" src={appTheme.dark ? deleteIcon : deleteIconLight} alt="Delete" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShoppingCartPage;