import React, { createContext, useState, useEffect } from 'react';
import detectBrowserLanguage from 'detect-browser-language'
import { useLocation } from 'react-router-dom';

import transaccionEntryYellowIcon from '../icons/transaccion-entry-yellow.png';
import transaccionExitGreenIcon from '../icons/transaccion-exit-green.png';
import transaccionExitRedIcon from '../icons/transaccion-exit-red.png';
import transaccionExitYellowIcon from '../icons/transaccion-exit-yellow.png';
import transaccionPenIcon from '../icons/transaccion-pen.png';
import transaccionPlusIcon from '../icons/transaccion-plus.png';

export const AppContext = createContext();

const AppProvider = (props) => {
    const [ useMode ] = useState(process.env.REACT_APP_USE_MODE);
    // const [ useMode ] = useState("production");

    const getCurrentTheme = () => {
        let firstTheme = {
            dark: false,
            asInTheSystem: true
        }
        const theme = JSON.parse(localStorage.getItem('theme'));
        if(theme) {
            if(theme.asInTheSystem === false) {
                firstTheme.dark = theme.dark; 
                firstTheme.asInTheSystem = false;
            } else {
                if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
                    firstTheme.dark = true;
                } else {
                    firstTheme.dark = false;
                }
            }
        } else {
            if(window.matchMedia("(prefers-color-scheme: dark)").matches) {
                firstTheme.dark = true;
            } else {
                firstTheme.dark = false;
            }
        }
        localStorage.setItem("theme", JSON.stringify(firstTheme));
        return firstTheme;
    }

    const getCurrentLanguage = () => {
        let firstLanguageState = {
            en: true,
            asInTheSystem: false
        }
        const language = detectBrowserLanguage().substring(0, 2);
        if(language === 'en') {
                firstLanguageState.en = false;
        } else {
                firstLanguageState.en = false;
        }
        localStorage.setItem("language", JSON.stringify(firstLanguageState));
        return firstLanguageState;
    }

    const theme = JSON.parse(localStorage.getItem('theme'));
    const language = JSON.parse(localStorage.getItem('language'));
    
    // Updating in case of changes
    if(language) {
        if(language.asInTheSystem) {
            const languageTemporal = detectBrowserLanguage().substring(0, 2);
            if(languageTemporal === 'en') {
                language.en = true;
            } else {
                language.en = false;
            }
        }
    }

    const [ appTheme, setAppTheme ] = useState(theme ? theme : getCurrentTheme());

    const [ themeColor, setThemeColor ] = useState("#627890");

    const [ waitingTheme, setWaitingTheme ] = useState(true);

    useEffect(() => {
        if (!waitingTheme) {
            appTheme.dark ? setThemeColor("#202022") : setThemeColor("#ffffff");
        } else {
            setTimeout(() => setWaitingTheme(false), 1000);
        }
    }, [appTheme, waitingTheme]);

    const [ appLanguage, setAppLanguaje ] = useState(language ? language : getCurrentLanguage());

    const [ credentials, setCredentials ] = useState(null);
    const [ appLock, setAppLock ] = useState(null);
    const [ alertLockActive, setAlertLockActive ] = useState(false);
    const [ alertRecommendLockActive, setAlertRecommendLockActive ] = useState(false);
    const [ protectedAccess, setProtectedAccess ] = useState(null);
    const [ stopDecrypt, setStopDescrypt ] = useState(false);

    useEffect(() => {
        localStorage.setItem("appVisibility", JSON.stringify({
            hidden: false,
            date: new Date()
        }));

        document.addEventListener('visibilitychange', function () {
            localStorage.setItem("appVisibility", JSON.stringify({
                hidden: document.hidden ? true : false,
                date: new Date()
            }));
        });

        window.addEventListener('click', function () {
            localStorage.setItem("appVisibility", JSON.stringify({
                hidden: document.hidden ? true : false,
                date: new Date()
            }));
        });
    })

    const checkVisibility = () => {
        if (protectedAccess && (!appLock)) {
            setTimeout(() => checkVisibility(), 60000);

            const appVisibility = JSON.parse(localStorage.getItem('appVisibility'));
            const date = new Date();

            if (appVisibility) {
                appVisibility.date = new Date(appVisibility.date);
                const waitingTime = date - appVisibility.date;

                if (waitingTime > 1480000) {
                    setStopDescrypt(true);
                    setAppLock(true);
                } else {
                    if (waitingTime > 1300000) { 
                        setStopDescrypt(true);
                        setAlertLockActive(true);
                    }
                }
            } 
        }  
    }

    useEffect(() => {
        if (protectedAccess && (!appLock)) {
            setTimeout(() => checkVisibility(), 60000);
        }
    // eslint-disable-next-line
    }, [protectedAccess, appLock])

    const getCredentialId = () => {
        // Security ************
        const indexedDB = window.indexedDB;

        const request = indexedDB.open("WebAuthnKeyId", 1);

        request.onerror = function (event) {
            console.error("An error occured with IndexdDB");
            console.error(event);
        }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("publicKey")) {
                db.createObjectStore("publicKey", { keyPath: "id"});
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('publicKey', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
            }
                
            const store = transaction.objectStore('publicKey');
            const requestToCheck = store.get(1);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {

                    setCredentials(request.result.key);

                    if(request.result.key.length > 0) {
                        // This mean the protection is active
                        // Now I have to cheack is the app was refreshed 
                        // less than a minute and 10 seconds
                        // if yes the app will be unlock
                        // then I will need to delete the time from 
                        // the indexeddb

                        const transaction = db.transaction('publicKey', 'readwrite');
                        const store = transaction.objectStore('publicKey');
                        const requestToUnloak = store.get(2);

                        requestToUnloak.onerror = function () {
                            setAppLock(true);
                        }

                        requestToUnloak.onsuccess = (ev) => {
                            const request = ev.target;

                            if (request.result) {
                                const oldDate = new Date(request.result.key);
                                const newDate = new Date();

                                const difference = newDate - oldDate;
                                
                                if ((difference > 0) && (difference < 5000)) {
                                    setAppLock(false);
                                } else {
                                    setAppLock(true);
                                }
                            } else {
                                setAppLock(true);
                            }
                        }
                    } else {
                        setAppLock(false);
                    }
                } else {
                    setAppLock(true);
                }
            }
        }
    }

    useEffect(() => {
        getCredentialId();
    }, []);

    // This identify if wheauthn is active in the app cheacking the indexeddb
    // If on indexeddb there are a object with the key 1, and the leang is 
    // > 0, it means that is the credential ID to has the device to unlock the 
    // device
    useEffect(() => {
        setProtectedAccess(credentials ? (credentials.length > 0 ? true : false) : false);
    // eslint-disable-next-line
    }, [credentials]);


    // this funtion make sure to save the time when the app was refresh 
    // this way the user don't have to enter the pass work each time 
    // they refresh 
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if ((appLock === false) && protectedAccess) {
                const indexedDB = window.indexedDB;
                const request = indexedDB.open("WebAuthnKeyId", 1);

                request.onupgradeneeded = function () {
                    const db = request.result;
                    if (!db.objectStoreNames.contains("publicKey")) {
                        db.createObjectStore("publicKey", { keyPath: "id"});
                    }
                }

                request.onsuccess = function () {
                    const db = request.result;
                    const webAuthnKeyId = {
                        id: 2,
                        key: new Date()
                    };

                    const transaction = db.transaction('publicKey', 'readwrite'); 
                    const store = transaction.objectStore('publicKey');
                    const requestToAdd = store.add(webAuthnKeyId);

                    requestToAdd.onerror = () => {
                        const transaction = db.transaction('publicKey', 'readwrite'); 
                        const store = transaction.objectStore('publicKey');
                        store.put(webAuthnKeyId);
                    }
                }
            };
        }

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [appLock, protectedAccess]);

    // This function is to setup data for app protection 
    // This is when user login with password, loging with google or create account
    const setupCretentials = () => {
        setCredentials(new Uint8Array());
        setAppLock(false);
        setProtectedAccess(false);

        const request = indexedDB.open("WebAuthnKeyId", 1);

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("publicKey")) {
                db.createObjectStore("publicKey", { keyPath: "id"});
            }
        }

        request.onsuccess = function () {
            const db = request.result;

            const webAuthnKeyId = {
                id: 1,
                key: new Uint8Array()
            };

            const webAuthnKeyId2 = {
                id: 2,
                key: new Date()
            };

            const transaction1 = db.transaction('publicKey', 'readwrite');
            const store1 = transaction1.objectStore('publicKey');
            const requestToPut1 = store1.put(webAuthnKeyId);

            requestToPut1.onerror = () => {
                console.log("Error put 1");
            }

            const transaction2 = db.transaction('publicKey', 'readwrite');
            const store2 = transaction2.objectStore('publicKey');
            const requestToPut2 = store2.put(webAuthnKeyId2);

            requestToPut2.onerror = () => {
                console.log("Error put 2");
            }

        }
    }

    const location = useLocation();

    const isInsideFatherPath = path => {
        if(path === location.pathname ) {
            return true;
        } else {
            if(path === "/cuenta-de-negocios") {
                if(
                    location.pathname === "/editar-cuenta" ||
                    location.pathname === "/usuarios-autorizados" ||
                    location.pathname === "/suscripcion" || 
                    location.pathname === "/habilitar-funciones" || 
                    location.pathname === "/cancelar-suscripcion" || 
                    location.pathname === "/detalles-del-plan" || 
                    location.pathname === "/facturas" ||
                    location.pathname === "/realizar-pago" ||
                    location.pathname === "/historial-de-facturacion" ||
                    location.pathname === "/eliminar-cuenta" ||
                    location.pathname === "/autorizar-acceso" ||
                    location.pathname === "/ver-perfl-usuario" ||
                    location.pathname === "/abandonar-cuenta"
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if(path === "/ajustes") {
                    if(
                        location.pathname === "/cambiar-idioma" ||
                        location.pathname === "/tamano-de-impresion" ||
                        location.pathname === "/proteger-acceso" ||
                        location.pathname === "/terminos-y-condiciones" ||
                        location.pathname === "/politicas-de-privacidad" 
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if(path === "/elegir-cuenta") {
                        if(
                            location.pathname === "/precios-disponibles" ||
                            location.pathname === "/crear-cuenta-de-negocio"
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        if(path === "/mi-perfil") {
                            if(
                                location.pathname === "/editar-perfil" ||
                                location.pathname === "/cambiar-password" ||
                                location.pathname === "/seguridad"
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    }
                }
            }
        }
    }

    const getPlanName = text => {
        switch (text) {
            case 'basic':
                if(appLanguage.en) {
                    return ["Basic Plan"];
                } else {
                    return ["Plan Básico"];
                }
            case 'standard':
                if(appLanguage.en) {
                    return ["Standard Plan"];
                } else {
                    return ["Plan Estándar"];
                }
            case 'premium':
                if(appLanguage.en) {
                    return ["Premium Plan"];
                } else {
                    return ["Plan Premium"];
                }
            default:
                return "";
        }
    }

    const getPlanNameAbbre = text => {
        switch (text) {
            case 'basic':
                if(appLanguage.en) {
                    return ["Basic"];
                } else {
                    return ["Básico"];
                }
            case 'standard':
                if(appLanguage.en) {
                    return ["Standard"];
                } else {
                    return ["Estándar"];
                }
            case 'premium':
                if(appLanguage.en) {
                    return ["Premium"];
                } else {
                    return ["Premium"];
                }
            default:
                return "";
        }
    }

    const getNameBusiness = text => {
        let name = '';

        for (let i = 0; (i < text.length); i++) {
            name += text[i];

            if (i > 10) {
                name += "..."
                break;
            }
        }

        return name;
    }

    const [ appPathSelected, setAppPathSeleted ] = useState({
        home: isInsideFatherPath('/'),
        metrics: isInsideFatherPath('/metricas'),
        newUser: isInsideFatherPath('/clientes'),
        settings: isInsideFatherPath('/ajustes'),
        help: isInsideFatherPath('/ayuda'),
        search: isInsideFatherPath('/buscador'),
        addCustomer: isInsideFatherPath('/agregar-cliente'),
        viewCustomer: isInsideFatherPath('/ver-cliente'),
        moreDepails: isInsideFatherPath('/mas-detalles'),
        cart: isInsideFatherPath('/venta-exitosa') || isInsideFatherPath('/resumen-de-venta') || isInsideFatherPath('/carrito-de-compras')
    });

    const getDateInString = (date) => {

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const meses = ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"];


        if(appLanguage.en) {
            return `${months[month]} ${day.toString().padStart(2, '0')}, ${year}`;
        } else {
            return `${day.toString().padStart(2, '0')} ${meses[month]}, ${year}`;
        }
    }

    const getDateInStringWithFullMonth = (date) => {
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

        if(appLanguage.en) {
            return `${months[month]} ${day.toString().padStart(2, '0')}, ${year}`;
        } else {
            return `${day.toString().padStart(2, '0')} ${meses[month]}, ${year}`;
        }
    }

    const getMonthNameAndFullYear = (period) => {

        const [m, y] = period.split('/');

        const newPeriodDate = new Date(Number(y), (Number(m) - 1));

        const month = newPeriodDate.getMonth();
        const year = newPeriodDate.getFullYear();

        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

        if(appLanguage.en) {
            return `${months[month]} ${year}`;
        } else {
            return `${meses[month]} ${year}`;
        }
    }

    const getDateInStringDayMonth = (date) => {
        
        const day = date.getDate();
        const month = date.getMonth();

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const meses = ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"];


        if(appLanguage.en) {
            return `${months[month]} ${day.toString().padStart(2, '0')}`;
        } else {
            return `${day.toString().padStart(2, '0')} de ${meses[month]}`;
        }
    }

    const getDateWithMonthShort = (date) => {
        
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const meses = ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"];

        if(appLanguage.en) {
            return `${day.toString().padStart(2, '0')}/${months[month]}/${year}`;
        } else {
            return `${day.toString().padStart(2, '0')}/${meses[month]}/${year}`;
        }
    }

    const getDayForRecharts = (text) => {
        const [day, month, year] = text.split("/").map(Number);
        const date = new Date(year, month - 1, day);

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const meses = ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"];

        if (appLanguage.en) {
            return months[date.getMonth()] + " " + day;
        } else {
            return meses[date.getMonth()] + " " + day;
        }
    }

    const getMonthForRecharts = (text) => {
        const [ month, year] = text.split("/").map(Number);
        const date = new Date(year, month - 1);

        const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const meses = ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"];

        if (appLanguage.en) {
            return months[date.getMonth()] + " " + year;
        } else {
            return meses[date.getMonth()] + " " + year;
        }
    }

    useEffect(() => { 
        const changeTheme = event => {
            try {
                const currentTheme = JSON.parse(localStorage.getItem('theme'));

                if (currentTheme.asInTheSystem === true) {
                    if(event.matches) {
                        setAppTheme({
                            asInTheSystem: true,
                            dark: true
                        });
                        localStorage.setItem("theme", JSON.stringify({
                            asInTheSystem :true,
                            dark: true
                        }));
                    } else {
                        setAppTheme({
                            asInTheSystem: true,
                            dark: false
                        });
                        localStorage.setItem("theme", JSON.stringify({
                            asInTheSystem: true,
                            dark: false
                        }));
                    }
                }
            } catch (error) {
                console.error("Something bad happened");
                console.error(error);
                setAppTheme(getCurrentTheme());
                console.log("Calling setAppTheme(getCurrentTheme());")
            }
        }

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => changeTheme(event));

        return() => {
            document.removeEventListener("change", changeTheme);
        }
    }, [appTheme]);

    useEffect(() => {
        setAppPathSeleted({
            home: isInsideFatherPath('/'),
            metrics: isInsideFatherPath('/metricas'),
            newUser: isInsideFatherPath('/clientes'),
            settings: isInsideFatherPath('/ajustes'),
            help: isInsideFatherPath('/ayuda'),
            search: isInsideFatherPath('/buscador'),
            addCustomer: isInsideFatherPath('/agregar-cliente'),
            viewCustomer: isInsideFatherPath('/ver-cliente'),
            moreDepails: isInsideFatherPath('/mas-detalles'),
            cart: isInsideFatherPath('/venta-exitosa') || isInsideFatherPath('/resumen-de-venta') || isInsideFatherPath('/carrito-de-compras')
        });
    // eslint-disable-next-line
    }, [location]);

    const getComprobanteTitle = () => {
        if (location.state && location.state.comprobante && location.state.comprobante.id && location.state.comprobante.name) {
            const comprobante = location.state.comprobante;
            return `${comprobante.name} (${comprobante.id})`;
        } else {
            return "";
        }
    }

    const getPageName = () => {
        switch (location.pathname) {
            case '/':
                return appLanguage.en ? '' : '' // 'Home' : 'Inicio' 
            case '/metricas':
                return appLanguage.en ? '' : '' // 'Dashboard' : 'Métricas'
            case '/elegir-cuenta':
                return appLanguage.en ? '' : '' //  'Choose account' : 'Seleccione cuenta'
            case '/clientes':
                return appLanguage.en ? 'Customers' : 'Clientes'
            case '/proveedores':
                return appLanguage.en ? "Suppliers" : "Proveedores"
            case '/agregar-proveedor':
                return appLanguage.en ? 'Add supplier' : 'Agregar proveedor'
            case '/agregar-proveedores':
                return appLanguage.en ? 'Add suppliers' : 'Agregar proveedores'
            case '/editar-proveedores':
                return appLanguage.en ? 'Edit suppliers' : 'Editar proveedores'
            case '/importar-proveedores-desde-excel':
                return appLanguage.en ? 'Import suppliers from Excel' : 'Importar proveedores desde Excel'
            case '/ver-proveedor':
                return appLanguage.en ? 'View supplier' : 'Ver proveedor'
            case '/proveedor-mas-detalles':
                return appLanguage.en ? 'More details about supplier' : 'Más detalles sobre el proveedor'
            case '/buscador':
                return appLanguage.en ? "General search" : "Búsqueda general"
            case '/ventas':
                return appLanguage.en ? 'Sales' : 'Ventas'
            case '/realizar-venta':
                return appLanguage.en ? "Make sale" : "Realizar venta"
            case '/gestionar-turnos':
                return appLanguage.en ? "Manage shifts" : "Gestionar turnos"
            case '/carrito-de-compras':
                return appLanguage.en ? "Shopping cart" : "Carrito de compras"
            case '/resumen-de-venta':
                return appLanguage.en ? "Sales summary" : "Resumen de venta"
            case '/venta-exitosa':
                return appLanguage.en ? "Successful sale" : "Venta exitosa"

            case '/facturas-de-ventas':
                return appLanguage.en ? 'Sales invoices' : 'Facturas de ventas'
            case '/comprobantes-de-facturas':
                return "Comprobantes de facturas"
            case '/ver-comprobante':
                return getComprobanteTitle();

            case '/compras':
                return appLanguage.en ? "Purchases" : "Compras"
            case '/contabilidad-basica':
                return appLanguage.en ? "Basic accounting" : "Contabilidad básica"

            case '/plantilla-de-recibos':
                return appLanguage.en ? "Receipt template" : "Plantilla de recibos"
            case '/ver-detalles-de-venta':
                return appLanguage.en ? "View sale details" : "Ver detalles de venta"
            case '/pagos-a-venta':
                return appLanguage.en ? "Payments on sale" : "Pagos a venta"
            case '/mas-detalles-de-venta':
                return appLanguage.en ? "More sales details" : "Más detalles de venta"
            case '/historial-de-la-venta':
                return appLanguage.en ? "Sale history" : "Historial de la venta"
            case '/ver-producto':
                return appLanguage.en ? 'View product' : 'Ver producto'
            case '/productos':
                return appLanguage.en ? 'Products' : 'Productos'
            case '/detalles-de-producto':
                return appLanguage.en ? "Product details" : 'Detalles del producto'
            case '/opciones-avanzadas':
                return appLanguage.en ? "Advanced options" : 'Opciones avanzadas'
            case '/movimientos-del-producto':
                return appLanguage.en ? "Product movements" : 'Movimientos del producto'
            case '/indicadores-de-desempeno-del-producto':
                return appLanguage.en ? "Performance Indicators" : 'Indicadores de Desempeño'
            case '/registrar-movimiento':
                return appLanguage.en ? "Register movement" : "Registrar movimiento"
            case '/agregar-producto':
                return appLanguage.en ? "Add product" : "Agregar producto"
            case '/inventario':
                return appLanguage.en ? 'Inventory' : 'Inventario'
            case '/agregar-productos':
                return appLanguage.en ? 'Add products' : 'Agregar productos'
            case '/editar-productos':
                return appLanguage.en ? 'Edit products' : 'Editar productos'
            case '/importar-productos-desde-excel':
                return appLanguage.en ? 'Import products from Excel' : 'Importar productos desde Excel'
            case '/agregar-cliente':
                return appLanguage.en ? 'Add client' : 'Agregar cliente'
            case '/agregar-clientes':
                return appLanguage.en ? 'Add clients' : 'Agregar clientes'
            case '/editar-clientes':
                return appLanguage.en ? 'Edit customers' : 'Editar clientes'
            case '/importar-clientes-desde-excel':
                return appLanguage.en ? 'Import customers from Excel' : 'Importar clientes desde Excel'
            case '/ver-cliente':
                return appLanguage.en ?  'View customer' : 'Ver cliente'
            case '/mas-detalles':
                return appLanguage.en ?  'More details' : 'Más detalles'
            case '/cuenta-de-negocios':
                return appLanguage.en ?  'Business account settings' : 'Ajustes de la cuenta de negocios'
            case '/editar-cuenta':
                return appLanguage.en ? "Edit account" : "Editar cuenta"
            case '/usuarios-autorizados':
                return appLanguage.en ? 'Authorized users' : 'Usuarios autorizados'
            case '/autorizar-acceso':
                return appLanguage.en ? "Authorize access" : "Autorizar acceso"
            case '/ver-perfl-usuario':
                return appLanguage.en ? "View profile" : "Ver perfil"
            case '/suscripcion':
                return appLanguage.en ? "Manage your subscription" : "Gestionar tu suscripción"
            case '/habilitar-funciones':
                return appLanguage.en ? "Enable features" : "Habilitar funciones"
            case '/cancelar-suscripcion':
                return appLanguage.en ? "Cancel subscription" : "Cancelar suscripción"
            case '/detalles-del-plan':
                return appLanguage.en ? "Plan details" : "Detalles del plan"
            case '/facturas':
                return appLanguage.en ? "Bills" : "Facturas"
            case '/historial-de-facturacion':
                return appLanguage.en ? 'Billing History' : 'Historial de facturación'
            case '/realizar-pago':
                return appLanguage.en ? 'Make payment' : 'Realizar pago'
            case '/eliminar-cuenta':
                return appLanguage.en ? "Eliminar cuenta de negocios" : "Delete business account"
            case '/abandonar-cuenta':
                return appLanguage.en ? "Leave account" : "Abandonar cuenta"
            case '/ajustes':
                return appLanguage.en ? "More options" : "Más opciones"
            case '/cambiar-idioma':
                return appLanguage.en ? "Language" : "Idiama"
            case '/tamano-de-impresion':
                return appLanguage.en ? "Print Size" : "Tamaño de Impresión"
            case '/proteger-acceso':
                return appLanguage.en ? "Biometrics or PIN" : "Biométricas o PIN"
            case '/terminos-y-condiciones':
                return appLanguage.en ? "Terms and conditions" : "Términos y condiciones"
            case '/politicas-de-privacidad':
                return appLanguage.en ? "Privacy policies" : "Políticas de privacidad"
            case '/mi-perfil':
                return appLanguage.en ? 'My Profile' : 'Mi perfil'
            case '/notificaciones':
                return appLanguage.en ? 'Notifications' : 'Notificaciones'
            case '/actividad-de-la-cuenta':
                return appLanguage.en ? "Account activity" : "Actividad de la cuenta"
            case '/editar-perfil':
                return appLanguage.en ? 'Edict profile' : 'Editar perfil'
            case '/cambiar-password':
                return appLanguage.en ? 'Change password' : 'Cambiar contraseña'
            case '/seguridad':
                return appLanguage.en ? 'Security' : 'Seguridad'
            case '/ayuda':
                return appLanguage.en ? "Help Center" : "Centro de ayuda"
            case '/unverified':
                return appLanguage.en ? 'Unverified' : 'Sin verificar'
            case '/new-account':
                return appLanguage.en ? 'New Account' : 'Nueva cuenta'
            case '/crear-cuenta-de-negocio':
                return appLanguage.en ? 'Create business account' : 'Crear cuenta de negocios'
            case '/precios-disponibles':
                return appLanguage.en ? 'Pricing options' : 'Opciones de precios'
            case '/conectarse-con-cuenta-existente':
                return appLanguage.en ? 'Connect with existing account' : 'Conectarse con cuenta existente'
            default:
                return '' // No found
          }
    }

    const isDeepEqual = (object1, object2) => {

        const objKeys1 = Object.keys(object1);
        const objKeys2 = Object.keys(object2);
      
        if (objKeys1.length !== objKeys2.length) return false;
      
        for (var key of objKeys1) {
          const value1 = object1[key];
          const value2 = object2[key];
      
          const isObjects = isObject(value1) && isObject(value2);
      
          if ((isObjects && !isDeepEqual(value1, value2)) ||
            (!isObjects && value1 !== value2)
          ) {
            return false;
          }
        }
        return true;
    };

    const isObject = (object) => {
        return object != null && typeof object === "object";
    };

    const getTimeInString = (time) => {
        const date = new Date(time);
        const hours = date.getHours();

        const allHours = [
            {hour: 12, me: "AM"},
            {hour: 1, me: "AM"}, 
            {hour: 2, me: "AM"}, 
            {hour: 3, me: "AM"},
            {hour: 4, me: "AM"},
            {hour: 5, me: "AM"},
            {hour: 6, me: "AM"},
            {hour: 7, me: "AM"},
            {hour: 8, me: "AM"},
            {hour: 9, me: "AM"},
            {hour: 10, me: "AM"},
            {hour: 11, me: "AM"},
            {hour: 12, me: "PM"},
            {hour: 1, me: "PM"}, 
            {hour: 2, me: "PM"}, 
            {hour: 3, me: "PM"},
            {hour: 4, me: "PM"},
            {hour: 5, me: "PM"},
            {hour: 6, me: "PM"},
            {hour: 7, me: "PM"},
            {hour: 8, me: "PM"},
            {hour: 9, me: "PM"},
            {hour: 10, me: "PM"},
            {hour: 11, me: "PM"},
        ];

        const minutes = date.getMinutes();
        return `${allHours[hours].hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${allHours[hours].me}`;
    }

    function isLessThanMinuteAgo(eventTime) {
        let currentTime = new Date();
        let timeDifference = currentTime - eventTime;
        return timeDifference < 60000;
    }

    const getShortDateEn = (time) => {
        const date = new Date(time);
        const now = new Date();

        if (date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()) {
            if (isLessThanMinuteAgo(date)) {
                return "Now";
            } else {
                return "Today";
            }
        }

        const yesterday = new Date(time);
        yesterday.setDate(yesterday.getDate() + 1);

        if ((yesterday.getDate() === now.getDate()) && yesterday.getMonth() === now.getMonth() && yesterday.getFullYear() === now.getFullYear()) {
            return "Yesterday";
        } else {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}/${month}/${year}`
        }
    }

    const getShortDateEs = (time) => {
        const date = new Date(time);
        const now = new Date();

        if (date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()) {
            if (isLessThanMinuteAgo(date)) {
                return "Ahora";
            } else {
                return "Hoy";
            }
        }

        const yesterday = new Date(time);
        yesterday.setDate(yesterday.getDate() + 1);

        if ((yesterday.getDate() === now.getDate()) && yesterday.getMonth() === now.getMonth() && yesterday.getFullYear() === now.getFullYear()) {
            return "Ayer";
        } else {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}/${month}/${year}`
        }
    }

    function formatRationalNumber(number) {
        if (number === undefined || number === null) {
            return "--";
        }
        
        // Check if the number is a string to convert it to a number
        if (typeof number === 'string') {
            number = parseFloat(number.split(',').join(''));
        }

        const fixedNumber = number.toFixed(2);
        const finalNumber = parseFloat(fixedNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    
        // Format the number with thousands and two decimal separators
        return finalNumber.toString();
    }

    function getNumberFromCustomString(text) {
        return parseFloat(text.split(",").join(""));
    }

    function formatPhoneNumber(phone) {
        let temp = "";
        let normalizedPhone = null;

        // Normalazing Phone String
        const normalizedString = phone.normalize("NFD");
        const chainWithoutAccents = normalizedString
            .replace(/[\u0300-\u036f]/g, "");

        normalizedPhone = chainWithoutAccents.trim().toLowerCase();

        if (normalizedPhone[0] === "+" && normalizedPhone[1] === "1" && phone.length > 8) {
            // From    +18099958646
            // To      +1 809 995 8646
            // Them    ["+1", "809", "995", "8646"]
    
            for (let j = 0; j < normalizedPhone.length; j++) {
              if (j === 2 || j === 5 || j === 8 ) {
                temp += " ";
                temp += normalizedPhone[j];
              } else {
                temp += normalizedPhone[j];
              }
            }
    
            const pnArray = temp.split(" ");
    
            // +1 (809) 995-8646
            const phone2 = `+1 (${pnArray[1]}) ${pnArray[2]}-${pnArray[3]}`;

            return phone2;
        } else {
            return phone;
        }
    }

    function formatPhoneNumberOnChange(phone) {
        let temp = "";
        let normalizedPhone = null;

        // Normalazing Phone String
        const normalizedString = phone.normalize("NFD");
        const chainWithoutAccents = normalizedString
            .replace(/[\u0300-\u036f]/g, "");

        normalizedPhone = chainWithoutAccents.trim().toLowerCase();

        if (normalizedPhone[0] === "+" && normalizedPhone[1] === "1") {
            // From    +18099958646
            // To      +1 809 995 8646
            for (let j = 0; j < normalizedPhone.length; j++) {
                if (j === 2 || j === 5 || j === 8) {
                    temp += " ";
                    temp += normalizedPhone[j];
                } else {
                    temp += normalizedPhone[j];
                }
            }
            return temp;
        } else {
            return phone;
        }
    }

    function formatProductName(name) {
        if (typeof name === 'string') {
            let newName = "";
            for (let i = 0; (i < name.length) && (i < 23); i++) {
                newName += name[i];
            }
            if (name.length > newName.length) {
                newName += "...";
            }
            return newName;
        } else {
            return "-"
        }
    }

    function isNumber(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    function isTouchEnabled() {
        return ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0);
    }

    const getBusinessCategories = () => {
        if (appLanguage.en) {
            return ["beautySalon", "clothingStore", "dispenser", "electronicsStore", "pharmacyAndVarieties", "foodStall", "gardening", "hardwareStore", "mechanicalWorkshop", "varietyStore"];
        } else {
            return ["pharmacyAndVarieties", "hardwareStore", "gardening", "foodStall", "beautySalon", "dispenser", "electronicsStore", "varietyStore", "clothingStore", "mechanicalWorkshop"];
        }
    }

    const getBusinessCategory = (text) => {
        switch (text) {
            case "pharmacyAndVarieties":
                return appLanguage.en ? "Pharmacy and Varieties" : "Farmacia y Variedades";
            case "clothingStore":
                return appLanguage.en ? "Clothing Store" : "Tienda de Ropa y Accesorios";
            case "dispenser":
                return appLanguage.en ? "Dispenser" : "Surtidora";
            case "electronicsStore":
                return appLanguage.en ? "Electronics Store" : "Tienda de Electrónica";
            case "foodStall":
                return appLanguage.en ? "Food Stall" : "Puesto de Comida";
            case "gardening":
                return appLanguage.en ? "Gardening" : "Jardinería";
            case "hardwareStore":
                return appLanguage.en ? "Hardware Store" : "Ferretería";
            case "mechanicalWorkshop":
                return appLanguage.en ? "Mechanical Workshop" : "Taller de Mecánica";
            case "beautySalon":
                return appLanguage.en ? "Beauty Salon" : "Salón de Belleza";
            case "barbershop":
                return appLanguage.en ? "Barbershop" : "Barbería";
            case "varietyStore":
                return appLanguage.en ? "Variety Store" : "Tienda de Variedades";
            default:
                return text;
        }
    }

    const getThisBusinessProductsCategories = (text) => {
        switch (text) {
            case "pharmacyAndVarieties":
                return ["general", "service", "food", "babiesAndMotherhood", "drink", "capsules", "creamsAndOintments", "care", "pharmacyAndHealth", "drops", "inhalers", "injections", "syrups", "electronicsAndAccessories", "stationeryAndOffice", "pills", "tablets", "powders", "suspension", "suppositories"];
            case "clothingStore":
                return ["general", "service", "accessories", "menClothing", "womenClothing", "boysAndGirlsClothing", "unisexClothing", "footwear", "perfumery", "care"];
            case "dispenser":
                return ["general", "service", "food", "dairyAndDerivatives", "drink", "alcoholicDrinks", "cleaningAndHome", "care", "pharmacyAndHealth", "babiesAndMotherhood", "electronicsAndAccessories", "stationeryAndOffice", "tools", "pet"];
            case "electronicsStore":
                return ["general", "service", "accessories", "audio", "cablesAndConnectors", "cameras", "chargersAndAdapters", "electronicComponents", "computers", "appliances", "coversAndProtectors", "smartHome", "printersAndScanners", "monitors", "stationeryAndOffice", "peripherals", "smartWatches", "tablets", "phones", "televisions"];
            case "foodStall":
                return ["general", "service", "food", "drink", "combos", "extrasOrComplements", "desserts", "sandwichesAndBurgers"];
            case "gardening":
                return ["general", "service", "accessories", "floors", "potsAndContainers", "substratesAndLands", "tools", "maintenanceAndCare", "irrigationSystems", "gardenDecoration", "seeds"];
            case "hardwareStore":
                return ["general", "service", "accessories", "adhesivesSealantsAndGlues", "automotiveAndLubricants", "heatingAndVentilation", "electricityAndLighting", "fixingsAndFasteners", "tools", "gardening", "cleaningAndMaintenance", "constructionMaterials", "paintsAndFinishes", "plumbingAndPlumbing", "securityAndProtection"];
            case "mechanicalWorkshop":
                return ["general", "service", "generalAccessoriesAndSpareParts", "batteriesAndElectricalSystems", "brakeAndSuspensionComponents", "diagnosisAndTesting", "liftingAndSupport", "filtersAndAirSystems", "tools", "lubricantsAndChemicals", "tiresAndAccessories", "securityAndProtection", "exhaustAndEmissionSystems", "screwsAndFixings", "transmissionAndPowerTrain", "automotiveGlass"];
            case "barbershop":
                return ["general", "service", "accessories", "skinCare", "fragrances", "personalHygiene", "beardProducts", "shavingProducts", "hairProducts"];
            case "beautySalon":
                return ["general", "service", "accessories", "skinCare", "nailCare", "hairCare", "hairRemoval", "fragrances", "makeUp", "hairStylingProducts", "specificTreatments"];
            case "varietyStore":
                return ["general", "service", "food", "dairyAndDerivatives", "drink", "alcoholicDrinks", "cleaningAndHome", "care", "pharmacyAndHealth", "babiesAndMotherhood", "electronicsAndAccessories", "stationeryAndOffice", "tools", "pet"];
            default:
                return ["general", "service"];
        }
    }

    const getCategory = (text) => {
        switch (text) {
            case "general":
                return appLanguage.en ? "General" : "General";
            case "service":
                return appLanguage.en ? "Services" : "Servicios";
            case "menClothing":
                return appLanguage.en ? "Men's Clothing" : "Ropa para Hombre";
            case "womenClothing":
                return appLanguage.en ? "Women's Clothing" : "Ropa para Mujer";
            case "boysAndGirlsClothing":
                return appLanguage.en ? "Clothing for Boys and Girls" : "Ropa para Niños y Niñas";
            case "unisexClothing":
                return appLanguage.en ? "Unisex Clothing" : "Ropa Unisex";
            case "accessories":
                return appLanguage.en ? "Accessories" : "Accesorios";
            case "footwear":
                return appLanguage.en ? "Footwear" : "Calzado";
            case "care":
                return appLanguage.en ? "Personal Care" : "Cuidado Personal";
            case "perfumery":
                return appLanguage.en ? "Perfumery" : "Perfumería";
            case "food":
                return appLanguage.en ? "Food" : "Alimentos";
            case "dairyAndDerivatives":
                return appLanguage.en ? "Dairy and Derivatives" : "Lácteos y Derivados";
            case "drink":
                return appLanguage.en ? "Drinks" : "Bebidas";
            case "alcoholicDrinks":
                return appLanguage.en ? "Alcoholic Drinks" : "Bebidas Alcohólicas";
            case "cleaningAndHome":
                return appLanguage.en ? "Cleaning and Home" : "Limpieza y Hogar";
            case "pharmacyAndHealth":
                return appLanguage.en ? "Pharmacy and Health" : "Farmacia y Salud";
            case "babiesAndMotherhood":
                return appLanguage.en ? "Babies and Motherhood" : "Bebés y Maternidad";
            case "electronicsAndAccessories":
                return appLanguage.en ? "Electronics and Accessories" : "Electrónica y Accesorios";
            case "stationeryAndOffice":
                return appLanguage.en ? "Stationery and Office" : "Papelería y Oficina";
            case "tools":
                return appLanguage.en ? "Tools" : "Herramientas";
            case "pet":
                return appLanguage.en ? "Pets" : "Mascotas";
            case "cablesAndConnectors":
                return appLanguage.en ? "Cables and Connectors" : "Cables y Conectores";
            case "cameras":
                return appLanguage.en ? "Cameras" : "Cámaras";
            case "chargersAndAdapters":
                return appLanguage.en ? "Chargers and Adapters" : "Cargadores y Adaptadores";
            case "electronicComponents":
                return appLanguage.en ? "Electronic Components" : "Componentes Electrónicos";
            case "computers":
                return appLanguage.en ? "Computers" : "Computadoras";
            case "appliances":
                return appLanguage.en ? "Appliances" : "Electrodomésticos";
            case "coversAndProtectors":
                return appLanguage.en ? "Covers and Protectors" : "Fundas y Protectores (Covers)";
            case "smartHome":
                return appLanguage.en ? "Smart Home" : "Hogar Inteligente";
            case "printersAndScanners":
                return appLanguage.en ? "Printers and Scanners" : "Impresoras y Escáneres";
            case "monitors":
                return appLanguage.en ? "Monitors" : "Monitores";
            case "peripherals":
                return appLanguage.en ? "Peripherals" : "Periféricos";
            case "smartWatches":
                return appLanguage.en ? "Smart Watches" : "Relojes Inteligentes";
            case "audio":
                return appLanguage.en ? "Audio" : "Audio";
            case "tablets":
                return appLanguage.en ? "Tablets" : "Tabletas";
            case "phones":
                return appLanguage.en ? "Phones" : "Teléfonos";
            case "televisions":
                return appLanguage.en ? "Televisions" : "Televisores";
            case "combos":
                return appLanguage.en ? "Combos" : "Combos ";
            case "extrasOrComplements":
                return appLanguage.en ? "Extras or Complements" : "Extras o Complementos";
            case "desserts":
                return appLanguage.en ? "Desserts" : "Postres";
            case "sandwichesAndBurgers":
                return appLanguage.en ? "Sandwiches and Burgers" : "Sándwiches y Hamburguesas";
            case "floors":
                return appLanguage.en ? "Floors" : "Plantas";
            case "potsAndContainers":
                return appLanguage.en ? "Pots and Containers" : "Macetas y Contenedores";
            case "substratesAndLands":
                return appLanguage.en ? "Substrates and Lands" : "Sustratos y Tierras";
            case "maintenanceAndCare":
                return appLanguage.en ? "Maintenance and Care" : "Mantenimiento y Cuidado";
            case "irrigationSystems":
                return appLanguage.en ? "Irrigation Systems" : "Sistemas de Riego";
            case "gardenDecoration":
                return appLanguage.en ? "Garden Decoration" : "Decoración de Jardín";
            case "seeds":
                return appLanguage.en ? "Seeds" : "Semillas";
            case "adhesivesSealantsAndGlues":
                return appLanguage.en ? "Adhesives, Sealants and Glues" : "Adhesivos, Selladores y Pegamentos";
            case "automotiveAndLubricants":
                return appLanguage.en ? "Automotive and Lubricants" : "Automotriz y Lubricantes";
            case "heatingAndVentilation":
                return appLanguage.en ? "Heating and Ventilation" : "Calefacción y Ventilación";
            case "electricityAndLighting":
                return appLanguage.en ? "Electricity and Lighting" : "Electricidad e Iluminación";
            case "fixingsAndFasteners":
                return appLanguage.en ? "Fixings and Fasteners" : "Fijaciones y Sujetadores";
            case "gardening":
                return appLanguage.en ? "Gardening" : "Jardinería";
            case "cleaningAndMaintenance":
                return appLanguage.en ? "Cleaning and Maintenance" : "Limpieza y Mantenimiento";
            case "constructionMaterials":
                return appLanguage.en ? "Construction Materials" : "Materiales de Construcción";
            case "paintsAndFinishes":
                return appLanguage.en ? "Paints and Finishes" : "Pinturas y Acabados";
            case "plumbingAndPlumbing":
                return appLanguage.en ? "Plumbing and Plumbing" : "Plomería y Fontanería";
            case "securityAndProtection":
                return appLanguage.en ? "Security and Protection" : "Seguridad y Protección";
            case "generalAccessoriesAndSpareParts":
                return appLanguage.en ? "General Accessories and Spare Parts" : "Accesorios y Repuestos Generales";
            case "batteriesAndElectricalSystems":
                return appLanguage.en ? "Batteries and Electrical Systems" : "Baterías y Sistemas Eléctricos";
            case "brakeAndSuspensionComponents":
                return appLanguage.en ? "Brake and Suspension Components" : "Componentes de Frenos y Suspensión";
            case "diagnosisAndTesting":
                return appLanguage.en ? "Diagnosis and Testing" : "Diagnóstico y Pruebas";
            case "liftingAndSupport":
                return appLanguage.en ? "Lifting and Support" : "Elevación y Soporte";
            case "filtersAndAirSystems":
                return appLanguage.en ? "Filters and Air Systems" : "Filtros y Sistemas de Aire";
            case "lubricantsAndChemicals":
                return appLanguage.en ? "Lubricants and Chemicals" : "Lubricantes y Químicos";
            case "tiresAndAccessories":
                return appLanguage.en ? "Tires and Accessories" : "Neumáticos y Accesorios";
            case "exhaustAndEmissionSystems":
                return appLanguage.en ? "Exhaust and Emission Systems" : "Sistemas de Escape y Emisión";
            case "screwsAndFixings":
                return appLanguage.en ? "Screws and Fixings" : "Tornillería y Fijaciones";
            case "transmissionAndPowerTrain":
                return appLanguage.en ? "Transmission and Power Train" : "Transmisión y Tren de Potencia";
            case "automotiveGlass":
                return appLanguage.en ? "Automotive Glass" : "Vidriería Automotriz";
            case "skinCare":
                return appLanguage.en ? "Skin Care" : "Cuidado de la Piel";
            case "fragrances":
                return appLanguage.en ? "Fragrances" : "Fragancias";
            case "personalHygiene":
                return appLanguage.en ? "Personal Hygiene" : "Higiene Personal";
            case "beardProducts":
                return appLanguage.en ? "Beard Products" : "Productos para la Barba";
            case "shavingProducts":
                return appLanguage.en ? "Shaving Products" : "Productos para el Afeitado";
            case "hairProducts":
                return appLanguage.en ? "Hair Products" : "Productos para el Cabello";
            case "nailCare":
                return appLanguage.en ? "Nail Care" : "Cuidado de las Uñas";
            case "hairCare":
                return appLanguage.en ? "Hair Care" : "Cuidado del Cabello";
            case "hairRemoval":
                return appLanguage.en ? "Hair removal" : "Depilación";
            case "makeUp":
                return appLanguage.en ? "Make-up" : "Maquillaje";
            case "hairStylingProducts":
                return appLanguage.en ? "Hair Styling Products" : "Productos de Peinado";
            case "specificTreatments":
                return appLanguage.en ? "Specific Treatments" : "Tratamientos Específicos";
            // Farmacy ***************************
            case "pills":
                return appLanguage.en ? "Pills" : "Pastillas";
            case "suspension":
                return appLanguage.en ? "Suspension" : "Suspensión";
            case "syrups":
                return appLanguage.en ? "Syrups" : "Jarabes";
            case "capsules":
                return appLanguage.en ? "Capsules" : "Cápsulas";
            case "creamsAndOintments":
                return appLanguage.en ? "Creams and Ointments" : "Cremas y Ungüentos";
            case "inhalers":
                return appLanguage.en ? "Inhalers" : "Inhaladores";
            case "drops":
                return appLanguage.en ? "Drops" : "Gotas";
            case "suppositories":
                return appLanguage.en ? "Suppositories" : "Supositorios";
            case "transdermalPatch":
                return appLanguage.en ? "Transdermal Patch" : "Parche Transdérmico";
            case "powders":
                return appLanguage.en ? "Powders" : "Polvos";
            case "injections":
                return appLanguage.en ? "Injections" : "Inyecciones";
            default:
                return text;
        }
    }

    const getUnitsOfMeasurementFor = (text) => {
        switch (text) {
            case "service":
                return ["perService", "perHour", "perEvent", "perSession", "perDay"];
            default:
                return ["perUnit", "perDozen", "perPackege", "perCentimeter", "perMeter", "perFeet", "perRoll", "perGram", "perKilogram", "perPound", "perMilligram", "perLiter", "perMilliliter"];
        }
    }

    const getRate = (text) => {
        switch (text) {
            case "perUnit":
                return appLanguage.en ? "Unit" : "Unidad";
            // For services
            case "perService":
                return appLanguage.en ? "Service" : "Servicio";
            case "perHour":
                return appLanguage.en ? "Hour" : "Hora";
            case "perEvent":
                return appLanguage.en ? "Event" : "Evento";
            case "perSession":
                return appLanguage.en ? "Session" : "Sesión";
            case "perDay":
                return appLanguage.en ? "Day" : "Día";
            // For products 
            case "perMeter": 
                return appLanguage.en ? "Meter (m)" : "Metros (m)";
            case "perCentimeter": 
                return appLanguage.en ? "Centimeter (cm)" : "Centímetros (cm)";
            case "perRoll": 
                return appLanguage.en ? "Roll" : "Rollo";
            case "perFeet": 
                return appLanguage.en ? "Foot (ft)" : "Pie (ft)";
            case "perKilogram": 
                return appLanguage.en ? "Kilogram (kg)" : "Kilogramo (kg)";
            case "perPound": 
                return appLanguage.en ? "Pound (lb)" : "Libra (lb)";
            case "perGram": 
                return appLanguage.en ? "Grams (g)" : "Gramo (g)";
            case "perMilligram": 
                return appLanguage.en ? "Milligrams (mg)" : "Miligramo (mg)";
            case "perLiter": 
                return appLanguage.en ? "Liter (L)" : "Litro (L)";
            case "perMilliliter": 
                return appLanguage.en ? "Milliliters (mL)" : "Mililitros (mL)";
            case "perDozen": 
                return appLanguage.en ? "Dozen" : "Docena";
            case "perPackege": 
                return appLanguage.en ? "Package" : "Paquete";
            default:
                return text;
        }
    }

    const getRatePer = (text) => {
        switch (text) {
            case "perUnit":
                return appLanguage.en ? "Per Unit" : "Por Unidad";
            // For services
            case "perService":
                return appLanguage.en ? "Per Service" : "Por Servicio";
            case "perHour":
                return appLanguage.en ? "Per Hour" : "Por Hora";
            case "perEvent":
                return appLanguage.en ? "Per Event" : "Por Evento";
            case "perSession":
                return appLanguage.en ? "Per Session" : "Por Sesión";
            case "perDay":
                return appLanguage.en ? "Per Day" : "Por Día";
            // For products
            case "perMeter":
                return appLanguage.en ? "Per Meter (m)" : "Por Metro (m)";
            case "perCentimeter":
                return appLanguage.en ? "Per Centimeter (cm)" : "Por Centímetro (cm)";
            case "perRoll":
                return appLanguage.en ? "Per Roll" : "Por Rollo";
            case "perFeet":
                return appLanguage.en ? "Per Foot (ft)" : "Por Pie (ft)";
            case "perKilogram": 
                return appLanguage.en ? "Per Kilogram (kg)" : "Por Kilogramo (kg)";
            case "perPound": 
                return appLanguage.en ? "Per Pound (lb)" : "Por Libra (lb)";
            case "perGram": 
                return appLanguage.en ? "Per Gram (g)" : "Por Gramo (g)";
            case "perMilligram": 
                return appLanguage.en ? "Per Milligram (mg)" : "Por Miligramo (mg)";
            case "perLiter": 
                return appLanguage.en ? "Per Liter (L)" : "Por Litro (L)";
            case "perMilliliter": 
                return appLanguage.en ? "Per Milliliter (mL)" : "Por Mililitro (mL)";
            case "perDozen": 
                return appLanguage.en ? "Per Dozen" : "Por Docena";
            case "perPackege": 
                return appLanguage.en ? "Per Package" : "Por Paquete";
            default:
                return text;
        }
    }

    // This state it used primary in components > navifations > cartBtn
    const [showCartNumber, setShowCartNumber] = useState(false);
    const [dropDownCartOpen, setDropDownCartOpen] = useState(false);
    const [ dropDownProfileMenuOpen, setDropDownProfileMenuOpen ] = useState(false);

    const getMovementIcon = (text) => {
        switch (text) {
            case "transaccionEntryYellow":
                return transaccionEntryYellowIcon;
            case "transaccionExitGreen":
                return transaccionExitGreenIcon;
            case "transaccionExitRed":
                return transaccionExitRedIcon;
            case "transaccionExitYellow":
                return transaccionExitYellowIcon;
            case "transaccionEdict":
                return transaccionPenIcon;
            case "transaccionPlus":
                return transaccionPlusIcon;
            default:
                return null;
        }
    }

    const getMovementDescription = (text) => {
        switch (text) {
            case "all":
                return appLanguage.en ? "All" : "Todos";
            case "purchases":
                return appLanguage.en ? "Purchase" : "Compra";
            case "customerReturns":
                return appLanguage.en ? "Customer Return" : "Devolución de Cliente";
            case "production":
                return appLanguage.en ? "Product manufactured in the business" : "Producto fabricado en el negocio";
            case "sales":
                return appLanguage.en ? "Sale" : "Venta";
            case "returnsToSuppliers":
                return appLanguage.en ? "Returns to suppliers" : "Devoluciones a proveedores";
            case "consumption":
                return appLanguage.en ? "Internal consumption" : "Consumo interno";
            case "adjustments":
                return appLanguage.en ? "Quantity adjustments" : "Ajustes de cantidad";
            case "loss":
                return appLanguage.en ? "Loss" : "Pérdida";
            case "lossCancellation": 
                return appLanguage.en ? "Loss cancellation" : "Cancelación de pérdida";
            case "saleCancellation":
                return appLanguage.en ? "Sale cancellation" : "Cancelación de venta";
            case "modified":
                return appLanguage.en ? "Details update" : "Actualización de detalles";
            case "start":
                return appLanguage.en ? "Start of cycle" : "Inicio del ciclo";
            default:
                return text;
        }
    }

    const getComprobanteTitleById = (type) => {
        switch (type) {
            case "01":
                return "Factura de Crédito Fiscal";
            case "02":
                return "Factura de Consumo";
            case "03":
                return "Notas de Débito";
            case "04":
                return "Notas de Crédito";
            case "11":
                return "Comprobante de Compras";
            case "12":
                return "Registro Único de Ingresos";
            case "13":
                return "Comprobante para Gastos Menores";
            case "14":
                return "Comprobante para Regímenes Especiales";
            case "15":
                return "Comprobante Gubernamentales";
            case "16":
                return "Comprobante para Exportaciones";
            case "17":
                return "Comprobante de Pagos al Exterior";
            case "31":
                return "Factura de Crédito Fiscal Electrónica";
            case "32":
                return "Factura de Consumo Electrónica";
            case "33":
                return "Nota de Débito Electrónica";
            case "34":
                return "Nota de Crédito Electrónica";
            case "41":
                return "Compras Electrónico";
            case "43":
                return "Gastos Menores Electrónico";
            case "44":
                return "Regímenes Especiales Electrónicos";
            case "45":
                return "Gubernamental Electrónico";
            case "46":
                return "Exportación Electrónico";
            case "47":
                return "Pagos al Exterior Electrónico";
            default:
                return appLanguage.en ? "Invalid" : "Inválido";
        }
    };


    const isComprobanteUsable = (tipo, comprobantesSummary) => {
        if (tipo === "00") {
            return true;
        } else {
            let comprobanteData = null
        
            for (const key in comprobantesSummary) {
                if (comprobantesSummary[key].id === tipo) {
                    comprobanteData = comprobantesSummary[key];
                    break;
                }
            }
            
            if (comprobanteData) {
                if (comprobanteData.isActive) {
                    return true;
                } else {
                    return false
                }
            } else {
                return false;
            }
        }
    }

    const getComprobanteEmoji = (tipo, comprobantesSummary) => {
        if (tipo === "00") {
            return "";
        } else {
            let comprobanteData = null
        
            for (const key in comprobantesSummary) {
                if (comprobantesSummary[key].id === tipo) {
                    comprobanteData = comprobantesSummary[key];
                    break;
                }
            }
            
            if (comprobanteData) {
                if (comprobanteData.quantity <= 0) {
                    return "❌";
                } 

                if (comprobanteData.isAlerts && comprobanteData.quantity <= comprobanteData.alertQuantity[0]) {
                    return "⚠️";
                } else {
                    return ""
                }
            } else {
                return "";
            }
        }
    }

    return (
        <AppContext.Provider
            value={{ 
                useMode,
                appTheme,
                themeColor,
                appLanguage,
                credentials,
                appLock,
                alertLockActive,
                alertRecommendLockActive,
                protectedAccess,
                stopDecrypt,
                appPathSelected,
                showCartNumber,
                dropDownCartOpen,
                dropDownProfileMenuOpen,
                setAppTheme,
                setAppLanguaje,
                setCredentials,
                setAppLock,
                setAlertLockActive,
                setAlertRecommendLockActive,
                setupCretentials,
                setProtectedAccess,
                setStopDescrypt,
                getCurrentTheme,
                getCurrentLanguage,
                setAppPathSeleted,
                isInsideFatherPath,
                getPageName,
                getPlanName,
                getPlanNameAbbre,
                getNameBusiness,
                getDateInString,
                getDateInStringWithFullMonth,
                getMonthNameAndFullYear,
                getDateInStringDayMonth,
                getDateWithMonthShort,
                getDayForRecharts,
                getMonthForRecharts,
                isDeepEqual,
                getTimeInString,
                getShortDateEn,
                getShortDateEs,
                formatRationalNumber,
                getNumberFromCustomString,
                formatPhoneNumber,
                formatPhoneNumberOnChange,
                formatProductName,
                isNumber,
                isTouchEnabled,
                getBusinessCategories,
                getBusinessCategory,
                getThisBusinessProductsCategories,
                getCategory,
                getUnitsOfMeasurementFor,
                getRate,
                getRatePer,
                setShowCartNumber,
                setDropDownCartOpen,
                setDropDownProfileMenuOpen,
                getMovementIcon,
                getMovementDescription,
                getComprobanteTitleById,
                isComprobanteUsable,
                getComprobanteEmoji
            }}
        >
            {props.children}
        </AppContext.Provider>
    )
}

export default AppProvider;