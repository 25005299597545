import "./basicAccounting.page.scss";

import { Fragment, useContext, useEffect } from "react";

import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { SideMenuContext } from "../../../context/SideMenuContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

const BasicAccountingPage = () => {
    const { accountData, accessTo } = useContext(AuthContext);
    const { appTheme, appLanguage } = useContext(AppContext);
    const { desktopView} = useContext(SideMenuContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.taxes)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Basic accounting" : "Contabilidad básica"} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.taxes ? <Fragment>
                                <div>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? "Basic accounting" : "Contabilidad básica"}
                                        />
                                        : null}
                                    <div className="products-services-first-section">
                                        <div className="products-services-first-item">
                                            {/**  */}

                                        </div>
                                    </div>
                                </div>

                                {/**  */}
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    );
}

export default BasicAccountingPage;