import "./inputTypeDate.scss";

import { useContext, useEffect } from "react";

import { AppContext } from "../../../context/AppContext";

import dateImgDark from "../../../icons/date-dark.png";
import dateImgLight from "../../../icons/date-light.png";

const InputTypeDate2 = ({setDateObj, showAlert, setShowAlert, dateString, setDateString, onClickPick}) => {
    const { appTheme, appLanguage } = useContext(AppContext);

    useEffect(() => {
        if (dateString.trim() === "") {
            setDateObj(false);
        } else {
            let day = "";
            let month = "";
            let year = "";

            for (let i = 0; i < dateString.length; i++) {
                if (i === 0 || i === 1) {
                    day += dateString[i];
                } else {
                    if (i === 5 || i === 6) {
                        month += dateString[i];
                    } else {
                        if (i === 10 || i === 11 || i === 12 || i === 13 ) {
                            year += dateString[i];
                        }
                    }
                }
            }

            if (day.length === 2 && month.length === 2 && year.length === 4) {

                const dayN = Number(day);
                const monthN = Number(month) - 1;
                const yearN = Number(year);
                
                const date = new Date(yearN, monthN, dayN);

                setDateObj(date);

                const newDateString = `${date.getDate().toString().padStart(2, '0')} / ${(date.getMonth() + 1).toString().padStart(2, '0')} / ${date.getFullYear()}`;

                if (dateString !== newDateString) {
                    setDateString(newDateString);
                }
                
            } else {
                setDateObj(null);
            }
        }
    // eslint-disable-next-line
    }, [dateString]);

    const onChange = (e) => {

        setShowAlert(false);

        const text = e.target.value;
        
        let stringDate1 = "";
        let stringDate2 = "";

        for (let i = 0; i < text.length; i++) {
            if ((text[i] !== " ") && Number.isInteger(Number(text[i]))) {
                stringDate1 += text[i];
            }
        }

        for (let j = 0; j < stringDate1.length && j < 8; j++) {
            if (j === 2 || j === 4 ) {
                stringDate2 += " / ";
                stringDate2 += stringDate1[j];
            } else {
                stringDate2 += stringDate1[j];
            }
        }

        setDateString(stringDate2);
    }

    return(
        <div 
            className={`input-type-date-container ${showAlert ? "border-date-alert2" : (appTheme.dark ? "border-box-dark" : "border-box-light")}`}
            style={{
                colorScheme: appTheme.dark ? "dark" : "light",
                boxShadow: "rgba(0, 0, 0, 0.2) 1px 1px 1px 0.5px",
            }}
        >
            <input
                type="text"
                inputMode="numeric"
                value={dateString}
                onChange={e => onChange(e)}
                className={`input-type-date-text ${appTheme.dark ? "dar-pri-bg input-border-box-dark" : "lig-pri-bg input-border-box-light"} ncfExpirationDatePlaceholder`}
                placeholder={appLanguage.en ? "day / month / year" : "día / mes / año"}
                style={{
                    width: "100%",
                    colorScheme: appTheme.dark ? "dark" : "light",
                    fontSize: "17px",
                    background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                    color: appTheme.dark ? "white" : "black",
                    fontWeight: "500",
                }}
            />
            <button
                className={`input-type-date-button ${appTheme.dark ? "input-type-date-button-dark2" : "input-type-date-button-light2"}`}
                onClick={onClickPick}
            >
                <img
                    className="input-type-date-img" 
                    src={appTheme.dark ? dateImgDark : dateImgLight}
                    alt="Date picker" 
                />
            </button>
        </div>
    )
}

export default InputTypeDate2;