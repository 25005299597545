import "./invoices.page.scss";

import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { SideMenuContext } from "../../../context/SideMenuContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import termsCondIconDark from '../../../icons/terms-and-conditions-dark.png';

import crossIcon from "../../../icons/cross.png";
import crossWhiteIcon from "../../../icons/cross-white.png";

import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";

import arrowDarkIcon from "../../../icons/arrowhead-dark.png";
import arrowLightIcon from "../../../icons/arrowhead-light.png";

import filterImgDark from "../../../icons/filter-dark.png";
import filterImgLight from "../../../icons/filter-light.png";

import adjustmentIconDark from '../../../icons/adjustment-dark.png';
import adjustmentIconLight from '../../../icons/adjustment-light.png';

import magnifyingIcon from "../../../icons/magnifying-glass.png";
import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';
import addImgDark from "../../../icons/plus-mini-dark.png";

import InputTypeDate from "../../../appTools/appToolsComponents/inputTypeDate/inputTypeDate";
import { InputPickerDateContext } from "../../../appTools/appToolsComponents/inputPickerDate/inputPickerDateContext";
import InputPickerDate from "../../../appTools/appToolsComponents/inputPickerDate/inputPickerDate";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

const InvoicesPage = () => {
    const { accountData, accessTo } = useContext(AuthContext);
    const { appTheme, appLanguage, isTouchEnabled } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized, smallView } = useContext(SideMenuContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const { currentDate, pickerDateActive, setPickerDateActive, setPickerDateTitle, setCurrentDate, inputName, setInputName } = useContext(InputPickerDateContext);

    // This will be save only in Ram Memory
    const [selecting, setSelecting] = useState(false);
    // eslint-disable-next-line
    const [invoicesSelected, setInvoicesSelected] = useState([]);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);

    // eslint-disable-next-line
    const [pageNumber, setPageNumber] = useState(null);

    const [searchingText, setSearchingText] = useState("");
    // eslint-disable-next-line
    const [searchingByTextActive, setSearchingTextActive] = useState(false);

    // Keep state in the current path
    // eslint-disable-next-line
    const [results, setResults] = useState(null);
    const [controler, setControler] = useState(null);

    // eslint-disable-next-line
    const [navControlers, setNavControlers] = useState(null);

    // All of this is to avoid requesting data 
    // from the backend twice
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (refresh === 1 && navControlers && controler) {
            setRefresh(0);
            setPageNumber(navControlers.page);
            // refreshSearch();
        }
        // eslint-disable-next-line
    }, [refresh]);

    const [activeRef, setActiveRef] = useState(null);
    // eslint-disable-next-line
    const [activeId, setActiveId] = useState(null);

    // eslint-disable-next-line
    const setActiveReference = (inputElement) => {
        setActiveRef(inputElement);
    }

    useEffect(() => {
        if (activeRef) {
            activeRef.scrollIntoView({
                block: 'center',
                inline: 'nearest'
            });
            // setActiveId(null);
        }
    }, [activeRef]);

    const navigate = useNavigate();

    const handleChange = e => {
        setSearchingText(e.target.value);
    }

    let inputRef = null;

    const setRefernce = (inputElement) => {
        inputRef = inputElement;
    }

    const cleanInput = () => {
        try {
            if (inputRef) {
                inputRef.focus();
            }
        } catch (error) {
            console.warn(error);
        }

        setSearchingText("");
    }

    const [searchInputFocus, setSearchInputFocus] = useState(false);

    const [disabled, setDisabled] = useState(true);

    const [dateStringFrom, setDateStringFrom] = useState("");
    const [dateStringTo, setDateStringTo] = useState("");

    const [dateObjFrom, setDateObjFrom] = useState(false);
    const [dateObjTo, setDateObjTo] = useState(false);

    const [showAlertFrom, setShowAlertFrom] = useState(false);
    const [showAlertTo, setShowAlertTo] = useState(false);

    // eslint-disable-next-line
    const [filterBy, setFilterBy] = useState("all");

    useEffect(() => {
        if (filterBy === "all" && (!dateObjFrom) && (!dateObjTo)) {
            if (filter) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            setDisabled(false);
        }
        // eslint-disable-next-line
    }, [filterBy, dateObjFrom, dateObjTo])

    useEffect(() => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
        // eslint-disable-next-line
    }, [document.activeElement]);

    const checkSearchInput = () => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
    }

    let observedRef = useRef(null);
    let headerObservedRef = useRef(null);

    const [width, setWidth] = useState(windowDimension.width);
    const [headerHeight, setHeaderHeight] = useState();
    const [filter, setFilter] = useState(null);

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, filter, results, isSideMenuMinimized]);

    const [filterIcon, setFilterIcon] = useState(appTheme.dark ? filterImgDark : filterImgLight);
    const [optionsIcon, setOptionsIcon] = useState(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);

    const [dropDownMenuOpen, setDropDownMenuOpen] = useState(false);
    const [dropDownOptionsOpen, setDropDownOptionsOpen] = useState(false);

    // eslint-disable-next-line
    const onScroll = () => {
        setDropDownMenuOpen(false);
        setDropDownOptionsOpen(false);
    }

    let menuRef = useRef();
    let optionsRef = useRef();

    useEffect(() => {
        if (dropDownMenuOpen) {
            setFilterIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setFilterIcon(appTheme.dark ? filterImgDark : filterImgLight);
        }
    }, [dropDownMenuOpen, appTheme]);

    useEffect(() => {
        if (dropDownOptionsOpen) {
            setOptionsIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setOptionsIcon(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);
        }
    }, [dropDownOptionsOpen, appTheme]);

    useEffect(() => {
        if (dropDownMenuOpen && menuRef) {
            if (menuRef.current) {
                let handler = (e) => {
                    if (!menuRef.current.contains(e.target)) {
                        if (!pickerDateActive) {
                            setDropDownMenuOpen(false);
                        }
                    };
                };
                document.addEventListener("mousedown", handler);
                return () => {
                    document.removeEventListener("mousedown", handler);
                }
            }
        }
    });

    useEffect(() => {
        if (dropDownOptionsOpen) {
            let handler = (e) => {
                if (optionsRef.current) {
                    if (!optionsRef.current.contains(e.target)) {
                        setDropDownOptionsOpen(false);
                    };
                } else {
                    setDropDownOptionsOpen(false);
                }
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickUserButton = () => {
        dropDownMenuOpen ? setDropDownMenuOpen(false) : setDropDownMenuOpen(true);
    }

    const onClickOptionsButton = () => {
        dropDownOptionsOpen ? setDropDownOptionsOpen(false) : setDropDownOptionsOpen(true);
    }

    const esPlaceholder = "Escribe palabra clave aquí...";
    const enPlaceholder = "Write keyword here...";

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            setControler({
                ...controler,
                "searchingText": searchingText,
                "triggerSearch": true,
            });
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.taxes)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    const onClickPickFrom = () => {
        setPickerDateActive(true);
        setPickerDateTitle(appLanguage.en ? "Select the date from which you want to filter" : "Selecciona la fecha desde la cual deseas filtrar");
        if (dateObjFrom) {
            setCurrentDate(dateObjFrom);
        }
        setInputName("dateFrom");
    }

    const onClickPickTo = () => {
        setPickerDateActive(true);
        setPickerDateTitle(appLanguage.en ? "Select the date up to which you want to filter" : "Selecciona la fecha hasta la cual deseas filtrar");
        if (dateObjTo) {
            setCurrentDate(dateObjTo);
        }
        setInputName("dateTo");
    }

    useEffect(() => {
        if (inputName) {
            switch (inputName) {
                case "dateFrom":
                    setDateStringFrom(`${currentDate.getDate().toString().padStart(2, '0')} / ${(currentDate.getMonth() + 1).toString().padStart(2, '0')} / ${currentDate.getFullYear()}`);
                    setShowAlertFrom(false);
                    break;
                case "dateTo":
                    setDateStringTo(`${currentDate.getDate().toString().padStart(2, '0')} / ${(currentDate.getMonth() + 1).toString().padStart(2, '0')} / ${currentDate.getFullYear()}`);
                    setShowAlertTo(false);
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [currentDate]);

    useEffect(() => {
        if (filter) {
            setDateObjFrom(filter.from);
            setDateObjTo(filter.to);
            // setSoldBy(filter.user);

            if (filter.from) {
                setDateStringFrom(`${filter.from.getDate().toString().padStart(2, '0')} / ${(filter.from.getMonth() + 1).toString().padStart(2, '0')} / ${filter.from.getFullYear()}`);
            } else {
                setDateStringFrom("");
            }

            if (filter.to) {
                setDateStringTo(`${filter.to.getDate().toString().padStart(2, '0')} / ${(filter.to.getMonth() + 1).toString().padStart(2, '0')} / ${filter.to.getFullYear()}`);
            } else {
                setDateStringTo("");
            }
        } else {
            setFilterBy("all");
            // setSoldBy("");
            // setStatus("");
            setDateObjFrom(null);
            setDateObjTo(null);
            setDateStringFrom("");
            setDateStringTo("");
        }
        setShowAlertFrom(false);
        setShowAlertTo(false);
        // eslint-disable-next-line
    }, [dropDownMenuOpen]);

    const onClickSelect = () => {
        if (!selecting && loading) {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error: try again.',
                    es: 'Error: inténtalo de nuevo.',
                }
            });
        } else {
            setSelecting(!selecting);
        }
    }

    const onClickApplyFilter = () => {
        let newDateFrom = null;
        let newDateTo = null;

        // if (filterBy !== "all") {
        //     if (filterBy === "soldBy") {
        //         if (soldBy.trim() === "") {
        //             setShowAlertSoldBy(true);
        //             return;
        //         }
        //     } else {
        //         if (status.trim() === "") {
        //             setShowAlertStatus(true);
        //             return;
        //         }
        //     }
        // }

        if ((!dateObjFrom) !== (!dateObjTo)) {
            if (!dateObjFrom) {
                setShowAlertFrom(true);
                return;
            }
            if (!dateObjTo) {
                setShowAlertTo(true);
                return;
            }
        }

        if (dateObjFrom) {
            newDateFrom = new Date(dateObjFrom.getTime());
            newDateFrom.setHours(0);
        }

        if (dateObjTo) {
            newDateTo = new Date(dateObjTo.getTime());
            newDateTo.setHours(23, 59, 59, 999);
        }

        setFilter({
            "filterBy": filterBy,
            // "soldBy": soldBy,
            // "status": status,
            "from": newDateFrom,
            "to": newDateTo,
        });

        setControler({
            ...controler,
            "filterBy": filterBy,
            // "soldBy": soldBy,
            // "status": status,
            "dateFrom": newDateFrom ? newDateFrom.getTime() : null,
            "dateTo": newDateTo ? newDateTo.getTime() : null,
            "searchingText": searchingText,
            "triggerSearch": true,
        });

        // setPageHistory([]);

        // setDropDownMenuOpen(false);
    }

    const onChangeFilterBy = (e) => {
        setFilterBy(e.target.value);
        // setSoldBy("");
        // setStatus("");
    }

    let moreRef = useRef();

    const [moreIcon, setMoreIcon] = useState(appTheme.dark ? arrowDarkIcon : arrowLightIcon);

    const [dropDownMoreOpen, setDropDownMoreOpen] = useState(false);

    useEffect(() => {
        if (dropDownMoreOpen) {
            setMoreIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setMoreIcon(appTheme.dark ? arrowDarkIcon : arrowLightIcon);
        }
    }, [dropDownMoreOpen, appTheme]);

    const onClickMoreButton = () => {
        dropDownMoreOpen ? setDropDownMoreOpen(false) : setDropDownMoreOpen(true);
    }

    useEffect(() => {
        if (dropDownMoreOpen && moreRef) {
            if (moreRef.current) {
                let handler = (e) => {
                    if (!moreRef.current.contains(e.target)) {
                        setDropDownMoreOpen(false);
                    };
                };
                document.addEventListener("mousedown", handler);
                return () => {
                    document.removeEventListener("mousedown", handler);
                }
            }
        }
    });

    const getShowMore = () => {
        if (width > 1055) {
            return false;
        } else {
            return true;
        }
    }

    const whereToShowComprobantesBtn = () => {
        if (width > 950) {
            return "onTop";
        } else {
            return "onMoreOptions";
        }
    }

    const whereToShowCreateInvoiceBtn = () => {
        if (width > 1055) {
            return "onTop";
        } else {
            return "onMoreOptions";
        }
    }

    const onClickComprobantesBtn = () => {
        navigate("/comprobantes-de-facturas")
    }

    const onClickCreateInvoiceBtn = () => {
        // navigate(null, { state: null })
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Sales invoices' : 'Facturas de ventas'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo && (!controler) ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`} ref={observedRef}>
                            {accessTo.taxes ? <Fragment>
                                <div>
                                    <InputPickerDate pickerWidth={width} />

                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? 'Sales invoices' : 'Facturas de ventas'}
                                        />
                                        : null}

                                    <div ref={headerObservedRef} className="products-services-first-section">
                                        <div className="products-services-first-item" style={{ maxWidth: "1090px" }}>
                                            <div className="div-search-input-home" style={{ marginTop: 0, maxWidth: "400px" }}>
                                                <input
                                                    ref={setRefernce}
                                                    type="search"
                                                    onBlur={checkSearchInput}
                                                    onFocus={checkSearchInput}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                    className={`search-input-home ${appTheme.dark ? "search-input-home-dar dar-sec-bg" : "search-input-home-lig lig-sec-bg"}`}
                                                    value={searchingText}
                                                    onChange={e => handleChange(e)}
                                                    name="searchingText"
                                                    onKeyDown={e => handleKeyPress(e)}
                                                    maxLength={30}
                                                    placeholder={appLanguage.en ? enPlaceholder : esPlaceholder}
                                                    style={{
                                                        colorScheme: appTheme.dark ? "dark" : "light"
                                                    }}
                                                />
                                                {searchingText === "" ? null :
                                                    <div className={`search-div-delete ${searchInputFocus ? (appTheme.dark ? "search-button-home-dar-focus" : "search-button-home-lig-focus") : (appTheme.dark ? "search-button-home-dar" : "search-button-home-lig")} ${appTheme.dark ? " dar-sec-bg" : "lig-sec-bg"}`}>
                                                        <button onClick={cleanInput} className={appTheme.dark ? "search-div-delete-button-dark" : "search-div-delete-button-light"}>
                                                            <img src={appTheme.dark ? crossWhiteIcon : crossIcon} alt="delete" />
                                                        </button>
                                                    </div>
                                                }
                                                <button
                                                    className="search-button-home"
                                                    type="submit"
                                                // onClick={search}
                                                >
                                                    <img src={magnifyingIcon} alt="Search box" />
                                                </button>
                                            </div>

                                            <div className="sales-filter-botton-main unselectable">

                                                {!selecting ?
                                                    <div ref={menuRef} className="sales-filter-botton-container">

                                                        <button
                                                            onClick={onClickUserButton}
                                                            className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                            style={{
                                                                width: smallView ? "60px" : "100px",
                                                                minHeight: "38.4px"
                                                            }}
                                                        >
                                                            <img
                                                                style={{ width: "20px", height: "20px", marginRight: smallView ? 0 : "12px" }}
                                                                src={filterIcon}
                                                                alt="Filter"
                                                            />
                                                            {smallView ? null : <p style={dropDownMenuOpen ? { fontWeight: "bold" } : {}}>{appLanguage.en ? "Filter" : "Filtrar"}</p>}
                                                        </button>

                                                        <span
                                                            className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownMenuOpen ? 'active' : 'inactive'}`}
                                                            style={{
                                                                marginLeft: smallView ? "43px" : "60px",
                                                            }}
                                                        />

                                                        <div
                                                            style={{
                                                                boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                marginLeft: "25px",
                                                            }}
                                                            className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"} ${dropDownMenuOpen ? 'active' : 'inactive'}`}
                                                        >
                                                            {smallView ? <p style={{ textAlign: "center", marginBottom: "10px" }}><b>{appLanguage.en ? "Filter invoices" : "Filtrar facturas"}</b></p> : null}

                                                            <label className="filter-labels-inputs" htmlFor="filterBy">{appLanguage.en ? "Filter by" : "Filtrar por"}</label>
                                                            <select
                                                                style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                                className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg input-border-box-dark" : "lig-pri-bg input-border-box-light"}`}
                                                                name="filterBy"
                                                                id="filterBy"
                                                                value={filterBy}
                                                                onChange={e => onChangeFilterBy(e)}
                                                            >
                                                                <option value="all">{appLanguage.en ? "All" : "Todo"}</option>
                                                            </select>

                                                            <label className="filter-labels-inputs">{appLanguage.en ? "From " : "Desde "} {showAlertFrom ? (appLanguage.en ? "** invalid **" : "** inválida **") : null}</label>
                                                            <InputTypeDate
                                                                dateObj={dateObjFrom}
                                                                setDateObj={setDateObjFrom}
                                                                dateString={dateStringFrom}
                                                                setDateString={setDateStringFrom}
                                                                showAlert={showAlertFrom}
                                                                setShowAlert={setShowAlertFrom}
                                                                onClickPick={onClickPickFrom}
                                                            />

                                                            <label className="filter-labels-inputs">{appLanguage.en ? "To" : "Hasta"} {showAlertTo ? (appLanguage.en ? "** invalid **" : "** inválida **") : null}</label>
                                                            <InputTypeDate
                                                                dateObj={dateObjTo}
                                                                setDateObj={setDateObjTo}
                                                                dateString={dateStringTo}
                                                                setDateString={setDateStringTo}
                                                                showAlert={showAlertTo}
                                                                setShowAlert={setShowAlertTo}
                                                                onClickPick={onClickPickTo}
                                                            />

                                                            <div className="filter-button-action-div">
                                                                <button
                                                                    disabled={disabled}
                                                                    onClick={onClickApplyFilter}
                                                                    className="filter-button-btn-div"
                                                                    style={disabled ? {
                                                                        backgroundColor: "#3f526698",
                                                                        color: appTheme.dark ? "rgb(173, 173, 173)" : "rgba(255, 255, 255, 0.712)",
                                                                        cursor: "default",
                                                                        boxShadow: "none",
                                                                    } : null}
                                                                >
                                                                    {appLanguage.en ? "Apply" : "Aplicar"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}

                                                {selecting ?
                                                    <div ref={optionsRef} className="sales-filter-botton-container">
                                                        <button
                                                            onClick={onClickOptionsButton}
                                                            style={{
                                                                margin: "0px 10px 0px 10px",
                                                                paddingLeft: "8px",
                                                                paddingRight: "8px",
                                                                width: "90px",
                                                            }}
                                                            className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    marginRight: "10px"
                                                                }}
                                                                src={optionsIcon}
                                                                alt="Options"
                                                            />
                                                            <p style={dropDownOptionsOpen ? { fontWeight: "bold" } : {}}>{invoicesSelected ? invoicesSelected.length : ""}</p>
                                                        </button>

                                                        <span
                                                            className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownOptionsOpen ? 'active' : 'inactive'}`}
                                                            style={{
                                                                marginLeft: "45px",
                                                            }}
                                                        />

                                                        <div
                                                            style={{
                                                                boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                marginLeft: "13px",
                                                                width: "210px",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}
                                                            className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"}   ${dropDownOptionsOpen ? 'active' : 'inactive'}`}
                                                        >
                                                            <button
                                                                style={{ marginTop: "20px" }}
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={null}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Download 1" : "Descargar 1"} </p>
                                                            </button>

                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={null}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Download 2" : "Descargar 2"} </p>
                                                            </button>

                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={null}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Download 3" : "Descargar 3"} </p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    : null}

                                                <button
                                                    className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                    style={{
                                                        margin: "20px 10px 0 10px",
                                                        height: "38.4px"
                                                    }}
                                                    disabled={loading ? true : false}
                                                    onClick={onClickSelect}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={selecting}
                                                        onChange={onClickSelect}
                                                        name='customerAccounts'
                                                        id='customerAccounts'
                                                        style={{
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                            marginRight: "10px",
                                                        }}
                                                    />
                                                    <p>{appLanguage.en ? "Select" : "Seleccionar"}</p>

                                                </button>

                                                {whereToShowComprobantesBtn() === "onTop" ?
                                                    <div className="customer-header-button-div" style={{ marginTop: "20px" }}>
                                                        <Link to={"/comprobantes-de-facturas"} className="customer-header-button-link" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                                            <img src={termsCondIconDark} alt="Comprobantes" />
                                                            <p style={{ whiteSpace: "nowrap" }}><b>{"Comprobantes"}</b></p>
                                                        </Link>
                                                    </div>
                                                    : null}

                                                {whereToShowCreateInvoiceBtn() === "onTop" ?
                                                    <div className="customer-header-button-div" style={{ marginTop: "20px" }}>
                                                        <Link to={null} className="customer-header-button-link" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                                            <img src={addImgDark} alt="Create invoice" />
                                                            <p style={{ whiteSpace: "nowrap" }}><b>{appLanguage.en ? "Create invoice" : "Crear factura"}</b></p>
                                                        </Link>
                                                    </div>
                                                    : null}

                                                {getShowMore() ?
                                                    <div ref={moreRef} className="notification-filter-botton-container unselectable">
                                                        <button
                                                            className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                            onClick={onClickMoreButton}
                                                            style={{
                                                                margin: "20px 10px 0px",
                                                                minHeight: "38.4px",
                                                            }}
                                                        >
                                                            <img
                                                                src={moreIcon}
                                                                style={{
                                                                    width: "18px",
                                                                    height: "18px",
                                                                }}
                                                                alt="More options"
                                                            />
                                                        </button>

                                                        <span
                                                            className={`notification-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownMoreOpen ? 'active' : 'inactive'}`}
                                                            style={{
                                                                marginLeft: "30px",
                                                                marginTop: "7px",
                                                            }}
                                                        />

                                                        <div
                                                            style={{
                                                                boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                marginLeft: "-181px",
                                                                marginTop: "11px",
                                                                width: "220px",
                                                                minHeight: "auto"
                                                            }}
                                                            className={`dropdown-menu-notification ${appTheme.dark ? "dropdown-menu-notification-dark border-box-dark" : "dropdown-menu-notification-light border-box-light"} ${dropDownMoreOpen ? 'active' : 'inactive'}`}
                                                        >
                                                            <div
                                                                style={{
                                                                    paddingTop: "13px"
                                                                }}
                                                            >
                                                                {whereToShowComprobantesBtn() === "onMoreOptions" ?
                                                                    <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                        <button
                                                                            className="invent-add-menu-btn"
                                                                            disabled={loading ? true : false}
                                                                            onClick={onClickComprobantesBtn}
                                                                            style={{
                                                                                justifyContent: "flex-start"
                                                                            }}
                                                                        >
                                                                            <img
                                                                                style={{
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                    marginRight: "10px",
                                                                                    marginLeft: "18px"
                                                                                }}
                                                                                src={termsCondIconDark}
                                                                                alt=""
                                                                            />
                                                                            <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{"Comprobantes"} </p>
                                                                        </button>
                                                                    </div>
                                                                    : null}

                                                                {whereToShowCreateInvoiceBtn() === "onMoreOptions" ?
                                                                    <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                        <button
                                                                            className="invent-add-menu-btn"
                                                                            disabled={loading ? true : false}
                                                                            onClick={onClickCreateInvoiceBtn}
                                                                            style={{
                                                                                justifyContent: "flex-start",
                                                                                marginBottom: "15px"
                                                                            }}
                                                                        >
                                                                            <img
                                                                                style={{
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                    marginRight: "10px",
                                                                                    marginLeft: "18px"
                                                                                }}
                                                                                src={addImgDark}
                                                                                alt=""
                                                                            />
                                                                            <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "Create invoice" : "Crear factura"} </p>
                                                                        </button>
                                                                    </div>
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>

                                            {searchingByTextActive ?
                                                <div
                                                    className={`filter-sales-details-main-con ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <div className="filter-sales-details-data">
                                                        <p style={{ color: appTheme.dark ? "#74b7ff" : "#1083ff" }}><b>{appLanguage.en ? "Search" : "Búsqueda"}</b></p>
                                                        <p>{appLanguage.en ? "Sorted by name" : "Ordenado por nombre"}</p>
                                                    </div>
                                                    <button className="filter-sales-details-clean-filter-btn" onClick={null}>
                                                        <img
                                                            style={{ width: "16px", height: "16px", marginRight: "16px" }}
                                                            src={appTheme.dark ? crossWhiteIcon : crossIcon}
                                                            alt="Clean filter"
                                                        />
                                                    </button>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    );
}

export default InvoicesPage;