import "./salesSummary.page.scss";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import arrowLeftDarkIcon from "../../../icons/arrow-left-dark.png";
import dollarSighDarkIcon from "../../../icons/dollar-sign-dark.png";

import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";

import arrowDarkIcon from "../../../icons/arrowhead-dark.png";
import arrowLightIcon from "../../../icons/arrowhead-light.png";

import closeLightIcon from '../../../icons/cross.png';
import closeDarkIcon from '../../../icons/cross-white.png';

import termsCondIconDark from '../../../icons/terms-and-conditions-dark.png';
import termsCondIconLight from "../../../icons/terms-and-conditions-light.png";

import arrowIconDark from '../../../icons/arrow-small-right-white.png';

import saveIcon from "../../../icons/check-dark.png";

import pendingIcon from "../../../icons/sale-pending.png";

import copyIconDark from '../../../icons/copy.png';
import copyIconLight from "../../../icons/copyLight.png";

import CollectPayment from "../../../appTools/appToolsComponents/collectPayment/collectPayment";
import { CollectPaymentContext } from "../../../appTools/appToolsComponents/collectPayment/collectPaymentContext";
import SaleMoreOptions from "../../../appTools/appToolsComponents/saleMoreOptions/saleMoreOptions";
import { SaleMoreOptionsContext } from "../../../appTools/appToolsComponents/saleMoreOptions/saleMoreOptionsContext";
import { AlertContext } from "../../../components/alert/alertContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import SalesSelectTable from "../../../appTools/appToolsComponents/salesSelectTable/salesSelectTable";

import firebase from "../../../firebase";
import { onSnapshot, doc } from "firebase/firestore";
import Spinner from "../../../components/spinner/spinner";

const SalesSummaryPage = () => {
    const { appTheme, appLanguage, isTouchEnabled, setDropDownCartOpen, setDropDownProfileMenuOpen, formatRationalNumber, getComprobanteTitleById, getComprobanteEmoji } = useContext(AppContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, setOnAgree, getErrorDescription } = useContext(AlertContext);
    const { accountData, accessTo, shift, shoppingCart, updateShoppingCartInfoFromServer, removeDiscountFromShoppingCart, saveKeyInIndexedDB, setShoppingCart, deleteShoppingCart, changePriceInShoppigCart, authorizedUsers, setSaleListener, setAuthorizedUsersListener, setSoldByInShoppingCart, comprobantesSummary } = useContext(AuthContext);
    const { desktopView, windowDimension } = useContext(SideMenuContext);
    const { setCashAmount, setCashToCharge, setCollectPaymentActive, loading, getPendingAmount } = useContext(CollectPaymentContext);
    const { setSaleMoreOptionsActive, setOption } = useContext(SaleMoreOptionsContext);

    const navigate = useNavigate();
    const location = useLocation();

    const [results, setResults] = useState(null);
    const [isSaleActive, setIsSaleActive] = useState(false);

    // This prevent the page send a request twice to the backend
    const [generalState, setGeneralState] = useState(false);

    useEffect(() => {
        if (accountData && generalState) {
            updateShoppingCartInfoFromServer();
        }
        // eslint-disable-next-line
    }, [accountData, generalState]);

    useEffect(() => {
        setSaleListener(true);
        setAuthorizedUsersListener(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setGeneralState(true);
        // eslint-disable-next-line 
    }, []);

    const [minimizeOptions, setMinimizeOptions] = useState(windowDimension.width > 550 ? false : true);

    useEffect(() => {
        setMinimizeOptions(windowDimension.width > 550 ? false : true);
    }, [windowDimension])

    const [optionsIcon, setOptionsIcon] = useState(appTheme.dark ? arrowDarkIcon : arrowLightIcon);

    const [dropDownOptionsOpen, setDropDownOptionsOpen] = useState(false);

    let pageObserverRef = useRef(null);
    let optionsRef = useRef();

    useEffect(() => {
        if (dropDownOptionsOpen) {
            setOptionsIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setOptionsIcon(appTheme.dark ? arrowDarkIcon : arrowLightIcon);
        }
    }, [dropDownOptionsOpen, appTheme]);

    const onClickOptionsButton = () => {
        dropDownOptionsOpen ? setDropDownOptionsOpen(false) : setDropDownOptionsOpen(true);
    }

    const onScroll = () => {
        setDropDownOptionsOpen(false);
        setDropDownCartOpen(false);
        setDropDownProfileMenuOpen(false);
    }

    const [pageWidth, setPageWidth] = useState(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return windowDimension.width;
            } else {
                try {
                    const width = pageObserverRef.current.offsetWidth
                    return width;
                } catch {
                    return windowDimension.width;
                }
            }
        } else {
            return windowDimension.width;
        }
    });

    useEffect(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return;
            }
            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (pageObserverRef.current.offsetWidth !== pageWidth) {
                        setPageWidth(pageObserverRef.current.offsetWidth);
                    }
                } catch { }
            });
            resizeObserver.observe(pageObserverRef.current);
            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [pageObserverRef.current, windowDimension]);

    useEffect(() => {
        if (dropDownOptionsOpen) {
            let handler = (e) => {
                if (optionsRef.current) {
                    if (!optionsRef.current.contains(e.target)) {
                        setDropDownOptionsOpen(false);
                    };
                } else {
                    setDropDownOptionsOpen(false);
                }
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onPrevious = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }

    const isCustomerSelected = () => {
        if (shoppingCart) {
            if (shoppingCart.customer) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    // eslint-disable-next-line
    const onOpenCustomer = () => {
        if (isCustomerSelected()) {
            navigate("/ver-cliente", { state: shoppingCart.customer })
        }
    }

    const onClickOpenProduct = (product) => {
        navigate("/ver-producto", { state: product.product })
    }

    const onCollectPayment = () => {
        setCollectPaymentActive(true);
        if (shoppingCart) {
            setCashAmount(formatRationalNumber(Math.round(getPendingAmount(shoppingCart))));
            setCashToCharge(formatRationalNumber(Math.round(getPendingAmount(shoppingCart))));
        }
    }

    const confirmRemoveDiscount = () => {
        removeDiscountFromShoppingCart();
        setAlertActive(false);
        setSoftAlertActive(true);
        setSoftAlertData({
            type: 'sucess',
            text: {
                en: 'The discount has been removed.',
                es: 'El descuento ha sido removido. ',
            }
        });
    }

    const onClickApplyDiscount = () => {
        if (shoppingCart) {
            if (shoppingCart.discount) {
                setDropDownOptionsOpen(false);
                setAlertData({
                    type: 'question',
                    title: {
                        en: `Remove discount?`,
                        es: `¿Remover descuento?`
                    },
                    code: '',
                    description: {
                        en: `Are you sure you want to remove the discount?`,
                        es: `¿Estás segura de que quieres remover el descuento?`
                    }
                });
                setOnAgree(() => confirmRemoveDiscount);
                setAlertActive(true);
            } else {
                setSaleMoreOptionsActive(true);
                setDropDownOptionsOpen(false);
                setOption("applyDiscount");
            }
        }
    }

    const onClickOpenSale = () => {
        navigate("/ver-detalles-de-venta", { state: { id: results.sale.id } })
    }

    const onClickGetQuote = () => {
        setSaleMoreOptionsActive(true);
        setDropDownOptionsOpen(false);
        setOption("getQuote");
    }

    const leavePending = async () => {
        setSaleMoreOptionsActive(true);
        setDropDownOptionsOpen(false);
        setOption("leavePending");

        try {
            let res = null;

            for (let i = 0; i < shoppingCart.items.length; i++) {
                const itemQuantity = shoppingCart.items[i].quantity;
                const productQuantity = shoppingCart.items[i].product.quantity;

                if (productQuantity !== null) {
                    if (itemQuantity > productQuantity) {
                        res = { msg: "thereAreNotEnough" };
                    }
                }
            }

            if (!res) {
                res = await firebase.useProcessSalePayment({
                    "shoppingCart": shoppingCart,
                    "businessId": accountData.id,
                    "paymentDetails": null,
                });
            }

            switch (res.msg) {
                case "unauthorizedDiscount":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Without authorization to apply discounts`,
                            es: `Sin autorización para aplicar descuentos`
                        },
                        code: "",
                        description: {
                            en: `You are not authorized to apply a discount. Please refresh the page and try again.`,
                            es: `No tienes autorización para aplicar un descuento. Por favor, refresque la página y trate de nuevo. `
                        }
                    });
                    setAlertActive(true);
                    break;
                case "customerDoesNotExist":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Client does not exist`,
                            es: `Cliente no existe`
                        },
                        code: "",
                        description: {
                            en: `The customer selected for this sale does not exist. Please refresh the page, check the shopping cart and try again.`,
                            es: `El cliente seleccionado para esta venta no existe. Por favor, refresque la página, revisa el carrito de compras y trate de nuevo.`
                        }
                    });
                    setAlertActive(true);
                    break;
                case "productDoesNotExist":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Product does not exist`,
                            es: `Producto no existe`
                        },
                        code: "",
                        description: {
                            en: `One or more of the products you are trying to sell does not exist. Please refresh the page and try again.`,
                            es: `Uno o más de los productos que intenta vender no existen. Por favor, refresque la página e inténtelo de nuevo.`
                        }
                    });
                    setAlertActive(true);
                    break;
                case "productOutOfStock":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Product out of stock`,
                            es: `Producto agotado`
                        },
                        code: "",
                        description: {
                            en: `One or more of the items in your shopping cart are out of stock. Please refresh the page, return to cart and then remove those items.`,
                            es: `Uno o más de los artículos del carrito de compras están agotados. Por favor, refresque la página, regrese al carrito y luego remueva dichos artículos.`
                        }
                    });
                    setAlertActive(true);
                    break;
                case "thereAreNotEnough":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `There are not enough in inventory`,
                            es: `No hay suficientes en el inventario`
                        },
                        code: "",
                        description: {
                            en: `There are not enough units of one or more items. Please refresh the page, return to cart and adjust the quantity of said item if it is in red.`,
                            es: `No hay suficientes unidades de uno o más artículos. Por favor, refresque la página, regrese al carrito y ajuste la cantidad de dichos artículo si está en rojo.`
                        }
                    });
                    setAlertActive(true);
                    break;
                case "inconsistentPrice":
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Inconsistent prices`,
                            es: `Precios inconsistentes`,
                        },
                        code: "",
                        description: {
                            en: `The data you are sending and the data processed by the server are inconsistent, please refresh the page, check the prices and try again.`,
                            es: `Los datos que estás enviando y los datos procesados ​​por el servidor son inconsistentes, por favor refresque la página, verifique los precios y trate de nuevo.`
                        }
                    });
                    setAlertActive(true);
                    break;
                default:
                    // this control the spinner
                    setSaleMoreOptionsActive(false);
                    setOption("");
                    const newState = {
                        sale: res.sale,
                        customer: shoppingCart.customer,
                    }

                    deleteShoppingCart();
                    navigate("/venta-exitosa", { replace: true, state: newState });
                    break;
            }
        } catch (error) {
            const code = "error";
            // this control the spinner
            setSaleMoreOptionsActive(false);
            setOption("");

            setDropDownOptionsOpen(true);

            setAlertData({
                type: 'error',
                title: {
                    en: `Error when trying to leave a pending sale.`,
                    es: `Error al intentar dejar una venta pendiente.`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const confirmLeavePending = () => {
        setAlertActive(false);
        if (isSaleActive) {
            deleteShoppingCart();
            if (results) {
                const newState = {
                    sale: results.sale,
                    customer: shoppingCart.customer,
                }
                navigate("/venta-exitosa", { replace: true, state: newState });
            } else {
                onPrevious();
            }
        } else {
            leavePending();
        }
    }

    const onClickLeavePending = () => {
        if (Number(shoppingCart.total) === 0) {
            return;
        }

        setAlertData({
            type: 'question',
            title: {
                en: `Leave sale in pending status?`,
                es: `¿Dejar la venta en estado pendiente?`
            },
            code: '',
            description: {
                en: `If you leave the sale in pending status you will be able to complete the payment of the remaining amount later.`,
                es: `Si dejas la venta en estado pendiente podrás completar el pago del monto restante más tarde.`
            }
        });
        setOnAgree(() => confirmLeavePending);
        setAlertActive(true);
    }

    const getShowOptions = () => {
        if (results) {
            if (accessTo.salesSup) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const getShowCancelSale = () => {
        if (results) {
            if (accessTo.salesSup) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }

            if (accountData.useShifts) {
                if (shift !== null) {
                    if (!shift) {
                        navigate("/gestionar-turnos", { replace: true })
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [accountData, shift]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.sales)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        if (shoppingCart) {
            if (shoppingCart.items.length === 0) {
                navigate("/carrito-de-compras", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!shoppingCart && results) {
            if (results.sale) {
                setIsSaleActive(true);
                setShoppingCart(results.shoppingCart);
                saveKeyInIndexedDB("shoppingCart", results.shoppingCart);
            }
        }
        // eslint-disable-next-line 
    }, [results]);

    useEffect(() => {
        if (location.state) {
            setResults(location.state)
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (results) {
            navigate(null, { replace: true, state: results });
        }
        // eslint-disable-next-line
    }, [results]);

    const copySaleId = () => {
        try {
            const text = shoppingCart.salesId;
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The sale Id has been copied',
                    es: 'El Id de la venta ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const handleChangePrice = (item, e) => {
        changePriceInShoppigCart(item.product.id, e.target.value)
    }

    const [saleId, setSaleId] = useState(null);

    useEffect(() => {
        if (shoppingCart && shoppingCart.salesId) {
            setSaleId(shoppingCart.salesId)
        }
    }, [shoppingCart]);

    useEffect(() => {
        if (saleId) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/sales`, saleId), (doc) => {
                const saleData = {
                    ...doc.data(),
                    "id": doc.id,
                };

                if (doc.data()) {
                    if (doc.data().status === "completed") {
                        setSaleMoreOptionsActive(false);
                        setCollectPaymentActive(false);
                        setOption("");

                        const newState = {
                            sale: saleData,
                            customer: shoppingCart.customer,
                        }

                        deleteShoppingCart();
                        navigate("/venta-exitosa", { replace: true, state: newState });
                    } else {
                        const newShoppingCart = {
                            ...shoppingCart,
                            "payments": saleData.payments,
                        }

                        setResults({
                            "sale": saleData,
                            "shoppingCart": newShoppingCart
                        });

                        setShoppingCart(newShoppingCart);
                        saveKeyInIndexedDB("shoppingCart", newShoppingCart);
                    }
                }
            }, err => {
                console.error(err);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [saleId]);

    const onChangeSoldBy = (e) => {
        setSoldByInShoppingCart(e.target.value);
    }

    let comprobantePickerRef = useRef(null);

    const [comprobantePickerActive, setComprobantePickerActive] = useState(false);

    const [showSave, setShowSave] = useState(false);

    const onClickComprobantePicker = () => {
        setComprobantePickerActive(true);
    }

    useEffect(() => {
        if (comprobantePickerActive) {
            let handler = (e) => {
                if (!comprobantePickerRef.current.contains(e.target)) {
                    if (comprobantePickerActive && (!showSave) && (!loading)) {
                        setComprobantePickerActive(false);
                    }
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const getShoppingCartComprobante = () => {
        if (shoppingCart && shoppingCart.comprobante && shoppingCart.comprobante.tipo !== "00") {
            return getComprobanteTitleById(shoppingCart.comprobante.tipo)
        } else {
            return "Informal"
        }
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Sales summary" : "Resumen de venta"} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo && shoppingCart ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef} className={`page-choose-account-item`}>
                            {accessTo.sales ? <Fragment>
                                <CollectPayment
                                    pickerWidth={pageWidth}
                                    setResults={setResults}
                                    isSaleActive={isSaleActive}
                                    setIsSaleActive={setIsSaleActive}
                                    showPartialPayment={!(Number(shoppingCart.total) === 0)}
                                />

                                <SaleMoreOptions pickerWidth={pageWidth} />

                                <ComprobantePicker
                                    comprobantePickerActive={comprobantePickerActive}
                                    pickerWidth={pageWidth}
                                    comprobantePickerRef={comprobantePickerRef}
                                    setComprobantePickerActive={setComprobantePickerActive}
                                    shoppingCart={shoppingCart}
                                    showSave={showSave}
                                    setShowSave={setShowSave}
                                    isSaleActive={isSaleActive}
                                    saleId={saleId}
                                    setResults={setResults}
                                />

                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Sales summary" : "Resumen de venta"}
                                    />
                                    : null}

                                <div style={{ marginTop: "25px", display: "flex", width: "100%", justifyContent: "center" }}>
                                    {/** Main container for seccion */}
                                    <div style={{ width: "100%", maxWidth: "765px" }}>
                                        {/** Customer and options */}
                                        <div className="unselectable" style={{ display: "flex", width: "100%", marginBottom: "20px" }}>

                                            <div
                                                className="shoppo-cart-cust-card-div"
                                                style={{
                                                    display: "flex",
                                                    borderRadius: "100px",
                                                    margin: "1px 10px 0 20px",
                                                    width: "calc(100% - 40px)",
                                                    justifyContent: "space-between",
                                                    height: "36px",
                                                    minHeight: "36px",
                                                }}
                                            >
                                                <div
                                                    onClick={onClickComprobantePicker}
                                                    className={`${appTheme.dark ? (isTouchEnabled() ? "shoppo-cart-cust-card-div-dark-NOHOVER" : "shoppo-cart-cust-card-div-dark-HOVER") : (isTouchEnabled() ? "shoppo-cart-cust-card-div-light-NOHOVER" : "shoppo-cart-cust-card-div-light-HOVER")}`}
                                                    style={{
                                                        display: "flex",
                                                        borderRadius: "100px",
                                                        paddingLeft: "10px",
                                                        width: "100%",
                                                        alignItems: "center",
                                                        height: "36px",
                                                        minHeight: "36px",
                                                    }}
                                                >
                                                    <img
                                                        src={appTheme.dark ? termsCondIconDark : termsCondIconLight}
                                                        alt="Comprobante settings"
                                                        style={{
                                                            width: "20px",
                                                            height: "20px",
                                                            marginRight: "10px"
                                                        }}
                                                    />

                                                    <p
                                                        style={{
                                                            "display": "-webkit-box",
                                                            "WebkitBoxOrient": "vertical",
                                                            "WebkitLineClamp": "1",
                                                            "lineClamp": "1",
                                                            "overflow": "hidden",
                                                            "textOverflow": "ellipsis",
                                                            fontWeight: "400",
                                                            fontSize: "16px"
                                                        }}
                                                    >
                                                        {getShoppingCartComprobante()} {getComprobanteEmoji(shoppingCart.comprobante.tipo, comprobantesSummary)} • {shoppingCart.comprobante.cliente.nombre}
                                                    </p>
                                                </div>
                                            </div>

                                            {getShowOptions() ?
                                                <div ref={optionsRef} className="sales-filter-botton-container" style={{ marginTop: 0 }}>
                                                    <button
                                                        onClick={onClickOptionsButton}
                                                        style={{
                                                            margin: "0px 20px 0px 10px",
                                                            paddingLeft: "8px",
                                                            paddingRight: "8px",
                                                            width: minimizeOptions ? "50px" : "162px",
                                                            height: "38px"
                                                        }}
                                                        className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                    >
                                                        <img
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                                marginRight: minimizeOptions ? "" : "10px"
                                                            }}
                                                            src={optionsIcon}
                                                            alt="Options"
                                                        />
                                                        {minimizeOptions ? null : <p style={dropDownOptionsOpen ? { fontWeight: "bold", whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}>{appLanguage.en ? "More options" : "Más opciones"}</p>}
                                                    </button>

                                                    <span
                                                        className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownOptionsOpen ? 'active' : 'inactive'}`}
                                                        style={{
                                                            marginLeft: minimizeOptions ? "27px" : "100px",
                                                        }}
                                                    />

                                                    <div
                                                        style={{
                                                            boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                            marginLeft: minimizeOptions ? "-170px" : "-58px",
                                                            width: "210px",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                        className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"}   ${dropDownOptionsOpen ? 'active' : 'inactive'}`}
                                                    >

                                                        {minimizeOptions ? <p style={{ textAlign: "center", marginTop: "10px" }}><b>{appLanguage.en ? "More options" : "Más opciones"}</b></p> : null}

                                                        {getShowCancelSale() ? <Fragment>

                                                            <img
                                                                style={{ width: "50px", height: "50px", marginTop: "20px" }}
                                                                src={pendingIcon}
                                                                alt="Sales status"
                                                            />

                                                            <p style={{ marginTop: "15px", fontWeight: "bold" }}>{appLanguage.en ? "Pending sale" : "Venta pendiente"}</p>

                                                            <span
                                                                onClick={copySaleId}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                                className={`sale-copy-id-successful ${isTouchEnabled() ? "sale-copy-id-successful-no-hover" : "sale-copy-id-successful-hover"}`}
                                                            >

                                                                <p style={{ marginRight: "10px" }}>{shoppingCart.salesId}</p>

                                                                <img
                                                                    src={appTheme.dark ? copyIconDark : copyIconLight}
                                                                    style={{ width: "16px", height: "16px" }}
                                                                    alt="Copy"
                                                                />
                                                            </span>

                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={onClickOpenSale}
                                                                style={{ marginTop: "20px" }}
                                                            >

                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Open sale" : "Abrir venta"} </p>
                                                                <img src={arrowIconDark} style={{ marginLeft: "10px", width: "16px", height: "16px" }} alt="Open sale" />
                                                            </button>

                                                        </Fragment> : <Fragment>

                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    width: "calc(100% - 15px)",
                                                                    flexDirection: "column",
                                                                    margin: "10px 0px"
                                                                }}
                                                            >
                                                                <label className="filter-labels-inputs" htmlFor="soldBy">{appLanguage.en ? "Assigned seller" : "Vendedor asignado"}:</label>
                                                                <select
                                                                    style={{
                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                        fontSize: "15px",
                                                                        fontWeight: "500",
                                                                    }}
                                                                    className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"} ${appTheme.dark ? "input-border-box-dark" : "input-border-box-light"}`}
                                                                    name="soldBy"
                                                                    id="soldBy"
                                                                    value={shoppingCart.soldBy}
                                                                    onChange={e => onChangeSoldBy(e)}
                                                                >
                                                                    {authorizedUsers ? <Fragment>
                                                                        {authorizedUsers.length > 0 ?
                                                                            <Fragment>
                                                                                {authorizedUsers.filter((record) => record.status !== "removed").map((record) => {
                                                                                    return (
                                                                                        <option key={record.id} value={record.id}>{record.name}</option>
                                                                                    );
                                                                                })}
                                                                            </Fragment>
                                                                            : null}
                                                                    </Fragment> : null}
                                                                </select>
                                                            </div>

                                                            {accessTo.salesSup ?
                                                                <button
                                                                    className="invent-add-menu-btn"
                                                                    disabled={loading ? true : false}
                                                                    onClick={onClickApplyDiscount}
                                                                    style={{ marginTop: "20px" }}
                                                                >
                                                                    <p style={{ whiteSpace: "nowrap" }}>
                                                                        {shoppingCart.discount ?
                                                                            (appLanguage.en ? "Remove discount" : "Remover descuento")
                                                                            :
                                                                            (appLanguage.en ? "Apply discount" : "Aplicar descuento")
                                                                        }
                                                                    </p>
                                                                </button>
                                                                : null}

                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={onClickGetQuote}
                                                                style={!accessTo.salesSup ? { marginTop: "20px" } : {}}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Get quote" : "Obtener cotización"} </p>
                                                            </button>

                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={(loading || Number(shoppingCart.total) === 0) ? true : false}
                                                                onClick={onClickLeavePending}
                                                                style={(loading || Number(shoppingCart.total) === 0) ? {
                                                                    backgroundColor: "rgba(63, 82, 102, 0.596)",
                                                                    cursor: "not-allowed",
                                                                } : {}}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>
                                                                    {appLanguage.en ? "Leave pending" : "Dejar pendiente"}
                                                                </p>
                                                            </button>

                                                        </Fragment>}
                                                    </div>
                                                </div>
                                                : null}
                                        </div>

                                        <SalesSelectTable
                                            onScroll={onScroll}
                                            headerHeight={desktopView ? (minimizeOptions ? 335 : 280) : (minimizeOptions ? 316 : 241)}
                                            results={shoppingCart ? shoppingCart.items : []}
                                            canSelectPrice={shoppingCart && (!shoppingCart.salesId)}
                                            onClickLink={onClickOpenProduct}
                                            dropDownOptionsOpen={dropDownOptionsOpen}
                                            handleChangePrice={handleChangePrice}
                                        />

                                        {/** Bottons and prices */}
                                        <div style={{ display: "flex", width: "100%", flexWrap: "nowrap", justifyContent: minimizeOptions ? "flex-end" : "" }}>
                                            {minimizeOptions ? null :
                                                <div
                                                    className="product-details-page-botttons-section unselectable"
                                                    style={{
                                                        justifyContent: "flex-start",
                                                        alignItems: "center",
                                                        flexWrap: "nowrap"
                                                    }}
                                                >
                                                    {isSaleActive ?
                                                        <button
                                                            onClick={onClickLeavePending}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            style={{
                                                                margin: "10px 22px 10px 0px",
                                                                height: "40px",
                                                                minWidth: "135px",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            {appLanguage.en ? "Leave pending" : "Dejar pendiente"}
                                                        </button>
                                                        :
                                                        <button
                                                            onClick={onPrevious}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            style={{
                                                                margin: "10px 22px 10px 0px",
                                                                height: "40px",
                                                                minWidth: "135px"
                                                            }}
                                                        >
                                                            <img style={{ width: "20px", height: "20px" }} src={arrowLeftDarkIcon} alt="Go back" />
                                                            {appLanguage.en ? "Go back" : "Volver"}
                                                        </button>
                                                    }

                                                    {shoppingCart ? (shoppingCart.items.length > 0 ?
                                                        <button
                                                            onClick={onCollectPayment}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            style={{
                                                                margin: "10px 22px 10px 0px",
                                                                height: "40px",
                                                                minWidth: "135px"
                                                            }}
                                                        >
                                                            <img src={dollarSighDarkIcon} alt="Charge" />

                                                            {appLanguage.en ? "Charge" : "Cobrar"}
                                                        </button>
                                                        : null) : null}
                                                </div>
                                            }
                                            <div style={{ margin: "20px" }}>
                                                <table cellPadding="10" cellSpacing="8">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap" }}>Subtotal: </td>
                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(shoppingCart.subtotal)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap", }}>Desc{shoppingCart.discount ? (shoppingCart.discount.type === "percentage" ? ` ${shoppingCart.discount.value}%` : "") : ""}: </td>
                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(shoppingCart.desc)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap" }}>ITBIS: </td>
                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(shoppingCart.itbis)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: "right", fontSize: "18px", fontWeight: "400", whiteSpace: "nowrap" }}>Total: </td>
                                                            <td style={{ textAlign: "right", fontSize: "18px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(shoppingCart.total)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {minimizeOptions ?
                                            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                                <div
                                                    className="product-details-page-botttons-section unselectable"
                                                    style={{
                                                        justifyContent: "space-around",
                                                        alignItems: "center",
                                                        flexWrap: "nowrap",
                                                        width: "100%"
                                                    }}
                                                >
                                                    {isSaleActive ?
                                                        <button
                                                            onClick={onClickLeavePending}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            style={{
                                                                margin: "10px 22px 10px 0px",
                                                                height: "40px",
                                                                minWidth: "135px",
                                                                whiteSpace: "nowrap",
                                                            }}
                                                        >
                                                            {appLanguage.en ? "Leave pending" : "Dejar pendiente"}
                                                        </button>
                                                        :
                                                        <button
                                                            onClick={onPrevious}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            style={{
                                                                margin: "10px 22px 10px 0px",
                                                                height: "40px",
                                                                minWidth: "135px"
                                                            }}
                                                        >
                                                            <img style={{ width: "20px", height: "20px" }} src={arrowLeftDarkIcon} alt="Go back" />
                                                            {appLanguage.en ? "Go back" : "Volver"}
                                                        </button>
                                                    }

                                                    {shoppingCart ? (shoppingCart.items.length > 0 ?
                                                        <button
                                                            onClick={onCollectPayment}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            style={{
                                                                margin: "10px 22px 10px 0px",
                                                                height: "40px",
                                                                minWidth: "135px"
                                                            }}
                                                        >
                                                            <img src={dollarSighDarkIcon} alt="Charge" />

                                                            {appLanguage.en ? "Charge" : "Cobrar"}
                                                        </button>
                                                        : null) : null}
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

const ComprobantePicker = ({
    comprobantePickerActive,
    pickerWidth,
    comprobantePickerRef,
    setComprobantePickerActive,
    shoppingCart,
    showSave,
    setShowSave,
    isSaleActive,
    saleId,
    setResults,
}) => {
    const { appTheme, appLanguage, isComprobanteUsable, getComprobanteEmoji } = useContext(AppContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);
    const { comprobantesSummary, setComporbanteInShoppingCart, saveKeyInIndexedDB, setShoppingCart, accountData } = useContext(AuthContext);

    const [tempComprobante, setTempComprobante] = useState(null);

    const [loading, setLoading] = useState(false);

    const [typeAlert, setTypeAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [nameAlert, setNameAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [rncAlert, setRncAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [addressAlert, setAddressAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const resetTempComprobante = () => {
        if (shoppingCart && shoppingCart.comprobante) {
            setTempComprobante({
                tipo: shoppingCart.comprobante.tipo,
                nombre: shoppingCart.comprobante.cliente.nombre,
                rnc: shoppingCart.comprobante.cliente.rnc,
                direccion: shoppingCart.comprobante.cliente.direccion
            });

            setTypeAlert({
                alert: false,
                enText: '',
                esText: ''
            });
    
            setNameAlert({
                alert: false,
                enText: '',
                esText: ''
            });
    
            setRncAlert({
                alert: false,
                enText: '',
                esText: ''
            });
    
            setAddressAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }
    }

    useEffect(() => {
        resetTempComprobante();
    // eslint-disable-next-line
    }, [shoppingCart]);

    const onClickClose = () => {
        setComprobantePickerActive(false);
        resetTempComprobante();
    }

    const onChangeComprobanteTipo = (e) => {
        const text = e.target.value;

        setTypeAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setNameAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setRncAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setAddressAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        if (text === "01" && tempComprobante.nombre === "Consumidor Final") {
            setTempComprobante({
                ...tempComprobante,
                tipo: text,
                nombre: "",
            });
        } else {
            setTempComprobante({
                ...tempComprobante,
                tipo: text,
                nombre: tempComprobante.nombre.trim() === "" ? shoppingCart.comprobante.cliente.nombre : tempComprobante.nombre,
            });
        }
    }

    const onChangeClienteNombre = (e) => {
        const text = e.target.value;

        setNameAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setTempComprobante({
            ...tempComprobante,
            nombre: text,
        });
    }

    const onChangeClienteRNC = (e) => {
        setRncAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        let newRNC = "";

        const targetValue = e.target.value;

        for (let i = 0; i < targetValue.length; i++) {
            if ((targetValue[i] !== " ") && Number.isInteger(Number(targetValue[i]))) {
                newRNC += targetValue[i];
            }
        }

        setTempComprobante({
            ...tempComprobante,
            rnc: newRNC,
        });
    }

    const onChangeClienteDireccion = (e) => {
        const text = e.target.value;

        setAddressAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setTempComprobante({
            ...tempComprobante,
            direccion: text,
        });
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const invalidRNC = (text) => {
        if (text.length === 9 || text.length === 10 || text.length === 11) {
            return false;
        } else {
            return true;
        }
    }

    const getRNCError = (rnc) => {
        if (rnc.trim() !== "") {
            if (invalidRNC(rnc)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const isComprobanteAvailable = (tipo) => {
        if (tipo === "00") {
            return true;
        } else {
            if (comprobantesSummary) {
                for (const key in comprobantesSummary) {
                    if (comprobantesSummary[key].id === tipo) {
                        if (comprobantesSummary[key].quantity > 0) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
                return false;
            } else {
                return false;
            }
        }
    }

    const onClickSave = () => {
        if (!tempComprobante) {
            return;
        }

        if (tempComprobante.tipo === "01") {
            if (tempComprobante.nombre.trim() === "") {
                setNameAlert({
                    alert: true,
                    enText: '* Required field',
                    esText: '* Campo requerido'
                });
                return;
            }

            if (getRNCError(tempComprobante.rnc)) {
                setRncAlert({
                    alert: true,
                    enText: '* Cédula or RNC is not valid',
                    esText: '* La cédula o RNC no es válido'
                });
                return;
            }

            if (tempComprobante.direccion.trim() === "") {
                setAddressAlert({
                    alert: true,
                    enText: '* Required field',
                    esText: '* Campo requerido'
                });
                return;
            }
        }

        if (!(isComprobanteAvailable(tempComprobante.tipo))) {
            // Validate the are NCFs available
            setTypeAlert({
                alert: true,
                enText: '* Not NCFs available',
                esText: '* No hay NCFs disponibles'
            });
            return;
        }

        if (isSaleActive) {
            changeComprobanteWhenSalePending();
        } else {
            setComporbanteInShoppingCart(tempComprobante);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Added to cart.',
                    es: 'Añadido al carrito de compra.',
                }
            });

            onClickClose();
        }
    }

    const changeComprobanteWhenSalePending = async () => {
        setLoading(true);
        setAlertActive(false);

        try {
            const res = await firebase.useChangeComprobanteWhenSalePending({
                "businessId": accountData.id,
                "saleId": saleId,
                "comprobante": tempComprobante,
            });

            const newShoppingCart = {
                ...shoppingCart,
                "comprobante": res.sale.comprobante,
            }

            setResults({
                "sale": res.sale,
                "shoppingCart": newShoppingCart
            });

            setShoppingCart(newShoppingCart);
            saveKeyInIndexedDB("shoppingCart", newShoppingCart);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Comprobante has been modified.',
                    es: 'Comprobante ha sido modificada.',
                }
            });

            onClickClose();
            setLoading(false);
        } catch (error) {
            const code = "error";
            setAlertData({
                type: 'error',
                title: {
                    en: `Error when trying to modify comprobante`,
                    es: `Error al intentar modificar comprobante`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (tempComprobante && shoppingCart && shoppingCart.comprobante) {
            let areSettingsEqual = null;
            if (tempComprobante.tipo === "01") {
                areSettingsEqual =
                    tempComprobante.tipo === shoppingCart.comprobante.tipo &&
                    tempComprobante.nombre === shoppingCart.comprobante.cliente.nombre &&
                    tempComprobante.rnc === shoppingCart.comprobante.cliente.rnc &&
                    tempComprobante.direccion === shoppingCart.comprobante.cliente.direccion
            } else {
                areSettingsEqual =
                    tempComprobante.tipo === shoppingCart.comprobante.tipo &&
                    tempComprobante.nombre === shoppingCart.comprobante.cliente.nombre
            }
            setShowSave(!areSettingsEqual);
        } else {
            setShowSave(false);
        }
        // eslint-disable-next-line
    }, [tempComprobante, shoppingCart]);

    return (
        <div
            style={{
                WebkitUserSelect: "auto",
                WebkitTouchCallout: "auto",
                MozUserSelect: "auto",
                MsUserSelect: "auto",
                userSelect: "auto",
            }}
        >
            {comprobantePickerActive ? <div className='picker-date-lock-screen' style={{ width: pickerWidth, minHeight: "calc(100vh - 65px)" }} /> : null}

            <div ref={comprobantePickerRef} className={`picker-date-genaral-card ${comprobantePickerActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pickerWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                <div className='picker-date-general-despcrition' style={{ display: "flex", justifyContent: "space-between", minHeight: "250px" }}>
                    {loading ?
                        <div style={{ marginTop: "40px" }}><Spinner /></div>
                    :
                        <Fragment>
                            {shoppingCart && tempComprobante ? <Fragment>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                                    <h4 style={{ marginLeft: "15px" }}>
                                        <span style={{ fontWeight: "600" }}>{appLanguage.en ? "Comprobante details" : "Detalles del comprobante"}</span>
                                    </h4>

                                    <button
                                        onClick={onClickClose}
                                        className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                    >
                                        <img
                                            className="see-details-transations-img"
                                            src={appTheme.dark ? closeDarkIcon : closeLightIcon}
                                            alt="close"
                                        />
                                    </button>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 40px)",
                                        padding: "10px 20px",
                                        flexDirection: "column"
                                    }}
                                >
                                    <div>
                                        <p>{"Tipo de comprobante:"}</p>
                                        <select
                                            className={`cash-in-cash-reg-input ${typeAlert.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                            style={{
                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                color: appTheme.dark ? "white" : "black",
                                                width: "100%",
                                                padding: "8px",
                                                fontSize: "16px",
                                            }}
                                            name="paymentMethod"
                                            id="paymentMethod"
                                            value={tempComprobante.tipo}
                                            onChange={(e) => onChangeComprobanteTipo(e)}
                                        >
                                            {isComprobanteUsable("00", comprobantesSummary) ? <option value="00">{"Informal"}</option> : null}
                                            {isComprobanteUsable("01", comprobantesSummary) ? <option value="01">Factura de Crédito Fiscal (01) {getComprobanteEmoji("01", comprobantesSummary)}</option> : null}
                                            {isComprobanteUsable("02", comprobantesSummary) ? <option value="02">Factura de Consumo (02) {getComprobanteEmoji("02", comprobantesSummary)}</option> : null}
                                        </select>

                                        <p style={{ marginTop: "25px" }}>{"Nombre o Razón Social del Cliente"}:</p>
                                        <input
                                            value={tempComprobante.nombre}
                                            onChange={(e) => onChangeClienteNombre(e)}
                                            onKeyDown={e => handleKeyPress(e)}
                                            inputMode="text"
                                            maxLength={40}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            placeholder={appLanguage.en ? "Enter the name" : "Escribe el nombre"}
                                            style={{
                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                color: appTheme.dark ? "white" : "black",
                                                padding: "8px",
                                                fontSize: "16px",
                                            }}
                                            className={`cash-in-cash-reg-input ${nameAlert.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                        />

                                        {tempComprobante.tipo === "01" ? <Fragment>

                                            <p style={{ marginTop: "25px" }}>{"RNC o Cédula del Cliente"}:</p>
                                            <input
                                                value={tempComprobante.rnc}
                                                onChange={(e) => onChangeClienteRNC(e)}
                                                onKeyDown={e => handleKeyPress(e)}
                                                inputMode="text"
                                                maxLength={11}
                                                autoComplete="off"
                                                autoCorrect="off"
                                                placeholder={appLanguage.en ? "Enter the RNC" : "Escribe el RNC"}
                                                style={{
                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                    background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                    color: appTheme.dark ? "white" : "black",
                                                    padding: "8px",
                                                    fontSize: "16px",
                                                }}
                                                className={`cash-in-cash-reg-input ${rncAlert.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                            />

                                            <p style={{ marginTop: "25px" }}>{"Dirección del Cliente"}:</p>
                                            <textarea
                                                value={tempComprobante.direccion}
                                                onChange={(e) => onChangeClienteDireccion(e)}
                                                onKeyDown={e => handleKeyPress(e)}
                                                placeholder={appLanguage.en ? "Enter the address" : "Escribe la dirección"}
                                                maxLength={200}
                                                autoComplete="off"
                                                autoCorrect="off"
                                                style={{
                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                    background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                    color: appTheme.dark ? "white" : "black",
                                                    padding: "8px",
                                                    fontSize: "16px",
                                                    height: "45px",
                                                    minHeight: "45px",
                                                    resize: "none",
                                                }}
                                                className={`cash-in-cash-reg-input ${addressAlert.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                            />
                                        </Fragment> : null}
                                    </div>
                                </div>

                                <div
                                    className="drop-men-not-save-con"
                                    style={{
                                        marginTop: "20px",
                                        marginBottom: "15px"
                                    }}
                                >
                                    <button
                                        disabled={!showSave}
                                        onClick={onClickSave}
                                        className="new-customer-bottons-button-save"
                                        style={!showSave ? {
                                            backgroundColor: "#3f526698",
                                            color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                            minWidth: "150px",
                                            cursor: "not-allowed",
                                        } : {
                                            minWidth: "150px"
                                        }}
                                    >
                                        <img src={saveIcon} alt="Save" />
                                        {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                    </button>
                                </div>

                            </Fragment> : null}

                    </Fragment>}
                </div>
            </div>
        </div>
    )
}

export default SalesSummaryPage;