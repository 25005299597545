import { Fragment, useContext, useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { SideMenuContext } from "../../../context/SideMenuContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';
import ComprobantesTable from "../../../appTools/appToolsComponents/comprobantesTable/comprobantesTable";

const ComprobantesPage = () => {
    const location = useLocation();

    const { accountData, accessTo, comprobantesSummary } = useContext(AuthContext);
    const { appTheme, appLanguage } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);

    const [ comprobantes, setComprobantes ] = useState(null);

    useEffect(() => {
        if (comprobantesSummary) {
            const comprobantesTemp = Object.values(comprobantesSummary)
                .filter(item => item.id)
                .sort((a, b) => a.id.localeCompare(b.id));

            setComprobantes(comprobantesTemp);
        } else {
            setComprobantes(null);
        }
    }, [comprobantesSummary]);

    const navigate = useNavigate();

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.taxes)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    const [activeRef, setActiveRef] = useState(null);
    const [activeId, setActiveId] = useState(null);

    const setActiveReference = (inputElement) => {
        setActiveRef(inputElement);
    }

    useEffect(() => {
        if (activeRef) {
            activeRef.scrollIntoView({
                block: 'center',
                inline: 'nearest'
            });
            // setActiveId(null);
        }
    }, [activeRef]);

    const onClickLink = (comprobante) => {
        const newState = {
            "activeId": comprobante.id,
        }

        navigate(null, { replace: true, state: newState });
        navigate("/ver-comprobante", { state: { comprobante } })
    }

    useEffect(() => {
        if (location.state) {
            setActiveId(location.state.activeId);
        }
    // eslint-disable-next-line 
    }, []);

    const getComprobantesSorted = () => {
        if (comprobantes) {
            return comprobantes.sort((a, b) => b.isFavorite - a.isFavorite);
        } else {
            return null;
        }
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {"Comprobantes de facturas"} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.taxes ? <Fragment>
                                <div>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={"Comprobantes de facturas"}
                                        />
                                        : null}
                                    <div className="products-services-first-section">
                                        <div className="products-services-first-item">
                                            <div
                                                style={{
                                                    margin: "0px",
                                                    marginTop: "15px",
                                                    width: "100%",
                                                    maxWidth: "724px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <ComprobantesTable
                                                    headerHeight={(desktopView ? 70 : 30)}
                                                    loading={false}
                                                    comprobantes={getComprobantesSorted()}
                                                    onClickLink={onClickLink}
                                                    activeId={activeId}
                                                    setActiveReference={setActiveReference}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/**  */}
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    );
}

export default ComprobantesPage;