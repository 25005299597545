import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { SideMenuContext } from "../../../context/SideMenuContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import closeLightIcon from '../../../icons/cross.png';
import closeDarkIcon from '../../../icons/cross-white.png';

import arrowLeftDarkIcon from "../../../icons/arrow-left-dark.png";
import arrowLeftLightIcon from "../../../icons/arrow-left-light.png";

import awwowSmallRightDark from "../../../icons/arrow-small-right-white.png";

import settingIconDark from '../../../icons/settings-dark.png';
import addImgDark from "../../../icons/plus-mini-dark.png";
import SequencesTable from "../../../appTools/appToolsComponents/sequencesTable/sequencesTable";

import forbiddenLight from "../../../icons/forbidden-light.png";
import forbiddenDark from "../../../icons/forbidden-dark.png";

import onIcon from "../../../icons/on-button.png";
import offIcon from "../../../icons/off-button.png";

import saveIcon from "../../../icons/check-dark.png";

import emptyBox from "../../../img/empty-box.svg";
import Spinner from "../../../components/spinner/spinner";

import deleteIcon from "../../../icons/closed-dark.png";

import { AlertContext } from "../../../components/alert/alertContext";

import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import { uid } from "uid";

import firebase from "../../../firebase";
import { collection, query, onSnapshot, orderBy, getCountFromServer, where, limit, startAt, getDocsFromServer, startAfter, doc, getDoc } from "firebase/firestore";
import { InputPickerDateContext } from "../../../appTools/appToolsComponents/inputPickerDate/inputPickerDateContext";

import InputPickerDate from "../../../appTools/appToolsComponents/inputPickerDate/inputPickerDate";
import InputTypeDate2 from "../../../appTools/appToolsComponents/inputTypeDate/inputTypeDate2";

const ComprobantePage = () => {
    const location = useLocation();

    const { accountData, accessTo, comprobantesSummary } = useContext(AuthContext);
    const { appTheme, appLanguage, isTouchEnabled } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const { pickerDateActive } = useContext(InputPickerDateContext);

    const { alertActive } = useContext(AlertContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const [showConnectionError, setConnectionError] = useState(false);

    const [effectControler, setEffectControler] = useState(false);

    const [comprobante, setComprobante] = useState(null);
    const [sequences, setSequences] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingSequences, setLoadingSequences] = useState(true);

    const [activeSequence, setActiveSequence] = useState(null);

    const [docsCount, setDocsCount] = useState(null);
    const [showPageNav, setShowPageNav] = useState(false);

    const [pageNumber, setPageNumber] = useState(1);

    // Keep state in the current path
    const [results, setResults] = useState([]);
    const [controler, setControler] = useState(null);
    const [pagesHistory, setPageHistory] = useState([]);
    const [navControlers, setNavControlers] = useState(null);

    // All of this is to avoid requesting data 
    // from the backend twice
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (refresh === 1 && navControlers && controler && docsCount) {
            setRefresh(0);
            setPageNumber(navControlers.page);
            refreshSearch();
        }
        // eslint-disable-next-line
    }, [refresh]);

    const navigate = useNavigate();

    useEffect(() => {
        if (location.state && location.state.comprobante) {
            if (refresh === 0) {
                setRefresh(1)
            }

            setComprobante(location.state.comprobante);

            if (location.state.docsCount) {
                setSequences(location.state.sequences);
                setDocsCount(location.state.docsCount);
                setControler(location.state.controler);
                setPageHistory(location.state.pagesHistory);
                setShowPageNav(location.state.showPageNav);

                let page = null;

                if (location.state.navControlers) {
                    setNavControlers(location.state.navControlers);
                    setPageNumber(location.state.navControlers.page);
                    page = location.state.navControlers.page;
                }

                if (location.state.controler) {
                    if (
                        sequences &&
                        page === 1
                    ) {
                        setResults(sequences);
                    } else {
                        const res = location.state.results;
                        setResults(res);
                    }
                } else {
                    setResults(sequences);
                    setShowPageNav(false);
                    setPageNumber(1);
                }
            }
        }
    // eslint-disable-next-line 
    }, []);

    const blink = () => {
        setLoadingSequences(true);
        setTimeout(() => {
            setLoadingSequences(false);
        }, 100);
    }

    useEffect(() => {
        if (sequences && comprobante && docsCount) {
            if (
                sequences &&
                pageNumber === 1
            ) {
                setResults(sequences);

                setNavControlers({
                    "page": 1,
                    "totalPage": Math.ceil(docsCount / 30),
                    "isPrevious": false,
                    "isNext": Math.ceil(docsCount / 30) > 1 ? true : false
                });
            }
        }

        if (docsCount && comprobante) {
            if (docsCount > 0) {
                // setEmpty(false);
                if (docsCount > 30) {
                    setShowPageNav(true);
                } else {
                    setShowPageNav(false);
                }
            } else {
                // setEmpty(true);
            }
        }
    // eslint-disable-next-line
    }, [sequences, docsCount, comprobante]);

    async function getComprobantesCounter(q) {
        try {
            const snapshot = await getCountFromServer(q);
            setDocsCount(snapshot.data().count);
            setLoadingSequences(false);
        } catch (error) {
            console.warn(error);
            setLoadingSequences(false);
        }
    }

    async function verifiedIfComprobantesCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);

            if (!(snapshot.data().count > 0)) {
                setSequences([]);
                setDocsCount(0);
                setConnectionError(false);
                setLoadingSequences(false);
            }
        } catch (error) {
            console.warn(error);
            setConnectionError(true);
            setLoadingSequences(false);
        }
    }

    useEffect(() => {
        if (effectControler) {
            const q = query(
                collection(firebase.db, `accounts/${accountData.id}/comprobantes`),
                where("comprobanteId", "==", comprobante.id),
                orderBy("time", "desc"),
                limit(30)
            );

            const unsub = onSnapshot(q, (querySnapshot) => {
                const res = [];

                querySnapshot.forEach((doc) => {
                    if (doc.data()) {
                        res.push({
                            ...doc.data(),
                            "id": doc.id,
                        })
                    }
                });

                if (querySnapshot.empty) {
                    verifiedIfComprobantesCollectionIsReallyEmpty(q)
                } else {
                    setSequences(res);

                    const q2 = query(
                        collection(firebase.db, `accounts/${accountData.id}/comprobantes`),
                        where("comprobanteId", "==", comprobante.id),
                        orderBy("time", "desc"),
                    );

                    getComprobantesCounter(q2);

                    if (activeSequence) {
                        const newActiveSecuence = res.find((sequence) => sequence.id === activeSequence.id);
                        if (newActiveSecuence) {
                            setActiveSequence(newActiveSecuence);
                        }
                    }
                }
            }, err => {
                console.error(err);
                setConnectionError(true);
                setLoadingSequences(false);
            });

            return () => unsub();
        }
        // eslint-disable-next-line
    }, [effectControler]);

    const getPageDetailsByNumber = (n) => {
        if (pagesHistory) {
            for (let i = 0; i < pagesHistory.length; i++) {
                if (pagesHistory[i]["page"] === n) {
                    return pagesHistory[i];
                }
            }
        }
        return null;
    }

    // To get the accounts use the pageHistory to call the funcition to get it.
    const updateOrPushHistory = (updatedObject) => {
        const pagesHistoryCopy = pagesHistory ? [...pagesHistory] : [];
        const page = updatedObject.page;
        const index = pagesHistoryCopy.findIndex(obj => obj["page"] === page);

        if (index !== -1) {
            // If the object exists, update it
            pagesHistoryCopy[index] = { ...pagesHistoryCopy[index], ...updatedObject };
            setPageHistory(pagesHistoryCopy);
        } else {
            // If the object doesn't exist, push a new object to the array
            pagesHistoryCopy.push(updatedObject);
            setPageHistory(pagesHistoryCopy);
        }
    }

    const onResetAll = (sequences) => {
        blink();

        setPageHistory([]);
        setResults(sequences);

        setShowPageNav(Math.ceil(docsCount / 30) > 1 ? true : false);

        setNavControlers({
            "page": 1,
            "totalPage": Math.ceil(docsCount / 30),
            "isPrevious": false,
            "isNext": Math.ceil(docsCount / 30) > 1 ? true : false,
        });
        setPageNumber(1);
    }

    async function goToPagePrevious(q, pageN) {
        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            updateOrPushHistory({
                "page": pageN,
                "startAt": res[0].time,
                "startAtDocId": res[0].id,
                "endAt": res[res.length - 1].time,
                "endAtDocId": res[res.length - 1].id,
            });
            setResults(res);
        }
    }

    const refreshSearch = async () => {
        if (
            sequences &&
            pageNumber === 1
        ) {
            setResults(sequences);
            setNavControlers({
                "page": 1,
                "totalPage": Math.ceil(docsCount / 30),
                "isPrevious": false,
                "isNext": Math.ceil(docsCount / 30) > 1 ? true : false
            });
        } else {
            let startAtText = null;

            const lastPageNumber = navControlers.page;

            setLoadingSequences(true);

            try {

                const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

                let docSnap = null;

                if (!lastPageDetails) {
                    console.warn("ERROR********************");
                } else {
                    startAtText = lastPageDetails.startAt;
                    const productRef = collection(firebase.db, `accounts/${accountData.id}/comprobantes`);
                    docSnap = await getDoc(doc(productRef, lastPageDetails.startAtDocId));
                }

                let lastQuery = null;

                lastQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/comprobantes`),
                    where("comprobanteId", "==", comprobante.id),
                    orderBy("time", "desc"),
                    startAt(docSnap ? docSnap : startAtText),
                    limit(30)
                );
                
                await goToPagePrevious(lastQuery, navControlers.page);

                setLoadingSequences(false);
            } catch (error) {
                console.error(error);
                setLoadingSequences(false);
            }
        }
    }

    const onClickPrevious = async e => {
        e.preventDefault();

        if (navControlers.isPrevious === false) {
            return;
        }

        let startAtText = null;

        const lastPageNumber = navControlers.page - 1;

        if (lastPageNumber === 1) {
            onResetAll(sequences);
            return
        }

        setLoadingSequences(true);

        try {
            const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

            let docSnap = null;

            if (!lastPageDetails) {
                console.warn("ERROR********************");
            } else {
                startAtText = lastPageDetails.startAt;
                const productRef = collection(firebase.db, `accounts/${accountData.id}/comprobantes`);
                docSnap = await getDoc(doc(productRef, lastPageDetails.startAtDocId));
            }

            let lastQuery = query(
                collection(firebase.db, `accounts/${accountData.id}/comprobantes`),
                where("comprobanteId", "==", comprobante.id),
                orderBy("time", "desc"),
                startAt(docSnap ? docSnap : startAtText),
                limit(30)
            );

            await goToPagePrevious(lastQuery, navControlers.page - 1);

            const newPageNumber = navControlers.page - 1;
            setPageNumber(newPageNumber);

            setNavControlers({
                "page": newPageNumber,
                "totalPage": Math.ceil(docsCount / 30),
                "isPrevious": newPageNumber === 1 ? false : true,
                "isNext": Math.ceil(docsCount / 30) > newPageNumber ? true : false
            });
            
        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }

        setLoading(false);
    }

    async function goToNextPage(q) {

        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            if (pagesHistory) {
                if (!(pagesHistory.length > 0)) {
                    let newHistory = [
                        {
                            "page": 1,
                            "startAt": results[0].time,
                            "startAtDocId": results[0].id,
                            "endAt": results[results.length - 1].time,
                            "endAtDocId": results[results.length - 1].id,
                        },
                        {
                            "page": navControlers.page + 1,
                            "startAt": res[0].time,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].time,
                            "endAtDocId": res[res.length - 1].id,
                        }
                    ];
                    setPageHistory(newHistory);
                } else {
                    updateOrPushHistory({
                        "page": navControlers.page + 1,
                        "startAt": res[0].time,
                        "startAtDocId": res[0].id,
                        "endAt": res[res.length - 1].time,
                        "endAtDocId": res[res.length - 1].id,
                    });
                }
            } else {
                updateOrPushHistory({
                    "page": navControlers.page + 1,
                    "startAt": res[0].time,
                    "startAtDocId": res[0].id,
                    "endAt": res[res.length - 1].time,
                    "endAtDocId": res[res.length - 1].id,
                });
            }
            setResults(res);
        }
    }

    const onClickNext = async e => {
        e.preventDefault();

        if (navControlers.isNext === false) {
            return;
        }

        let startAfterOrAfterText = null;

        const nextPageNumber = navControlers.page + 1;
        const nextPageDetails = getPageDetailsByNumber(nextPageNumber);

        let endAtDocId = null;

        if (!nextPageDetails) {
            const lastProduct = results[results.length - 1];
            endAtDocId = lastProduct.id;
            startAfterOrAfterText = lastProduct.time;
        } else {
            const previousPage = getPageDetailsByNumber(nextPageNumber - 1);
            if (previousPage) {
                endAtDocId = previousPage.endAtDocId;
            }
            startAfterOrAfterText = nextPageDetails.startAt;
        }

        let nextQuery = null;
        let docSnap = null;

        try {
            setLoadingSequences(true);

            if (endAtDocId) {
                const productRef = collection(firebase.db, `accounts/${accountData.id}/comprobantes`);
                docSnap = await getDoc(doc(productRef, endAtDocId));
            }

            nextQuery = query(
                collection(firebase.db, `accounts/${accountData.id}/comprobantes`),
                where("comprobanteId", "==", comprobante.id),
                orderBy("time", "desc"),
                endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                limit(30)
            );

            await goToNextPage(nextQuery);

            const newPageNumber = navControlers.page + 1;
            setPageNumber(newPageNumber);

            setNavControlers({
                "page": newPageNumber,
                "totalPage": Math.ceil(docsCount / 30),
                "isPrevious": newPageNumber === 1 ? false : true,
                "isNext": Math.ceil(docsCount / 30) > newPageNumber ? true : false
            });
        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }
        setLoadingSequences(false);
    }

    const onTryAgain = () => {
        setEffectControler(uid(6));
        setLoadingSequences(true);
    }

    function getKeyById(id) {
        for (const key in comprobantesSummary) {
            if (comprobantesSummary[key].id === id) {
                return key;
            }
        }
        return null;
    }

    useEffect(() => {
        if (comprobantesSummary && comprobante) {
            const keyName = getKeyById(comprobante.id);
            if (keyName) {
                setComprobante(comprobantesSummary[keyName]);
            }
        }

        if (comprobante) {
            setEffectControler(true);
        }
        // eslint-disable-next-line
    }, [comprobantesSummary, comprobante]);

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.taxes)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    const getComprobanteTitle = () => {
        if (comprobante) {
            return `${comprobante.name} (${comprobante.id})`;
        } else {
            return "";
        }
    }

    let pageObserverRef = useRef(null);
    let headerObservedRef = useRef(null);

    const [pageWidth, setPageWidth] = useState(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return windowDimension.width;
            } else {
                try {
                    const width = pageObserverRef.current.offsetWidth
                    return width;
                } catch {
                    return windowDimension.width;
                }
            }
        } else {
            return windowDimension.width;
        }
    });

    const [headerHeight, setHeaderHeight] = useState(134);

    useEffect(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (pageObserverRef.current.offsetWidth !== pageWidth) {
                        setPageWidth(pageObserverRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(pageObserverRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [pageObserverRef.current, windowDimension, isSideMenuMinimized]);

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, isSideMenuMinimized]);

    const getStatusText = (status, isActive) => {
        if (status === "notSupported") {
            return appLanguage.en ? "Not Supported" : "No Soportado"
        } else {
            if (isActive) {
                return appLanguage.en ? "Active" : "Activo"
            } else {
                return appLanguage.en ? "Inactive" : "Inactivo"
            }
        }
    }

    const getStatusBackgroundColor = (status, isActive) => {
        if (status === "notSupported") {
            return "rgba(128, 128, 128, 0.151)";
        } else {
            if (isActive) {
                return appTheme.dark ? "rgba(0, 128, 0, 0.700)" : "rgba(0, 253, 0, 0.35)";
            } else {
                return "rgba(128, 128, 128, 0.151)";
            }
        }
    }

    const isFavorite = (comprobante) => {
        if (comprobante.status === "notSupported" || (!comprobante.isActive) || !comprobante.isFavorite) {
            return false;
        } else {
            return true;
        }
    }

    const isAlerts = (comprobante) => {
        if (comprobante.status === "notSupported" || (!comprobante.isActive) || !comprobante.isAlerts) {
            return false;
        } else {
            return true;
        }
    }

    const onClickLink = (sequence) => {
        onClickNumeracion(sequence);
    }

    let settingsPickerRef = useRef(null);
    let nuevaNumeracionPickerRef = useRef(null);
    let numeracionPickerRef = useRef(null);

    const [settingsPickerActive, setSettingsPickerActive] = useState(false);
    const [nuevaNumeracionPickerActive, setNuevaNumeracionPickerActive] = useState(false);
    const [numeracionPickerActive, setNumeracionPickerActive] = useState(false);

    // To control new NFCs
    const [isSecondSectionSelected, setIsSecondSectionSelected] = useState(false);

    useEffect(() => {
        if (!nuevaNumeracionPickerActive) {
            setIsSecondSectionSelected(false);
        }
    }, [nuevaNumeracionPickerActive]);

    useEffect(() => {
        if (settingsPickerActive) {
            let handler = (e) => {
                if (!settingsPickerRef.current.contains(e.target)) {
                    if ((!alertActive) && (!loading)) {
                        closeAllPickers();
                    }
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    useEffect(() => {
        if (nuevaNumeracionPickerActive) {
            let handler = (e) => {
                if (!nuevaNumeracionPickerRef.current.contains(e.target)) {
                    if ((!alertActive) && (!loading) && (!pickerDateActive)) {
                        closeAllPickers();
                    }
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    useEffect(() => {
        if (numeracionPickerActive) {
            let handler = (e) => {
                if (!numeracionPickerRef.current.contains(e.target)) {
                    if ((!alertActive) && (!loading)) {
                        closeAllPickers();
                    }
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickSettings = () => {
        setSettingsPickerActive(true);
        setNuevaNumeracionPickerActive(false);
        setNumeracionPickerActive(false);
    }

    const onClickNuevaNumeracion = () => {
        setSettingsPickerActive(false);
        setNuevaNumeracionPickerActive(true);
        setNumeracionPickerActive(false);
    }

    const onClickNumeracion = (sequence) => {
        setSettingsPickerActive(false);
        setNuevaNumeracionPickerActive(false);

        setNumeracionPickerActive(true);
        setActiveSequence(sequence);
    }

    const closeAllPickers = () => {
        setSettingsPickerActive(false);
        setNuevaNumeracionPickerActive(false);
        setNumeracionPickerActive(false);
        setActiveSequence(null);
    }

    const onClickCloseSettingsPicker = () => {
        closeAllPickers();
    }

    const onClickCloseNuevaNumeracionPicker = () => {
        closeAllPickers();
    }

    const onClickCloseNumeracionPicker = () => {
        closeAllPickers();
    }

    useEffect(() => {
        const newState = {
            "comprobante": comprobante,
            "sequences": sequences,
            "results": results,
            "docsCount": docsCount,
            "controler": controler,
            "pagesHistory": pagesHistory,
            "navControlers": navControlers,
            "showPageNav": showPageNav,
        }

        navigate(null, { replace: true, state: newState });

    // eslint-disable-next-line
    }, [results, controler, pagesHistory, navControlers, showPageNav, docsCount, comprobante, sequences]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {getComprobanteTitle()} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef} className={`page-choose-account-item`}>
                            {accessTo.taxes ? <Fragment>



                                <SettingsPicker
                                    settingsPickerActive={settingsPickerActive}
                                    pageWidth={pageWidth}
                                    settingsPickerRef={settingsPickerRef}
                                    loading={loading}
                                    setLoading={setLoading}
                                    onClickClose={onClickCloseSettingsPicker}
                                    getComprobanteTitle={getComprobanteTitle}
                                    comprobante={comprobante}
                                    setComprobante={setComprobante}
                                    getKeyById={getKeyById}
                                    closeAllPickers={closeAllPickers}
                                />

                                <NuevaNumeracionPicker
                                    nuevaNumeracionPickerActive={nuevaNumeracionPickerActive}
                                    pageWidth={pageWidth}
                                    nuevaNumeracionPickerRef={nuevaNumeracionPickerRef}
                                    loading={loading}
                                    setLoading={setLoading}
                                    onClickClose={onClickCloseNuevaNumeracionPicker}
                                    getComprobanteTitle={getComprobanteTitle}
                                    comprobante={comprobante}
                                    comprobanteId={comprobante ? comprobante.id : "00"}
                                    isSecondSectionSelected={isSecondSectionSelected}
                                    setIsSecondSectionSelected={setIsSecondSectionSelected}
                                    getKeyById={getKeyById}
                                    closeAllPickers={closeAllPickers}
                                    setSequences={setSequences}
                                    onResetAll={onResetAll}
                                />

                                <NumeracionPicker
                                    numeracionPickerActive={numeracionPickerActive}
                                    pageWidth={pageWidth}
                                    numeracionPickerRef={numeracionPickerRef}
                                    loading={loading}
                                    setLoading={setLoading}
                                    comprobante={comprobante}
                                    comprobanteId={comprobante ? comprobante.id : "00"}
                                    setSequences={setSequences}
                                    onClickClose={onClickCloseNumeracionPicker}
                                    activeSequence={activeSequence}
                                    getKeyById={getKeyById}
                                    closeAllPickers={closeAllPickers}
                                    onResetAll={onResetAll}
                                />

                                <InputPickerDate pickerWidth={pageWidth} />

                                <div>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={getComprobanteTitle()}
                                        />
                                        : null}

                                    <div style={{ marginTop: "15px", display: "flex", width: "100%", justifyContent: "center" }}>
                                        <div style={{ width: "100%", maxWidth: "747px" }}>
                                            {comprobante ? <Fragment>
                                                <div style={{ margin: "0px 0px 15px 20px" }}>
                                                    {comprobante.status === "notSupported" ? null :
                                                        <div style={{ display: "flex" }}>
                                                            <button
                                                                onClick={onClickSettings}
                                                                className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                style={{ margin: "10px 22px 10px 0px" }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        marginRight: pageWidth > 540 ? "10px" : "10px",
                                                                    }}
                                                                    src={settingIconDark}
                                                                    alt="Settings"
                                                                />
                                                                {appLanguage.en ? <p>Settings</p> : <p>Ajustes</p>}
                                                            </button>

                                                            <button
                                                                onClick={onClickNuevaNumeracion}
                                                                className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                style={{ margin: "10px 22px 10px 0px" }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        marginRight: pageWidth > 540 ? "10px" : "10px",
                                                                    }}
                                                                    src={addImgDark}
                                                                    alt=""
                                                                />
                                                                <p style={{ whiteSpace: "nowrap" }}> {appLanguage.en ? "New sequence" : "Nueva secuencia"}</p>
                                                            </button>
                                                        </div>
                                                    }
                                                </div>

                                                <div
                                                    ref={headerObservedRef}
                                                    className={`customer-view-main-header-main-background ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                                    style={{
                                                        minHeight: "100px",
                                                        marginTop: "0px",
                                                        marginBottom: "25px",
                                                        flexDirection: "column"
                                                    }}
                                                >

                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", minHeight: "58.4px" }}>
                                                        <p style={{ fontWeight: "500", padding: "10px", marginRight: "5px" }} >{getComprobanteTitle()}</p>
                                                    </div>

                                                    {/** Cuerpo */}
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            marginBottom: "5px"
                                                        }}
                                                    >

                                                        <p style={{ padding: "5px 15px", whiteSpace: "nowrap" }}>
                                                            <span className="customer-detaines-info-out-of-stock-span-cont" style={{ fontSize: "16px", color: appTheme.dark ? "white" : "black", background: getStatusBackgroundColor(comprobante.status, comprobante.isActive), fontWeight: "600" }}>{getStatusText(comprobante.status, comprobante.isActive)}</span>
                                                        </p>

                                                        <p style={{ padding: "5px 15px", whiteSpace: "nowrap" }}>{comprobante.quantity} {appLanguage.en ? "Available" : "Disponibles"}</p>

                                                        {isFavorite(comprobante) ?
                                                            <p style={{ padding: "5px 15px", whiteSpace: "nowrap" }}>⭐ {appLanguage.en ? "Favorite" : "Favorito"}</p>
                                                            : null}

                                                        {isAlerts(comprobante) ?
                                                            <p style={{ padding: "5px 15px", whiteSpace: "nowrap" }}>🔔 {appLanguage.en ? "Alerts active " : "Alertas activas"}</p>
                                                            : null}
                                                    </div>
                                                </div>

                                                {comprobante.status === "notSupported" ?
                                                    <div
                                                        className={`inventory-table-container unselectable ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                                        style={{
                                                            maxHeight: `calc(100vh - ${headerHeight + (desktopView ? 150 : 110) + 90}px)`,
                                                            height: `calc(100vh - ${headerHeight + (desktopView ? 150 : 110) + 90}px)`,
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                            borderRadius: "10px",
                                                            margin: "0px 15px 25px 25px"
                                                        }}
                                                    >
                                                        <div className="section-forbidden-container">
                                                            <div className="section-forbidden-page-name">
                                                                <img className="section-forbidden-img" src={appTheme.dark ? forbiddenDark : forbiddenLight} alt="Forbidden" />
                                                                <p className="section-forbidden-title">No Soportado</p>
                                                            </div>
                                                            <p style={{ textAlign: "center" }} className="section-forbidden-text">Este comprobante fiscal actualmente no es compatible con esta aplicación, estamos trabajando para integrarlo lo antes posible.</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    (loadingSequences || showConnectionError || (Array.isArray(sequences) && sequences.length > 0) ?
                                                        <SequencesTable
                                                            headerHeight={headerHeight + (desktopView ? 150 : 110)}
                                                            loading={loadingSequences}
                                                            results={results}
                                                            onClickLink={onClickLink}
                                                            pageNumber={pageNumber}
                                                            navControlers={navControlers}
                                                            showPageNav={showPageNav}
                                                            onClickNext={onClickNext}
                                                            onClickPrevious={onClickPrevious}
                                                            showConnectionError={showConnectionError}
                                                            onTryAgain={onTryAgain}
                                                        />
                                                        :
                                                        <div
                                                            className={`inventory-table-container unselectable ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                                            style={{
                                                                maxHeight: `calc(100vh - ${headerHeight + (desktopView ? 150 : 110) + 90}px)`,
                                                                height: `calc(100vh - ${headerHeight + (desktopView ? 150 : 110) + 90}px)`,
                                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                                borderRadius: "10px",
                                                                margin: "0px 15px 25px 25px"
                                                            }}
                                                        >
                                                            <NoSequencesYet />
                                                        </div>
                                                    )
                                                }

                                            </Fragment> : null}
                                        </div>
                                    </div>
                                </div>
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    );
}

const SettingsPicker = ({
    settingsPickerActive,
    pageWidth,
    settingsPickerRef,
    loading,
    setLoading,
    onClickClose,
    getComprobanteTitle,
    comprobante,
    setComprobante,
    getKeyById,
    closeAllPickers
}) => {
    const { appTheme, appLanguage, isTouchEnabled } = useContext(AppContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);
    const { accountData, saveKeyInIndexedDB, setComprobantesSummary } = useContext(AuthContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const [tempSettings, setTempSettings] = useState(comprobante ? {
        "isActive": comprobante.isActive,
        "isFavorite": comprobante.isFavorite,
        "isAlerts": comprobante.isAlerts,
        "alertQuantity": comprobante.alertQuantity,
    } : null);

    const [showSave, setShowSave] = useState(false);

    const [quantityAlert, setQuantityAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    useEffect(() => {
        if (comprobante) {
            setTempSettings({
                "isActive": comprobante.isActive,
                "isFavorite": comprobante.isFavorite,
                "isAlerts": comprobante.isAlerts,
                "alertQuantity": comprobante.alertQuantity,
            });
        }
    }, [comprobante]);

    useEffect(() => {
        if (settingsPickerActive && comprobante) {
            setTempSettings({
                "isActive": comprobante.isActive,
                "isFavorite": comprobante.isFavorite,
                "isAlerts": comprobante.isAlerts,
                "alertQuantity": comprobante.alertQuantity,
            });
        } else {
            setQuantityAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }
        // eslint-disable-next-line
    }, [settingsPickerActive])

    useEffect(() => {
        if (tempSettings && comprobante) {
            const areSettingsEqual =
                tempSettings.isActive === comprobante.isActive &&
                tempSettings.isFavorite === comprobante.isFavorite &&
                tempSettings.isAlerts === comprobante.isAlerts &&
                (!tempSettings.isAlerts || tempSettings.alertQuantity[0] === comprobante.alertQuantity[0]);

            setShowSave(!areSettingsEqual);
        }
    }, [tempSettings, comprobante]);

    const onClickOpcion = (option) => {
        if (option === "isActive") {
            if (tempSettings[option]) {
                setTempSettings({
                    ...tempSettings,
                    "isAlerts": false,
                    "isFavorite": false,
                    [option]: !tempSettings[option],
                });
            } else {
                setTempSettings({
                    ...tempSettings,
                    [option]: !tempSettings[option],
                });
            }
        } else {
            if (tempSettings.isActive) {
                setTempSettings({
                    ...tempSettings,
                    [option]: !tempSettings[option],
                });
            }
        }
    }

    const onChangeAlertQuantity = (e) => {
        const text = e.target.value;
        let amount = "";
        for (let i = 0; i < text.length; i++) {
            if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                amount += text[i];
            }
        }

        if (amount !== "") {
            amount = Number(amount);
        }

        setQuantityAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        setTempSettings({
            ...tempSettings,
            "alertQuantity": [amount],
        });
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            // onSaveProcess();
        }
    }

    const onSaveSettings = async () => {
        setLoading(true);
        setAlertActive(false);

        const keyName = getKeyById(comprobante.id);

        try {
            const res = await firebase.useChangeComprobantesSettings({
                businessId: accountData.id,
                keyName: keyName,
                newSettings: tempSettings,
            });

            setComprobantesSummary(res);
            saveKeyInIndexedDB("comprobantesSummary", res);
            setComprobante(res[keyName]);

            setAlertActive(false);
            setLoading(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Settings saved successfully',
                    es: 'Ajustes guardados exitosamente'
                }
            });

            closeAllPickers();

        } catch (error) {
            const code = "error";
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to save settings`,
                    es: `Error al intentar guardar los ajustes`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    const onClickSave = () => {
        if (
            tempSettings.isAlerts &&
            (typeof tempSettings.alertQuantity[0] !== 'number' || tempSettings.alertQuantity[0] <= 0)
        ) {
            setQuantityAlert({
                alert: true,
                enText: 'Required field',
                esText: 'Campo requerido'
            });
            return;
        }
        onSaveSettings();
    }

    return (
        <div
            style={{
                WebkitUserSelect: "auto",
                WebkitTouchCallout: "auto",
                MozUserSelect: "auto",
                MsUserSelect: "auto",
                userSelect: "auto",
            }}
        >
            {settingsPickerActive ? <div className='picker-date-lock-screen' style={{ width: pageWidth, minHeight: "calc(100vh - 65px)" }} /> : null}

            <div ref={settingsPickerRef} className={`picker-date-genaral-card ${settingsPickerActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pageWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                <div className='picker-date-general-despcrition' style={{ display: "flex", minHeight: "250px" }}>
                    {loading ?
                        <div style={{ marginTop: "40px" }}><Spinner /></div>
                        :
                        <Fragment>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                                <h4 style={{ marginLeft: "15px" }}>
                                    <span style={{ fontWeight: "600" }}>{appLanguage.en ? `Settings for ${getComprobanteTitle()}` : `Ajustes para ${getComprobanteTitle()}`}</span>
                                </h4>

                                <button
                                    onClick={onClickClose}
                                    className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                >
                                    <img
                                        className="see-details-transations-img"
                                        src={appTheme.dark ? closeDarkIcon : closeLightIcon}
                                        alt="close"
                                    />
                                </button>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column"
                                }}
                            >
                                {tempSettings ?
                                    <div style={{ width: "calc(100% - 40px)" }}>
                                        <button
                                            onClick={() => onClickOpcion("isActive")}
                                            className={`${isTouchEnabled() ? "drop-men-not-option-btn-no-hover" : "drop-men-not-option-btn"}`}
                                        >
                                            <img
                                                src={tempSettings.isActive ? onIcon : offIcon}
                                                style={{
                                                    width: "45px",
                                                    height: "45px"
                                                }}
                                                alt="Check"
                                            />
                                            <p style={{ fontSize: "16px", fontWeight: "500", color: appTheme.dark ? "white" : "black" }}>{appLanguage.en ? "Active" : "Activo"}</p>
                                        </button>

                                        <button
                                            onClick={() => onClickOpcion("isFavorite")}
                                            className={`${isTouchEnabled() ? "drop-men-not-option-btn-no-hover" : "drop-men-not-option-btn"}`}
                                        >
                                            <img
                                                src={tempSettings.isFavorite ? onIcon : offIcon}
                                                style={{
                                                    width: "45px",
                                                    height: "45px"
                                                }}
                                                alt="Check"
                                            />
                                            <p style={{ fontSize: "16px", fontWeight: "500", color: appTheme.dark ? "white" : "black" }}>{appLanguage.en ? "Favorite" : "Favorito"}</p>
                                        </button>

                                        <button
                                            onClick={() => onClickOpcion("isAlerts")}
                                            className={`${isTouchEnabled() ? "drop-men-not-option-btn-no-hover" : "drop-men-not-option-btn"}`}
                                        >
                                            <img
                                                src={tempSettings.isAlerts ? onIcon : offIcon}
                                                style={{
                                                    width: "45px",
                                                    height: "45px"
                                                }}
                                                alt="Check"
                                            />
                                            <p style={{ fontSize: "16px", fontWeight: "500", color: appTheme.dark ? "white" : "black" }}>{appLanguage.en ? "Availability alerts" : "Alertas de disponibilidad"}</p>
                                        </button>

                                        {tempSettings.isAlerts ?
                                            <div
                                                style={{
                                                    margin: "10px 10px 15px 10px",
                                                    width: "calc(100% - 20px)",
                                                }}
                                            >
                                                <p>{appLanguage.en ?
                                                    "Amount to send alert for availability"
                                                    :
                                                    "Cantidad para enviar alerta por disponibilidad"}:
                                                </p>
                                                <input
                                                    value={tempSettings.alertQuantity[0]}
                                                    onChange={(e) => onChangeAlertQuantity(e)}
                                                    onKeyDown={e => handleKeyPress(e)}
                                                    inputMode="number"
                                                    maxLength={6}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                    placeholder={appLanguage.en ? "Enter the amount to alert" : "Escribe la cantidad a alertar"}
                                                    style={{
                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                        background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                        color: appTheme.dark ? "white" : "black"
                                                    }}
                                                    className={`cash-in-cash-reg-input ${quantityAlert.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                                />
                                            </div>
                                            : null}

                                        <div
                                            className="drop-men-not-save-con"
                                            style={{
                                                marginTop: "35px",
                                                marginBottom: "15px"
                                            }}
                                        >
                                            <button
                                                onClick={onClickSave}
                                                disabled={!showSave}
                                                className="new-customer-bottons-button-save"
                                                style={!showSave ? {
                                                    backgroundColor: "#3f526698",
                                                    color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                    cursor: "default",
                                                    minWidth: "150px"
                                                } : {
                                                    minWidth: "150px"
                                                }}
                                            >
                                                <img src={saveIcon} alt="Save" />
                                                {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                            </button>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

const NuevaNumeracionPicker = ({
    nuevaNumeracionPickerActive,
    pageWidth,
    nuevaNumeracionPickerRef,
    loading,
    setLoading,
    onClickClose,
    getComprobanteTitle,
    comprobante,
    comprobanteId,
    isSecondSectionSelected,
    setIsSecondSectionSelected,
    getKeyById,
    closeAllPickers,
    setSequences,
    onResetAll
}) => {
    const { appTheme, appLanguage, getDateWithMonthShort } = useContext(AppContext);
    const { accountData, } = useContext(AuthContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);
    const { currentDate, setPickerDateActive, setPickerDateTitle, setCurrentDate, inputName, setInputName } = useContext(InputPickerDateContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const [dateStringFrom, setDateStringFrom] = useState("");

    const [dateObjFrom, setDateObjFrom] = useState(false);

    const [showAlertFrom, setShowAlertFrom] = useState(false);

    const getComprobanteTitleById = (type) => {
        switch (type) {
            case "01":
                return "Facturas de Crédito Fiscal (01)";
            case "02":
                return "Facturas de Consumo (02)";
            case "03":
                return "Notas de Débito (03)";
            case "04":
                return "Notas de Crédito (04)";
            case "11":
                return "Comprobantes de Compras (11)";
            case "12":
                return "Registro Único de Ingresos (12)";
            case "13":
                return "Comprobante para Gastos Menores (13)";
            case "14":
                return "Comprobante para Regímenes Especiales (14)";
            case "15":
                return "Comprobantes Gubernamentales (15)";
            case "16":
                return "Comprobantes para Exportaciones (16)";
            case "17":
                return "Comprobantes de Pagos al Exterior (17)";
            case "31":
                return "Factura de Crédito Fiscal Electrónica (31)";
            case "32":
                return "Factura de Consumo Electrónica (32)";
            case "33":
                return "Nota de Débito Electrónica (33)";
            case "34":
                return "Nota de Crédito Electrónica (34)";
            case "41":
                return "Compras Electrónico (41)";
            case "43":
                return "Gastos Menores Electrónico (43)";
            case "44":
                return "Regímenes Especiales Electrónicos (44)";
            case "45":
                return "Gubernamental Electrónico (45)";
            case "46":
                return "Exportación Electrónico (46)";
            case "47":
                return "Pagos al Exterior Electrónico (47)";
            default:
                return appLanguage.en ? "Invalid" : "Inválido";
        }
    };

    const goToFirstSection = () => {
        setIsSecondSectionSelected(false);
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    useEffect(() => {
        if (inputName && currentDate) {
            setDateStringFrom(`${currentDate.getDate().toString().padStart(2, '0')} / ${(currentDate.getMonth() + 1).toString().padStart(2, '0')} / ${currentDate.getFullYear()}`);
            setShowAlertFrom(false);
        }
        // eslint-disable-next-line
    }, [currentDate]);

    const [newSequence, setNewSequence] = useState({
        ncfFrom: "",
        ncfTo: "",
        expirationDate: "",
    });

    const [showAlertNcfFrom, setShowAlertNcfFrom] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [showAlertNcfTo, setShowAlertNcfTo] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    useEffect(() => {
        setNewSequence({
            ...newSequence,
            expirationDate: dateStringFrom
        });
        // eslint-disable-next-line
    }, [dateStringFrom])

    const onChangenNcfFrom = (e) => {
        setShowAlertNcfFrom({
            alert: false,
            enText: '',
            esText: ''
        });

        const value = e.target.value;
        let tempNcfFrom = "";

        for (let i = 0; i < value.length; i++) {
            if (i === 0) {
                if (/[A-Za-z]/.test(value[i])) {
                    tempNcfFrom += value[i].toUpperCase();
                }
            } else {
                if (/[0-9]/.test(value[i])) {
                    tempNcfFrom += value[i];
                }
            }
        }

        setNewSequence({
            ...newSequence,
            ncfFrom: tempNcfFrom
        });
    }

    const onChangeNcfTo = (e) => {
        setShowAlertNcfTo({
            alert: false,
            enText: '',
            esText: ''
        });

        const value = e.target.value;
        let tempNcfTo = "";

        for (let i = 0; i < value.length; i++) {
            if (i === 0) {
                if (/[A-Za-z]/.test(value[i])) {
                    tempNcfTo += value[i].toUpperCase();
                }
            } else {
                if (/[0-9]/.test(value[i])) {
                    tempNcfTo += value[i];
                }
            }
        }

        setNewSequence({
            ...newSequence,
            ncfTo: tempNcfTo
        });
    }

    const onClickPickFrom = () => {
        setPickerDateActive(true);
        setPickerDateTitle(appLanguage.en ? "Select the due date for the new sequence" : "Seleccione la fecha de vencimiento de la nueva secuencia");
        if (dateObjFrom) {
            setCurrentDate(dateObjFrom);
        }
        setInputName("dateFrom");
    }

    const getExpirationDate = (expirationDate) => {
        const dateRegex = /^\d{2} \/ \d{2} \/ \d{4}$/;
        if (!dateRegex.test(expirationDate)) {
            return false;
        }

        const [day, month, year] = expirationDate.split(" / ").map(Number);

        const date = new Date(year, month - 1, day);

        return getDateWithMonthShort(date);
    }

    function isValidFutureDate(inputDateString) {
        const dateRegex = /^\d{2} \/ \d{2} \/ \d{4}$/;
        if (!dateRegex.test(inputDateString)) {
            return false;
        }

        const [day, month, year] = inputDateString.split(" / ").map(Number);

        const inputDate = new Date(year, month - 1, day);
        if (
            inputDate.getFullYear() !== year ||
            inputDate.getMonth() !== month - 1 ||
            inputDate.getDate() !== day
        ) {
            return false;
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (inputDate <= today) {
            return false;
        }

        return true;
    }

    const getNcfQuantity = (strFrom, strTo) => {
        let numberFrom = "";
        for (let i = 3; i < strFrom.length; i++) {
            numberFrom += strFrom[i];
        }
        numberFrom = Number(numberFrom);

        let numberTo = "";
        for (let j = 3; j < strTo.length; j++) {
            numberTo += strTo[j];
        }
        numberTo = Number(numberTo);

        return (numberTo - numberFrom) + 1;
    }

    const onClickNext = () => {
        if (newSequence.ncfFrom.trim() === "") {
            setShowAlertNcfFrom({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;
        } else {
            if (!/^[A-Z0-9]{11}$/.test(newSequence.ncfFrom) && !/^[A-Z0-9]{13}$/.test(newSequence.ncfFrom)) {
                setShowAlertNcfFrom({
                    alert: true,
                    enText: '* Invalid format',
                    esText: '* Formato no válido'
                });
                return;
            } else {
                const newSequenceType = `${newSequence.ncfFrom[1]}${newSequence.ncfFrom[2]}`;
                if (comprobanteId !== newSequenceType) {
                    setShowAlertNcfFrom({
                        alert: true,
                        enText: '* Invalid format',
                        esText: '* Formato no válido'
                    });
                    const code = "error";
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Incorrect NCF`,
                            es: `NCF incorrecto`
                        },
                        code: code,
                        description: {
                            en: `You are trying to add a sequence from ${getComprobanteTitleById(newSequenceType)} in the session for ${getComprobanteTitle()} vouchers.`,
                            es: `Estás intentando agregar una secuencia de ${getComprobanteTitleById(newSequenceType)} en la sesión para ${getComprobanteTitle()}.`,
                        }
                    });
                    setAlertActive(true);
                    return;
                }
            }
        }

        if (newSequence.ncfTo.trim() === "") {
            setShowAlertNcfTo({
                alert: true,
                enText: '* Required field',
                esText: '* Campo requerido'
            });
            return;
        } else {
            if (!/^[A-Z0-9]{11}$/.test(newSequence.ncfTo) && !/^[A-Z0-9]{13}$/.test(newSequence.ncfTo)) {
                setShowAlertNcfTo({
                    alert: true,
                    enText: '* Invalid format',
                    esText: '* Formato no válido'
                });
                return;
            } else {
                const newSequenceType = `${newSequence.ncfTo[1]}${newSequence.ncfTo[2]}`;
                if (comprobanteId !== newSequenceType) {
                    setShowAlertNcfTo({
                        alert: true,
                        enText: '* Invalid format',
                        esText: '* Formato no válido'
                    });
                    const code = "error";
                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Incorrect NCF`,
                            es: `NCF incorrecto`
                        },
                        code: code,
                        description: {
                            en: `You are trying to add a sequence from ${getComprobanteTitleById(newSequenceType)} in the session for ${getComprobanteTitle()} vouchers.`,
                            es: `Estás intentando agregar una secuencia de ${getComprobanteTitleById(newSequenceType)} en la sesión para ${getComprobanteTitle()}.`,
                        }
                    });
                    setAlertActive(true);
                    return;
                }
            }
        }

        if (newSequence.ncfFrom[0] !== newSequence.ncfTo[0]) {
            setShowAlertNcfFrom({
                alert: true,
                enText: '* Invalid sequence',
                esText: '* Sequencia inválida'
            });
            const code = "error";
            setAlertData({
                type: 'error',
                title: {
                    en: `Invalid sequence`,
                    es: `Sequencia inválida`
                },
                code: code,
                description: {
                    en: "The sequence you are trying to add is invalid, please ensure you are entering the NCFs correctly.",
                    es: "La secuencia que estás intentando agregar no es válida, revisa que estés ingresando los NCFs de manera correcta.",
                }
            });
            setAlertActive(true);
            return;
        }

        const ncfQuantity = getNcfQuantity(newSequence.ncfFrom, newSequence.ncfTo);

        if (!(Number.isInteger(ncfQuantity) && ncfQuantity > 0 && ncfQuantity <= 99999999)) {
            setShowAlertNcfFrom({
                alert: true,
                enText: '* Invalid sequence',
                esText: '* Sequencia inválida'
            });
            const code = "error";
            setAlertData({
                type: 'error',
                title: {
                    en: `Invalid sequence`,
                    es: `Sequencia inválida`
                },
                code: code,
                description: {
                    en: "The sequence you are trying to add is invalid, please ensure you are entering the NCFs correctly.",
                    es: "La secuencia que estás intentando agregar no es válida, revisa que estés ingresando los NCFs de manera correcta.",
                }
            });
            setAlertActive(true);
            return;
        }

        if (newSequence.expirationDate.trim() === "") {
            setShowAlertFrom(true);
            return;
        } else {
            if (!isValidFutureDate(newSequence.expirationDate)) {
                setShowAlertFrom(true);
                return;
            }
        }

        setShowAlertNcfFrom({
            alert: false,
            enText: '',
            esText: ''
        });

        setShowAlertNcfTo({
            alert: false,
            enText: '',
            esText: ''
        });

        setIsSecondSectionSelected(true);
    }

    const onClickSave = async () => {
        setLoading(true);
        setAlertActive(false);

        const keyName = getKeyById(comprobante.id);

        try {
            const res = await firebase.useAddComprobanteSequence({
                "businessId": accountData.id,
                "keyName": keyName,
                "comprobanteId": comprobanteId,
                "newSequence": newSequence,
            });

            setSequences(res);

            onResetAll(res);

            setNewSequence({
                ncfFrom: "",
                ncfTo: "",
                expirationDate: "",
            });
            setDateStringFrom("");

            setAlertActive(false);
            setLoading(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Sequence added successfully',
                    es: 'Sequencia agregada exitosamente'
                }
            });

            closeAllPickers();

        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));

            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to add sequence`,
                    es: `Error al intentar agregar secuencia`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    return (
        <div
            style={{
                WebkitUserSelect: "auto",
                WebkitTouchCallout: "auto",
                MozUserSelect: "auto",
                MsUserSelect: "auto",
                userSelect: "auto",
            }}
        >
            {nuevaNumeracionPickerActive ? <div className='picker-date-lock-screen' style={{ width: pageWidth, minHeight: "calc(100vh - 65px)" }} /> : null}

            <div ref={nuevaNumeracionPickerRef} className={`picker-date-genaral-card ${nuevaNumeracionPickerActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pageWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                <div className='picker-date-general-despcrition' style={{ display: "flex", justifyContent: "space-between", minHeight: "250px" }}>
                    {loading ?
                        <div style={{ marginTop: "40px" }}><Spinner /></div>
                        :
                        <Fragment>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {isSecondSectionSelected ?
                                        <button
                                            onClick={goToFirstSection}
                                            className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                        >
                                            <img style={{ width: "20px", height: "20px" }} className="see-details-transations-img" src={appTheme.dark ? arrowLeftDarkIcon : arrowLeftLightIcon} alt="close" />
                                        </button>
                                        : null}

                                    <h4 style={{ marginLeft: "15px" }}>
                                        <span style={{ fontWeight: "600" }}> {isSecondSectionSelected ? (appLanguage.en ? "Summary" : "Resumen") : (appLanguage.en ? `New sequence for ${getComprobanteTitle()}` : `Nueva secuencia para ${getComprobanteTitle()}`)}</span>
                                    </h4>
                                </div>

                                <button
                                    onClick={onClickClose}
                                    className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                >
                                    <img
                                        className="see-details-transations-img"
                                        src={appTheme.dark ? closeDarkIcon : closeLightIcon}
                                        alt="close"
                                    />
                                </button>
                            </div>

                            {isSecondSectionSelected ?
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 40px)",
                                        padding: "10px 20px",
                                        flexDirection: "column"
                                    }}
                                >

                                    <p style={{ fontWeight: "400", padding: "5px", marginBottom: "20px" }}>
                                        {appLanguage.en ?
                                            "Verify that the information below is exactly the same as that provided by the DGII."
                                        :
                                            "Verifique que la información a continuación sea exactamente igual a la proporcionada por la DGII."
                                        }
                                    </p>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                                        <p style={{ fontWeight: "400", padding: "5px" }}>{appLanguage.en ? "Serie" : "Serie"}: <b>{newSequence.ncfFrom[0]}</b></p>
                                        <p style={{ fontWeight: "400", padding: "5px" }}>{appLanguage.en ? "Type" : "Tipo"}: <b>{newSequence.ncfFrom[1]}{newSequence.ncfFrom[2]}</b></p>
                                        <p style={{ fontWeight: "400", padding: "5px" }}>{appLanguage.en ? "Quantity" : "Cantidad"}: <b>{getNcfQuantity(newSequence.ncfFrom, newSequence.ncfTo)}</b></p>

                                    </div>

                                    <p style={{ fontWeight: "400", padding: "15px 5px" }}>{appLanguage.en ? "Expiration date" : "Fecha de vencimiento"}: <b>{getExpirationDate(newSequence.expirationDate)}</b></p>

                                    <p style={{ fontWeight: "400", padding: "5px" }}>{appLanguage.en ? "From" : "Desde"}: <b>{newSequence.ncfFrom}</b></p>
                                    <p style={{ fontWeight: "400", padding: "5px" }}>{appLanguage.en ? "To" : "Hasta"}: <b>{newSequence.ncfTo}</b></p>

                                </div>
                                :
                                <div
                                    style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column"
                                    }}
                                >
                                    <div
                                        style={{
                                            margin: "10px 10px 15px 10px",
                                            width: "calc(100% - 40px)",
                                        }}
                                    >
                                        <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "NCF From" : "NCF Desde"}: <b>{appLanguage.en ? showAlertNcfFrom.enText : showAlertNcfFrom.esText}</b></p>
                                        <input
                                            value={newSequence.ncfFrom}
                                            onChange={(e) => onChangenNcfFrom(e)}
                                            onKeyDown={e => handleKeyPress(e)}
                                            inputMode="text"
                                            maxLength={13}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            placeholder={appLanguage.en ? `Ex: B${comprobanteId}00000000` : `Ej: B${comprobanteId}00000000`}
                                            style={{
                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                color: appTheme.dark ? "white" : "black",
                                                marginBottom: "20px"
                                            }}
                                            className={`cash-in-cash-reg-input ${showAlertNcfFrom.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                        />

                                        <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "NCF To" : "NCF Hasta"}: <b>{appLanguage.en ? showAlertNcfTo.enText : showAlertNcfTo.esText}</b></p>
                                        <input
                                            value={newSequence.ncfTo}
                                            onChange={(e) => onChangeNcfTo(e)}
                                            onKeyDown={e => handleKeyPress(e)}
                                            inputMode="text"
                                            maxLength={13}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            placeholder={appLanguage.en ? `Ex: B${comprobanteId}00000000` : `Ej: B${comprobanteId}00000000`}
                                            style={{
                                                colorScheme: appTheme.dark ? "dark" : "light",
                                                background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                                color: appTheme.dark ? "white" : "black",
                                                marginBottom: "20px",
                                            }}
                                            className={`cash-in-cash-reg-input ${showAlertNcfTo.alert ? "cash-in-cash-reg-input-alert" : (appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                                        />

                                        <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Expiration date" : "Fecha de vencimiento"}: {showAlertFrom ? <b>{appLanguage.en ? "* Invalid date" : "* Fecha inválida"}</b> : null}</p>
                                        <InputTypeDate2
                                            dateObj={dateObjFrom}
                                            setDateObj={setDateObjFrom}
                                            dateString={dateStringFrom}
                                            setDateString={setDateStringFrom}
                                            showAlert={showAlertFrom}
                                            setShowAlert={setShowAlertFrom}
                                            onClickPick={onClickPickFrom}
                                        />
                                    </div>
                                </div>
                            }

                            <div
                                className="drop-men-not-save-con"
                                style={{
                                    marginTop: "25px",
                                    marginBottom: "15px"
                                }}
                            >
                                {isSecondSectionSelected ?
                                    <button
                                        onClick={onClickSave}
                                        className="new-customer-bottons-button-save"
                                        style={{
                                            minWidth: "150px"
                                        }}
                                    >
                                        <img src={saveIcon} alt="Save" />
                                        {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                    </button>
                                    :
                                    <button
                                        onClick={onClickNext}
                                        className="new-customer-bottons-button-save"
                                        style={{
                                            minWidth: "150px"
                                        }}
                                    >

                                        {appLanguage.en ? <p>Next</p> : <p>Siguiente</p>}
                                        <img style={{ marginLeft: "15px", width: "20px", height: "20px" }} src={awwowSmallRightDark} alt="Next" />
                                    </button>
                                }
                            </div>

                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

const NumeracionPicker = ({
    numeracionPickerActive,
    pageWidth,
    numeracionPickerRef,
    loading,
    setLoading,
    comprobante,
    comprobanteId,
    setSequences,
    onClickClose,
    activeSequence,
    getKeyById,
    closeAllPickers,
    onResetAll
}) => {
    const { appTheme, appLanguage, getDateWithMonthShort } = useContext(AppContext);

    const { setAlertData, setAlertActive, getErrorDescription, setOnAgree } = useContext(AlertContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const { accountData } = useContext(AuthContext);

    const isNotExpired = (time) => {
        const expirationDate = new Date(time);
        const today = new Date();
        return expirationDate > today;
    }

    const getNextNCF = (sequence) => {
        if (sequence && isNotExpired(sequence.expirationDate)) {
            if (sequence.ncfCanceled && sequence.ncfCanceled.length > 0 ) {
                return sequence.ncfCanceled[0];
            } else {
                if (sequence.currentNcf) {
                    return sequence.currentNcf;
                } else {
                    return appLanguage.en ? "None" : "Ninguno";
                }
            }
        } else {
            return appLanguage.en ? "None" : "Ninguno";
        }
    }

    const getNCFsToBeRecycled = () => {
        if (activeSequence && Array.isArray(activeSequence.ncfCanceled) && isNotExpired(activeSequence.expirationDate)) {
            return activeSequence.ncfCanceled.length;
        } else {
            return 0;
        }
    }

    const onConfirmDelete = async () => {
        setLoading(true);
        setAlertActive(false);

        const keyName = getKeyById(comprobante.id);

        try {
            const res = await firebase.useDeleteComprobanteSequence({
                "businessId": accountData.id,
                "comprobanteId": comprobanteId,
                "keyName": keyName,
                "sequenceId": activeSequence.id,
            });

            setSequences(res);

            onResetAll(res);

            setAlertActive(false);
            setLoading(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Sequence added successfully',
                    es: 'Sequencia agregada exitosamente'
                }
            });

            closeAllPickers();

        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));

            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to delete the sequence`,
                    es: `Error al intentar eliminar la secuencia`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    const onDelete = () => {
        setAlertData({
            type: 'question',
            title: {
                en: `Delete sequence?`,
                es: `¿Eliminar secuencia?`
            },
            code: '',
            description: {
                en: `Are you sure you want to delete this sequence?`,
                es: `¿Estás seguro de que deseas eliminar esta secuencia?`
            }
        });
        setOnAgree(() => onConfirmDelete);
        setAlertActive(true);
    }

    return (
        <div
            style={{
                WebkitUserSelect: "auto",
                WebkitTouchCallout: "auto",
                MozUserSelect: "auto",
                MsUserSelect: "auto",
                userSelect: "auto",
            }}
        >
            {numeracionPickerActive ? <div className='picker-date-lock-screen' style={{ width: pageWidth, minHeight: "calc(100vh - 65px)" }} /> : null}

            <div ref={numeracionPickerRef} className={`picker-date-genaral-card ${numeracionPickerActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pageWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                <div className='picker-date-general-despcrition' style={{ display: "flex", justifyContent: "space-between", minHeight: "250px" }}>
                    {loading ?
                        <div style={{ marginTop: "40px" }}><Spinner /></div>
                        :
                        <Fragment>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                                <h4 style={{ marginLeft: "15px" }}>
                                    <span style={{ fontWeight: "600" }}>{appLanguage.en ? "More details of the sequence" : "Más detalles de la secuencia"}</span>
                                </h4>

                                <button
                                    onClick={onClickClose}
                                    className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                >
                                    <img
                                        className="see-details-transations-img"
                                        src={appTheme.dark ? closeDarkIcon : closeLightIcon}
                                        alt="close"
                                    />
                                </button>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    width: "calc(100% - 40px)",
                                    padding: "10px 20px",
                                    flexDirection: "column"
                                }}
                            >
                                {activeSequence ? <Fragment>
                                    <p
                                        style={{
                                            fontWeight: "400",
                                            textAlign: "center",
                                        }}
                                    >
                                        {appLanguage.en ? "Next NCF" : "Siguiente NCF"}:
                                    </p>
                                    <p
                                        style={{
                                            fontSize: "20px",
                                            textAlign: "center",
                                            fontWeight: "600",
                                            marginBottom: "20px"
                                        }}
                                    >
                                        {getNextNCF(activeSequence)}
                                    </p>

                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <p style={{ fontWeight: "400", padding: "5px" }}>{appLanguage.en ? "Serie" : "Serie"}: <b>{activeSequence.ncfFrom[0]}</b></p>
                                        <p style={{ fontWeight: "400", padding: "5px" }}>{appLanguage.en ? "Type" : "Tipo"}: <b>{activeSequence.ncfFrom[1]}{activeSequence.ncfFrom[2]}</b></p>
                                        <p style={{ fontWeight: "400", padding: "5px" }}>{appLanguage.en ? "Available" : "Disponibles"}: <b>{activeSequence.ncfQuantity}</b></p>
                                    </div>

                                    <p style={{ fontWeight: "400", padding: "15px 5px" }}>{appLanguage.en ? "Expiration date" : "Fecha de vencimiento"}: <b>{getDateWithMonthShort(new Date(activeSequence.expirationDate))}</b></p>

                                    <p style={{ fontWeight: "400", padding: "5px" }}>{appLanguage.en ? "From" : "Desde"}: <b>{activeSequence.ncfFrom}</b></p>
                                    <p style={{ fontWeight: "400", padding: "5px" }}>{appLanguage.en ? "To" : "Hasta"}: <b>{activeSequence.ncfTo}</b></p>

                                    <p style={{ fontWeight: "400", padding: "15px 5px" }}>{appLanguage.en ? "NCFs to be recycled" : "NCFs a ser reciclados"}: <b>{getNCFsToBeRecycled()}</b></p>
                                </Fragment> : null}

                            </div>

                            <div
                                className="drop-men-not-save-con"
                                style={{
                                    marginTop: "15px",
                                    marginBottom: "15px"
                                }}
                            >
                                <button
                                    onClick={onDelete}
                                    className="new-customer-bottons-button-save"
                                    style={{
                                        minWidth: "150px"
                                    }}
                                >
                                    <img src={deleteIcon} alt="Delete" />
                                    {appLanguage.en ? <p>Delete</p> : <p>Eliminar</p>}
                                </button>
                            </div>

                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

const NoSequencesYet = () => {
    const { appLanguage } = useContext(AppContext);

    const [enTitle] = useState("No sequence added");
    const [esTitle] = useState("No se ha agregado ninguna secuencia");

    const [enDesc] = useState("Once you have added any sequence of NCFs, they will be displayed here.");
    const [esDesc] = useState("Cuando hayas agregado alguna secuencia de NCFs, esta se mostrarán aquí.");

    return (
        <div className="no-customer-yet-main-container">
            <div style={{ maxWidth: "420px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img className="no-customer-yet-main-img" src={emptyBox} alt="No Sequences yet" />
                <p><b>{appLanguage.en ? enTitle : esTitle}</b></p>
                <p>{appLanguage.en ? enDesc : esDesc}</p>
            </div>
        </div>
    )
}

export default ComprobantePage;