import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { AuthContext } from "../../firebase/context";
import { AlertContext } from "../alert/alertContext";
import { SoftAlertContext } from "../soft-alert/softAlertContext";
import { uid } from "uid";
import fingerIconDark from '../../icons/fingerprint-dark.png';
import fingerIconLight from '../../icons/fingerprint-light.png';
import { decode } from 'cbor-x';
import Spinner from '../spinner/spinner';

import checkIcon from "../../img/check.svg";
import uncheckIcon from "../../img/uncheck.svg";

import "./settingsProtectAccess.scss";

const SettingsProtectAccess = () => {

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, getErrorDescription, setOnAgree } = useContext(AlertContext);
    const { appTheme, appLanguage, setCredentials, protectedAccess } = useContext(AppContext);
    const { userData } = useContext(AuthContext);

    const randomString = uid(20);

    const [ loading, setLoading ] = useState(false);

    async function activateEncryption() {
        setLoading(true);

        const publicKeyCredentialCreationOptions = {
            challenge: Uint8Array.from(
                randomString.toString(), c => c.charCodeAt(0)),
            rp: {
                name: "HazCuentas",
                id: "app.hazcuentas.com",
            },
            user: {
                id: Uint8Array.from(
                    userData.id, c => c.charCodeAt(0)),
                name: userData.email,
                displayName: userData.displayName,
            },
            pubKeyCredParams: [{alg: -7, type: "public-key"}, {alg: -257, type: "public-key"}],
            authenticatorSelection: {
                authenticatorAttachment: "platform",
            },
            timeout: 60000,
            attestation: "direct"
        };

        try {
            const credential = await navigator.credentials.create({
                publicKey: publicKeyCredentialCreationOptions
            });

            // note: a CBOR decoder library is needed here.
            const decodedAttestationObject = decode(new Uint8Array(credential.response.attestationObject));

            const {authData} = decodedAttestationObject;

            // get the length of the credential ID
            const dataView = new DataView(new ArrayBuffer(2));

            const idLenBytes = authData.slice(53, 55);

            idLenBytes.forEach((value, index) => dataView.setUint8(index, value));

            const credentialIdLength = dataView.getUint16();

            // get the credential ID
            const credentialId = authData.slice(55, 55 + credentialIdLength);

            saveKeyIdLocally(credentialId);

        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));
            setAlertData({
                type : 'error',
                title: {
                    en : 'Error trying to login',
                    es : 'Error al intentar iniciar sesión'
                },
                code : code,
                description : getErrorDescription(code)
            });
            setAlertActive(true);
        }

        setLoading(false);
    }

    const saveKeyIdLocally = (credentialId) => {

        const indexedDB = window.indexedDB;

        const request = indexedDB.open("WebAuthnKeyId", 1);

        request.onerror = function (event) {
            console.error("An error occured with IndexdDB");
            console.error(event);
        }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("publicKey")) {
                db.createObjectStore("publicKey", { keyPath: "id"});
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            
            const webAuthnKeyId = {
                id: 1,
                key: credentialId
            };

            const transaction = db.transaction('publicKey', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
            }
            
            const store = transaction.objectStore('publicKey');

            const requestToCheck = store.get(1);

            requestToCheck.onsuccess = (ev) => {

                const request = ev.target;

                if (request.result) {

                    const requestToUpdate = store.put(webAuthnKeyId);

                    requestToUpdate.onsuccess = () => {      
                        setCredentials(credentialId);
                    }

                    requestToUpdate.onerror = () => {
                        console.log("Error updating data");
                    }

                } else {
                    const requestToAdd = store.add(webAuthnKeyId);

                    requestToAdd.onsuccess = () => {
                        setCredentials(credentialId);
                    }

                    requestToAdd.onerror = () => {
                        console.log("Error adding data");
                    }
                }
            }

            requestToCheck.onerror = () => {
                console.log("Error getting data");
            }
        }

        setSoftAlertActive(true);
        setSoftAlertData({
            type : 'sucess',
            text: {
                en : "Protection active",
                es : "Protección activa"
            }
        });
    }

    async function deactivateEncryption() {
        setAlertActive(false);

        const indexedDB = window.indexedDB;

        const request = indexedDB.open("WebAuthnKeyId", 1);

        request.onerror = function (event) {
            console.error("An error occured with IndexdDB");
            console.error(event);
        }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("publicKey")) {
                db.createObjectStore("publicKey", { keyPath: "id"});
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            
            const webAuthnKeyId = {
                id: 1,
                key: new Uint8Array()
            };

            const transaction = db.transaction('publicKey', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
            }
            
            const store = transaction.objectStore('publicKey');

            const requestToCheck = store.get(1);

            requestToCheck.onsuccess = (ev) => {

                const request = ev.target;

                if (request.result) {

                    const requestToUpdate = store.put(webAuthnKeyId);

                    requestToUpdate.onsuccess = () => {      
                        setCredentials(null);
                    }

                    requestToUpdate.onerror = () => {
                        console.log("Error updating data");
                    }

                } else {
                    const requestToAdd = store.add(webAuthnKeyId);

                    requestToAdd.onsuccess = () => {
                        setCredentials(null);
                    }

                    requestToAdd.onerror = () => {
                        console.log("Error adding data");
                    }
                }
            }

            requestToCheck.onerror = () => {
                console.log("Error getting data");
            }
        }

        setSoftAlertActive(true);
        setSoftAlertData({
            type : 'sucess',
            text: {
                en : "Protection disabled",
                es : "Protección desactivada"
            }
        });
    }

    const deactivateEncryptionConfirmation = () => {
        setAlertData({
            type: 'question',
            title: {
                en: 'Disable protection?',
                es: '¿Desactivar protección?'
            },
            code: 'confirm-log-out',
            description: {
                en: "Are you sure you want to deactivate the protection of this application?",
                es: "¿Estás seguro de que quieres desactivar la protección de esta aplicación?"
            }
        });
        setOnAgree(() => deactivateEncryption);
        setAlertActive(true);
    }

    return (
        <div className={`settings-protect-access-cont `}>
            <div className="settings-protect-access-title-con">
                <img className="settings-protect-fingerprint" src={appTheme.dark ? fingerIconDark : fingerIconLight} alt="Protect" />
                <img className="settings-protect-status" src={protectedAccess ? checkIcon : uncheckIcon} alt="active" />
                {protectedAccess ? <p>{appLanguage.en ? "Protection active" : "Protección activa"}</p> : <p>{appLanguage.en ? "Protection disabled" : "Protección desactivada"}</p>}
            </div>
            {loading ? <br/> : 
                <div className="settings-protect-access-title-descp">
                    {appLanguage.en ? <p>"Use this device to unlock the app (via fingerprint, facial recognition or PIN)"</p> : <p>Utilice este dispositivo para desbloquear la aplicación (mediante huella digital, reconocimiento facial o PIN)</p>}
                </div>
            }
            {protectedAccess ? 
                <div className="settings-protect-access-title-button">
                    {loading ? <Spinner /> : 
                        <button onClick={deactivateEncryptionConfirmation}><p>{appLanguage.en ? "Deactivate" : "Desactivar"}</p></button>
                    }
                </div>
            :
                <div className="settings-protect-access-title-button">
                    {loading ? <Spinner /> : 
                        <button onClick={activateEncryption}><p>{appLanguage.en ? "Activate" : "Activar"}</p></button>
                    }
                </div>
            }
        </div>
    );
}

export default SettingsProtectAccess;