import { Fragment, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../context/AppContext';
import { AuthContext } from "../../../firebase/context";
import { SideMenuContext } from '../../../context/SideMenuContext';
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

import Spinner from "../../../components/spinner/spinner";

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';
import SettingsTitle from '../../../components/settings-title/settingsTitle';

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import saveIcon from "../../../icons/check-dark.png";

import pageIcon from '../../../img/logo192.png';
import firebase from '../../../firebase/firebase';

const PrintSizePage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);

    const { userData, setUserData, saveKeyInIndexedDB } = useContext(AuthContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const { desktopView } = useContext(SideMenuContext);

    const [loadingSettings, setLoadingSettings] = useState(false);

    const [showSave, setShowSave] = useState(false);

    const getCurrentInvoiceSize = (userData) => {
        if (userData && userData.printSettings) {
            return userData.printSettings.invoiveSize;
        } else {
            return "none";
        }
    }

    const [tempSettings, setTempSittings] = useState(getCurrentInvoiceSize(userData) ? {
        "invoiveSize": getCurrentInvoiceSize(userData),
    } : null);

    useEffect(() => {
        setTempSittings(getCurrentInvoiceSize(userData) ? {
            "invoiveSize": getCurrentInvoiceSize(userData),
        } : null);
    }, [userData]);

    const handleChangeSettings = (e) => {
        setTempSittings({
            "invoiveSize": e.target.value,
        });
    }

    const setInvoiceTemp = (value) => {
        setTempSittings({
            "invoiveSize": value,
        });
    }

    useEffect(() => {
        if (tempSettings && userData) {
            if (tempSettings.invoiveSize === getCurrentInvoiceSize(userData)) {
                setShowSave(false);
            } else {
                setShowSave(true);
            }
        }
    }, [tempSettings, userData]);

    async function updateInvoiceSize() {
        setLoadingSettings(true);

        try {
            const response = await firebase.useUpdatePrintSize({
                tempSettings: tempSettings,
            });
            
            setUserData(response);
            saveKeyInIndexedDB("userData", response);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Changes saved.',
                    es: 'Cambios guardados.',
                }
            });

            setLoadingSettings(false);
        } catch (error) {
            console.error(error);
            setLoadingSettings(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error saving changes.',
                    es: 'Error al guardar cambios.',
                }
            });
        }
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Print Size" : "Tamaño de Impresión"} </title>
            </Helmet>
            <div
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                style={{
                    colorScheme: appTheme.dark ? "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ?
                        <SettingsHeader2
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "More options" : "Más opciones"}
                            textLink={"/ajustes"}
                            text2={appLanguage.en ? "Print Size" : "Tamaño de Impresión"}
                        />
                        : null}

                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitle
                                icon={pageIcon}
                                firstText={"HazCuentas v2.1"}
                                secondText={"República Dominicana 2025"}
                            />
                            
                            <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
                                <div
                                    style={{
                                        boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                        width: "290px",
                                        position: "static",
                                        margin: 0,
                                    }}
                                    className={`dropdown-menu-notification ${appTheme.dark ? "dropdown-menu-notification-dark border-box-dark" : "dropdown-menu-notification-light border-box-light"} active`}
                                >
                                    {loadingSettings ?
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                minHeight: "200px",
                                                paddingTop: "50px"
                                            }}
                                        >
                                            <Spinner />
                                        </div>
                                    :
                                        <Fragment>
                                            {tempSettings ?
                                                <div
                                                    style={{
                                                        minHeight: "220px",
                                                    }}
                                                >
                                                    <div className="drop-men-not-option-con">
                                                        <h3 style={{textAlign: "center", marginBottom: "25px"}}>{appLanguage.en ? "Size when printing sales or quotes" : "Tamaño al imprimir ventas o cotizaciones"}</h3>

                                                        <div
                                                            className={`settings-bills-const-amount-to-pay-item ${tempSettings.invoiveSize === "letter" ? "settings-bills-const-amount-to-pay-item-hover" : null}`}
                                                            onClick={() => setInvoiceTemp("letter")}
                                                            style={{
                                                                padding: "8px 10px",
                                                                justifyContent: "flex-start",
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <input
                                                                style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                                                type="radio"
                                                                id="letter"
                                                                name="invoiveSize"
                                                                checked={tempSettings.invoiveSize === "letter" ? true : false}
                                                                value="letter"
                                                                onChange={handleChangeSettings}
                                                            />
                                                            <label htmlFor="letter">Normal ({appLanguage.en ? "letter" : "carta"})</label>
                                                        </div>

                                                        <div
                                                            className={`settings-bills-const-amount-to-pay-item ${tempSettings.invoiveSize === "72mm" ? "settings-bills-const-amount-to-pay-item-hover" : null}`}
                                                            onClick={() => setInvoiceTemp("72mm")}
                                                            style={{
                                                                padding: "8px 10px",
                                                                justifyContent: "flex-start",
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <input
                                                                style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                                                type="radio"
                                                                id="72mm"
                                                                name="invoiveSize"
                                                                checked={tempSettings.invoiveSize === "72mm" ? true : false}
                                                                value="72mm"
                                                                onChange={handleChangeSettings}
                                                            />
                                                            <label htmlFor="72mm">72 mm ({appLanguage.en ? "receipt" : "recibo"})</label>
                                                        </div>

                                                        <div
                                                            className={`settings-bills-const-amount-to-pay-item ${tempSettings.invoiveSize === "48mm" ? "settings-bills-const-amount-to-pay-item-hover" : null}`}
                                                            onClick={() => setInvoiceTemp("48mm")}
                                                            style={{
                                                                padding: "8px 10px",
                                                                justifyContent: "flex-start",
                                                                maxWidth: "100%"
                                                            }}
                                                        >
                                                            <input
                                                                style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                                                type="radio"
                                                                id="48mm"
                                                                name="invoiveSize"
                                                                checked={tempSettings.invoiveSize === "48mm" ? true : false}
                                                                value="48mm"
                                                                onChange={handleChangeSettings}
                                                            />
                                                            <label htmlFor="48mm">48 mm ({appLanguage.en ? "receipt" : "recibo"})</label>
                                                        </div>

                                                    </div>

                                                    <div
                                                        className="drop-men-not-save-con"
                                                        style={{
                                                            marginTop: "35px"
                                                        }}
                                                    >
                                                        <button
                                                            onClick={updateInvoiceSize}
                                                            disabled={!showSave}
                                                            className="new-customer-bottons-button-save"
                                                            style={!showSave ? {
                                                                minWidth: "150px",
                                                                backgroundColor: "#3f526698",
                                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                cursor: "default"
                                                            } : {
                                                                minWidth: "150px"
                                                            }}
                                                        >
                                                            {showSave ? <img src={saveIcon} alt="Save" /> : null}
                                                            {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                        </button>
                                                    </div>
                                                </div>
                                            : null}
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default PrintSizePage;