import "./saleView.page.scss";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";

import ReactToPrint from 'react-to-print';
import jsPDF from 'jspdf';

import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";

import firebase from "../../../firebase";
import { onSnapshot, doc } from "firebase/firestore";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import deleteIcon from "../../../icons/closed-dark.png";

import printIconDark from "../../../icons/printer-dark.png";
import printIconLight from "../../../icons/printer-ligth.png";

import dollarSighDarkIcon from "../../../icons/dollar-sign-dark.png";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import arrowDarkIcon from "../../../icons/arrowhead-dark.png";
import arrowLightIcon from "../../../icons/arrowhead-light.png";

import arrowLineWhiteIcon from "../../../icons/arrow-small-right-white.png";
import arrowLineIcon from "../../../icons/arrow-small-right.png";

import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";

import sendIconDark from "../../../icons/share-with-dark.png";
import sendIconLight from "../../../icons/share-with-light.png";

import downloadIconDark from "../../../icons/download-dark.png";
import copyIconDark from "../../../icons/copy.png";
import copyIconLight from "../../../icons/copyLight.png";
import emailIconDark from "../../../icons/email-dark.png";
import whatsAppIconDark from "../../../icons/whatsapp-icon-dark.png";

import completedIcon from "../../../icons/sale-completed.png";
import pendingIcon from "../../../icons/sale-pending.png";
import cancelIcon from "../../../icons/sale-cancel.png";

import histotyDarkIcon from "../../../icons/history-dark.png";

import { uid } from "uid";
import Spinner from "../../../components/spinner/spinner";
import ConnectionError from "../../../components/connection-error/connectionError";
import CollectPaymentFromSale from "../../../appTools/appToolsComponents/collectPayment/collectPaymentFromSale";
import { CollectPaymentContext } from "../../../appTools/appToolsComponents/collectPayment/collectPaymentContext";
import SalesSummaryTable from "../../../appTools/appToolsComponents/salesSummaryTable/salesSummaryTable";
import { AlertContext } from "../../../components/alert/alertContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import SaleReceipt from "../../../receipts/saleReceipt";
import ShareViaExternalApp from "../../../appTools/appToolsComponents/shareViaExternalApp/shareViaExternalApp";
import { ShareViaExternalAppContext } from "../../../appTools/appToolsComponents/shareViaExternalApp/shareViaExternalAppContext";
import SaleReceiptPDF from "../../../receipts/saleReceiptPDF";
import { SoftLoadingContext } from "../../../components/soft-loading/softLoadingContext";

const SaleViwePage = () => {

    const { appTheme, appLanguage, isTouchEnabled, formatRationalNumber, setDropDownCartOpen, setDropDownProfileMenuOpen } = useContext(AppContext);

    const { setAlertData, setAlertActive, setOnAgree, getErrorDescription } = useContext(AlertContext);
    const { setSoftLoadingActive } = useContext(SoftLoadingContext);
    const { accountData, accessTo, userSettings, setSaleListener, setAuthorizedUsersListener } = useContext(AuthContext);

    const { setCashAmount, setCashToCharge, setCollectPaymentActive } = useContext(CollectPaymentContext);
    const { setContactType, setShareViaExternalAppActive } = useContext(ShareViaExternalAppContext);

    const { windowDimension, desktopView, isSideMenuMinimized } = useContext(SideMenuContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    // This prevent the page send a request twice to the backend
    const [generalState] = useState("0000");

    useEffect(() => {
        setSaleListener(true);
        setAuthorizedUsersListener(true);
        // eslint-disable-next-line
    }, [generalState]);

    let shareRef = useRef();
    let moreRef = useRef();

    const location = useLocation();

    const [saleId, setSaleId] = useState(null);
    const [receiptData, setReceiptData] = useState(null);

    const [shareIcon, setShareIcon] = useState(appTheme.dark ? sendIconDark : sendIconLight);
    const [moreIcon, setMoreIcon] = useState(appTheme.dark ? arrowDarkIcon : arrowLightIcon);

    const [dropDownShareOpen, setDropDownShareOpen] = useState(false);
    const [dropDownMoreOpen, setDropDownMoreOpen] = useState(false);

    useEffect(() => {
        if (dropDownShareOpen) {
            setShareIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setShareIcon(appTheme.dark ? sendIconDark : sendIconLight);
        }
    }, [dropDownShareOpen, appTheme]);

    useEffect(() => {
        if (dropDownMoreOpen) {
            setMoreIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setMoreIcon(appTheme.dark ? arrowDarkIcon : arrowLightIcon);
        }
    }, [dropDownMoreOpen, appTheme]);

    const onClickShareButton = () => {
        dropDownShareOpen ? setDropDownShareOpen(false) : setDropDownShareOpen(true);
    }

    const onClickMoreButton = () => {
        dropDownMoreOpen ? setDropDownMoreOpen(false) : setDropDownMoreOpen(true);
    }

    useEffect(() => {
        if (dropDownShareOpen && shareRef) {
            if (shareRef.current) {
                let handler = (e) => {
                    if (!shareRef.current.contains(e.target)) {
                        setDropDownShareOpen(false);
                    };
                };
                document.addEventListener("mousedown", handler);
                return () => {
                    document.removeEventListener("mousedown", handler);
                }
            }
        }
    });

    useEffect(() => {
        if (dropDownMoreOpen && moreRef) {
            if (moreRef.current) {
                let handler = (e) => {
                    if (!moreRef.current.contains(e.target)) {
                        setDropDownMoreOpen(false);
                    };
                };
                document.addEventListener("mousedown", handler);
                return () => {
                    document.removeEventListener("mousedown", handler);
                }
            }
        }
    });

    const [request, setRequest] = useState("0000");

    const [showConnectionError, setShowConnectionError] = useState(false);

    const [loading, setLoading] = useState(true);

    const [sale, setSale] = useState(null);

    const [customerId, setCustomerId] = useState(null);
    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        if (customerId && accessTo && accessTo.customers) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/customers`, customerId), (doc) => {
                const consumerUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete consumerUpdated.searchKeys;

                if (doc.data()) {
                    setCustomer(consumerUpdated);
                }
            }, err => {
                console.error(err);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [customerId]);

    useEffect(() => {
        if (sale) {
            setCustomerId(sale.customerId);
            setReceiptData({
                ...sale.invoice,
                "items": sale.items,
                "subtotal": sale.subtotal,
                "desc": sale.desc,
                "itbis": sale.itbis,
                "total": sale.total,
                "payments": sale.payments
            })
        } else {
            setReceiptData(null);
        }
        // eslint-disable-next-line
    }, [sale]);

    const receiptRef = useRef();
    const receiptRefPDF = useRef();

    // Now I have to listeng to the product doc
    useEffect(() => {
        if (saleId && (request !== "0000")) {
            setShowConnectionError(false);
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/sales`, saleId.id), (doc) => {
                const saleData = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete saleData.searchKeys;

                if (doc.data()) {
                    setSale(saleData);
                    setShowConnectionError(false);
                } else {
                    setShowConnectionError(true);
                }

                // Si el producto no exite entonce no existe
                setLoading(false);
            }, err => {
                console.error(err);
                setShowConnectionError(true);
                setLoading(false);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [request]);

    const onTryAgain = () => {
        setRequest(uid());
    }

    const navigate = useNavigate();

    let headerObservedRef = useRef(null);
    let pageObserverRef = useRef(null);

    const [headerHeight, setHeaderHeight] = useState(107);

    const [pageWidth, setPageWidth] = useState(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return windowDimension.width;
            } else {
                try {
                    const width = pageObserverRef.current.offsetWidth
                    return width;
                } catch {
                    return windowDimension.width;
                }
            }
        } else {
            return windowDimension.width;
        }
    });

    useEffect(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return;
            }
            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (pageObserverRef.current.offsetWidth !== pageWidth) {
                        setPageWidth(pageObserverRef.current.offsetWidth);
                    }
                } catch { }
            });
            resizeObserver.observe(pageObserverRef.current);
            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [pageObserverRef.current, windowDimension, isSideMenuMinimized]);

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, isSideMenuMinimized]);

    useEffect(() => {
        if (location.state) {
            setSaleId({
                "id": location.state.id
            });
            setRequest(uid());
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.salesConsult)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        navigate(null, { replace: true, state: saleId });
        // eslint-disable-next-line
    }, [saleId]);

    const onCancelSale = async () => {
        setLoading(true);
        setAlertActive(false);

        try {
            const res = await firebase.useCancelSale({
                businessId: accountData.id,
                saleId: sale.id,
            });

            setSale(res)
            setLoading(false);

            setAlertData({
                type: 'success',
                title: {
                    en: 'Sale canceled',
                    es: 'Venta cancelada'
                },
                code: '',
                description: {
                    en: `Sale ${sale.id} has been successfully cancelled. You can print the receipt for more details.`,
                    es: `La venta ${sale.id} ha sido cancelada de manera exitosa. Puedes imprimir el recibo para obtener más detalles.`,
                }
            });
            setAlertActive(true);

        } catch (error) {
            console.error(error);
            const { code } = JSON.parse(JSON.stringify(error));
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to cancel this sale.`,
                    es: `Error al intentar cancelar esta venta.`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    const onDelete = () => {
        setAlertData({
            type: 'question',
            title: {
                en: `Delete sale?`,
                es: `¿Eliminar venta?`
            },
            code: '',
            description: {
                en: `Are you sure you want to cancel the sale ${sale.id}?`,
                es: `¿Estás seguro de que deseas cancelar la venta ${sale.id}?`
            }
        });
        setOnAgree(() => onCancelSale);
        setAlertActive(true);
    }

    const onClickOpenProduct = (product) => {
        navigate("/ver-producto", { state: product.product })
    }

    const onCollectPayment = () => {
        setCollectPaymentActive(true);
        if (sale && (!sale.splitPayment)) {
            setCashAmount(formatRationalNumber(Math.round(sale.total)));
            setCashToCharge(formatRationalNumber(Math.round(sale.total)));
        }
    }

    const onScroll = () => {
        setDropDownCartOpen(false);
        setDropDownProfileMenuOpen(false);
        setDropDownMoreOpen(false);
    }

    const canDeleteSale = () => {
        if (sale.status !== "canceled") {
            if (accessTo.salesSup) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const canPrintInvoice = () => {
        if (sale.invoice) {
            return true;
        } else {
            return false;
        }
    }

    const getInvoiceSize = () => {
        if (userSettings) {
            if (userSettings.invoiveSize === "72mm") {
                return "(72 mm)"
            } else {
                if (userSettings.invoiveSize === "48mm") {
                    return "(48 mm)"
                } else {
                    return "(Normal)"
                }
            }
        } else {
            return ""
        }
    }

    const onClickShareOption = (option) => {
        if (sale && sale.invoiceLinkId) {
            setContactType(option)
            setShareViaExternalAppActive(true);
            setDropDownShareOpen(false);
        } else {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Link not found',
                    es: 'Enlace no encontrado',
                }
            });
        }
    }

    const onCopyInvoiceLink = () => {
        if (sale && sale.invoiceLinkId) {
            const invoiceLink = `https://factura.hazcuentas.com/?id=${sale.invoiceLinkId}`;
            try {
                navigator.clipboard.writeText(invoiceLink);
                setSoftAlertActive(true);
                setSoftAlertData({
                    type: 'sucess',
                    text: {
                        en: 'Invoice link has been copied',
                        es: 'El enlace de la factura ha sido copiado',
                    }
                });
                setDropDownShareOpen(false);
            } catch {
                setSoftAlertActive(true);
                setSoftAlertData({
                    type: 'error',
                    text: {
                        en: 'Copy error',
                        es: 'Error al copiar',
                    }
                });
            }
        } else {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Link not found',
                    es: 'Enlace no encontrado',
                }
            });
        }
    }

    const generatePDF = async () => {
        blinkSoftLoading();
        setDropDownShareOpen(false);
        try {
            const doc = new jsPDF('portrait', 'pt', 'letter');
            doc.html(receiptRefPDF.current, {
                callback: function (pdf) {
                    pdf.save(`Venta_${receiptData.saleId}.pdf`);
                },
                x: 65,
                y: 0,
                html2canvas: {
                    scale: 0.74
                }
            });
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const blinkSoftLoading = () => {
        setSoftLoadingActive(true);
        setTimeout(() => {
            setSoftLoadingActive(false);
        }, 1000);
    }

    const whereToShowPrintBtn = () => {
        return "onTop";
    }

    const whereToShowDeleteBtn = () => {
        if (pageWidth > 530) {
            return "onTop";
        } else {
            return "onMoreOptions";
        }
    }

    const whereToShowPaymentBtn = () => {
        if (pageWidth > 660) {
            return "onTop";
        } else {
            return "onMoreOptions";
        }
    }

    const whereToShowHistoryBtn = () => {
        if (pageWidth > 710) {
            return "onTop";
        } else {
            return "onMoreOptions";
        }
    }

    const getShowMore = () => {
        if (pageWidth > 710) {
            return false;
        } else {
            return true;
        }
    }

    const getSaleIcon = (status) => {
        switch (status) {
            case "completed":
                return completedIcon;
            case "canceled":
                return cancelIcon;
            default:
                return pendingIcon;
        }
    }

    const getSaleStatus = (status) => {
        switch (status) {
            case "completed":
                return appLanguage.en ? "Completed" : "Completa";
            case "canceled":
                return appLanguage.en ? "Canceled" : "Cancelada";
            default:
                return appLanguage.en ? "Pending" : "Pendiente";
        }
    }

    const copySaleId = () => {
        try {
            const text = sale.id;
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The code has been copied',
                    es: 'El código ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const onClickSeePayments = () => {
        navigate("/pagos-a-venta", { state: { id: sale.id } })
    }

    const onClickSeeHistory = () => {
        navigate("/historial-de-la-venta", { state: { id: sale.id } })
    }

    const onClickMoreDetails = () => {
        navigate("/mas-detalles-de-venta", { state: { id: sale.id } })
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "View sale details" : "Ver detalles de venta"}</title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef} className={`page-choose-account-item`}>
                            {accessTo.salesConsult ? <Fragment>

                                <CollectPaymentFromSale
                                    pickerWidth={pageWidth}
                                    sale={sale}
                                    setSale={setSale}
                                />

                                <ShareViaExternalApp
                                    pickerWidth={pageWidth}
                                    sale={sale}
                                    customer={customer}
                                />

                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "View sale details" : "Ver detalles de venta"}
                                    />
                                    : null}

                                {loading ? <div style={{ marginTop: "60px" }} className="more-details-spinner-container"><Spinner /></div> :

                                    (showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> :
                                        (sale ?
                                            <Fragment>

                                                <div style={{ marginTop: "25px", display: "flex", width: "100%", justifyContent: "center" }}>
                                                    {/** Main container for seccion maxWidth: "745px" */}
                                                    <div style={{ width: "100%", maxWidth: "740px" }}>

                                                        <div ref={headerObservedRef} style={{ display: "flex", marginBottom: "5px", marginLeft: "5px" }}> {/** , flexWrap: "wrap"*/}
                                                            <div ref={shareRef} className="notification-filter-botton-container unselectable">
                                                                <button
                                                                    style={{
                                                                        marginLeft: "15px",
                                                                        marginBottom: "15px",
                                                                        minWidth: pageWidth < 400 ? "auto" : "150px",
                                                                        minHeight: "38.4px"
                                                                    }}
                                                                    className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                                    onClick={onClickShareButton}
                                                                >
                                                                    <img
                                                                        src={shareIcon}
                                                                        style={{
                                                                            width: "18px",
                                                                            height: "18px",
                                                                            marginRight: "8px"
                                                                        }}
                                                                        alt="Share"
                                                                    />
                                                                    <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Share" : "Compartir"}</p>
                                                                </button>

                                                                <span
                                                                    className={`notification-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownShareOpen ? 'active' : 'inactive'}`}
                                                                    style={{
                                                                        marginLeft: "80px",
                                                                        marginTop: "-8px",
                                                                    }}
                                                                />

                                                                <div
                                                                    style={{
                                                                        boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                        marginLeft: "15px",
                                                                        marginTop: "-4px",
                                                                        width: "220px",

                                                                    }}
                                                                    className={`dropdown-menu-notification ${appTheme.dark ? "dropdown-menu-notification-dark border-box-dark" : "dropdown-menu-notification-light border-box-light"} ${dropDownShareOpen ? 'active' : 'inactive'}`}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            minHeight: "220px",
                                                                            paddingTop: "13px"
                                                                        }}
                                                                    >
                                                                        <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                            <button
                                                                                className="invent-add-menu-btn"
                                                                                disabled={loading ? true : false}
                                                                                onClick={generatePDF}
                                                                                style={{
                                                                                    justifyContent: "flex-start"
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    style={{
                                                                                        width: "20px",
                                                                                        height: "20px",
                                                                                        marginRight: "10px",
                                                                                        marginLeft: "18px"
                                                                                    }}
                                                                                    src={downloadIconDark}
                                                                                    alt="Download"
                                                                                />
                                                                                <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "Download PDF" : "Descargar PDF"} </p>
                                                                            </button>

                                                                            <SaleReceiptPDF receiptData={receiptData} receiptRef={receiptRefPDF} />

                                                                        </div>
                                                                        <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                            <button
                                                                                className="invent-add-menu-btn"
                                                                                disabled={loading ? true : false}
                                                                                onClick={onCopyInvoiceLink}
                                                                                style={{
                                                                                    justifyContent: "flex-start"
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    style={{
                                                                                        width: "20px",
                                                                                        height: "20px",
                                                                                        marginRight: "10px",
                                                                                        marginLeft: "18px"
                                                                                    }}
                                                                                    src={copyIconDark}
                                                                                    alt="Copy"
                                                                                />
                                                                                <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "Copy link" : "Copiar enlace"} </p>
                                                                            </button>
                                                                        </div>
                                                                        <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                            <button
                                                                                className="invent-add-menu-btn"
                                                                                disabled={loading ? true : false}
                                                                                onClick={() => onClickShareOption("email")}
                                                                                style={{
                                                                                    justifyContent: "flex-start"
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    style={{
                                                                                        width: "20px",
                                                                                        height: "20px",
                                                                                        marginRight: "10px",
                                                                                        marginLeft: "18px"
                                                                                    }}
                                                                                    src={emailIconDark}
                                                                                    alt="Email"
                                                                                />
                                                                                <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "Email" : "Correo"} </p>
                                                                            </button>
                                                                        </div>
                                                                        <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                            <button
                                                                                className="invent-add-menu-btn"
                                                                                disabled={loading ? true : false}
                                                                                style={{
                                                                                    marginBottom: "15px",
                                                                                    justifyContent: "flex-start"
                                                                                }}
                                                                                onClick={() => onClickShareOption("whatsapp")}
                                                                            >
                                                                                <img
                                                                                    style={{
                                                                                        width: "20px",
                                                                                        height: "20px",
                                                                                        marginRight: "10px",
                                                                                        marginLeft: "18px"
                                                                                    }}
                                                                                    src={whatsAppIconDark}
                                                                                    alt="WhatsApp"
                                                                                />
                                                                                <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "WhatsApp" : "WhatsApp"} </p>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            {whereToShowPrintBtn() === "onTop" ?
                                                                (receiptData && ( <div onClick={() => blinkSoftLoading()}>
                                                                    <ReactToPrint
                                                                        trigger={() =>
                                                                            <button
                                                                                onClick={null}
                                                                                disabled={!canPrintInvoice()}
                                                                                className={pageWidth <= 530 ? `notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}` : (isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product")}
                                                                                style={canPrintInvoice() ? { 
                                                                                    margin: "0px 0px 15px 15px",
                                                                                    padding: "10px 20px",
                                                                                    maxHeight: "38.4"
                                                                                } : {
                                                                                    margin: "0px 0px 15px 15px",
                                                                                    padding: "10px 20px",
                                                                                    maxHeight: "38.4",
                                                                                    backgroundColor: "#3f526698",
                                                                                    color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                                    cursor: "not-allowed"
                                                                                }}
                                                                            >
                                                                                <img 
                                                                                    src={ pageWidth <= 530 ? (appTheme.dark ? printIconDark : printIconLight) : printIconDark} 
                                                                                    style={{
                                                                                        width: "16px",
                                                                                        height: "16px",
                                                                                        marginRight: "10px",
                                                                                    }}      
                                                                                    alt="Print"
                                                                                />

                                                                                {appLanguage.en ?
                                                                                    <p style={{ whiteSpace: "nowrap" }}>Print</p>
                                                                                :
                                                                                    <p style={{ whiteSpace: "nowrap" }}>Imprimir</p>
                                                                                }
                                                                            </button>
                                                                        }
                                                                        content={() => receiptRef.current}
                                                                        documentTitle={`Venta_${receiptData.saleId}`}
                                                                    />
                                                                    </div>))
                                                                : null}

                                                            <SaleReceipt receiptData={receiptData} receiptRef={receiptRef} />

                                                            {whereToShowDeleteBtn() === "onTop" ?
                                                                <button
                                                                    onClick={e => onDelete(e)}
                                                                    className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                    disabled={!canDeleteSale()}
                                                                    style={canDeleteSale() ? { margin: "0px 0px 15px 15px" } : {
                                                                        margin: "0px 0px 15px 15px",
                                                                        backgroundColor: "#3f526698",
                                                                        color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                        cursor: "not-allowed"
                                                                    }}
                                                                >
                                                                    <img src={deleteIcon} alt="Delete" />
                                                                    {appLanguage.en ? <p>Delete</p> : <p>Eliminar</p>}
                                                                </button>
                                                                : null}

                                                            {whereToShowPaymentBtn() === "onTop" ?
                                                                <button
                                                                    onClick={onClickSeePayments}
                                                                    className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                    style={{ margin: "0px 0px 15px 15px" }}
                                                                >
                                                                    <img src={dollarSighDarkIcon} alt="Payments" />
                                                                    <p>{appLanguage.en ? "Payments" : "Pagos"}</p>
                                                                </button>
                                                                : null}

                                                            {whereToShowHistoryBtn() === "onTop" ?
                                                                <button
                                                                    onClick={onClickSeeHistory}
                                                                    className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                    style={{ margin: "0px 0px 15px 15px" }}
                                                                >
                                                                    <img src={histotyDarkIcon} alt="History" />
                                                                    <p>{appLanguage.en ? "History" : "Historial"}</p>
                                                                </button>
                                                                : null}

                                                            {getShowMore() ?
                                                                <div ref={moreRef} className="notification-filter-botton-container unselectable">
                                                                    <button
                                                                        className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                                        onClick={onClickMoreButton}
                                                                        style={{
                                                                            marginLeft: "15px",
                                                                            marginBottom: "15px",
                                                                            minHeight: "38.4px"
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={moreIcon}
                                                                            style={{
                                                                                width: "18px",
                                                                                height: "18px",
                                                                            }}
                                                                            alt="More options"
                                                                        />
                                                                    </button>

                                                                    <span
                                                                        className={`notification-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownMoreOpen ? 'active' : 'inactive'}`}
                                                                        style={{
                                                                            marginLeft: "30px",
                                                                            marginTop: "-8px",
                                                                        }}
                                                                    />

                                                                    <div
                                                                        style={{
                                                                            boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                            marginLeft: "-160px",
                                                                            marginTop: "-4px",
                                                                            width: "220px",
                                                                            minHeight: "auto"
                                                                        }}
                                                                        className={`dropdown-menu-notification ${appTheme.dark ? "dropdown-menu-notification-dark border-box-dark" : "dropdown-menu-notification-light border-box-light"} ${dropDownMoreOpen ? 'active' : 'inactive'}`}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                paddingTop: "13px"
                                                                            }}
                                                                        >
                                                                            {whereToShowPrintBtn() === "onMoreOptions" ?
                                                                                <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                                    {receiptData && (
                                                                                        <ReactToPrint
                                                                                            trigger={() =>
                                                                                                <button
                                                                                                    onClick={null}
                                                                                                    disabled={!canPrintInvoice()}
                                                                                                    className="invent-add-menu-btn"
                                                                                                    style={{
                                                                                                        justifyContent: "flex-start"
                                                                                                    }}
                                                                                                >
                                                                                                    <img
                                                                                                        style={{
                                                                                                            width: "20px",
                                                                                                            height: "20px",
                                                                                                            marginRight: "10px",
                                                                                                            marginLeft: "18px"
                                                                                                        }}
                                                                                                        src={printIconDark}
                                                                                                        alt="Print"
                                                                                                    />
                                                                                                    {appLanguage.en ?
                                                                                                        <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>Print {getInvoiceSize()} </p>
                                                                                                        :
                                                                                                        <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>Imprimir {getInvoiceSize()} </p>
                                                                                                    }
                                                                                                </button>
                                                                                            }
                                                                                            content={() => receiptRef.current}
                                                                                            documentTitle={`Venta_${receiptData.saleId}`}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                                : null}

                                                                            {whereToShowDeleteBtn() === "onMoreOptions" ?
                                                                                <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                                    <button
                                                                                        className="invent-add-menu-btn"
                                                                                        disabled={!canDeleteSale()}
                                                                                        onClick={onDelete}
                                                                                        style={canDeleteSale() ? { justifyContent: "flex-start" } : {
                                                                                            justifyContent: "flex-start",
                                                                                            backgroundColor: "#3f526698",
                                                                                            color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                                            cursor: "not-allowed"
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            style={{
                                                                                                width: "20px",
                                                                                                height: "20px",
                                                                                                marginRight: "10px",
                                                                                                marginLeft: "18px"
                                                                                            }}
                                                                                            src={deleteIcon}
                                                                                            alt="Delete"
                                                                                        />
                                                                                        <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "Delete" : "Eliminar"} </p>
                                                                                    </button>
                                                                                </div>
                                                                                : null}

                                                                            {whereToShowPaymentBtn() === "onMoreOptions" ?
                                                                                <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                                    <button
                                                                                        className="invent-add-menu-btn"
                                                                                        disabled={loading ? true : false}
                                                                                        onClick={onClickSeePayments}
                                                                                        style={{
                                                                                            justifyContent: "flex-start"
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            style={{
                                                                                                width: "20px",
                                                                                                height: "20px",
                                                                                                marginRight: "10px",
                                                                                                marginLeft: "18px"
                                                                                            }}
                                                                                            src={dollarSighDarkIcon}
                                                                                            alt="Payments"
                                                                                        />
                                                                                        <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "Payments" : "Pagos"} </p>
                                                                                    </button>
                                                                                </div>
                                                                                : null}

                                                                            {whereToShowHistoryBtn() === "onMoreOptions" ?
                                                                                <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                                    <button
                                                                                        className="invent-add-menu-btn"
                                                                                        disabled={loading ? true : false}
                                                                                        style={{
                                                                                            marginBottom: "15px",
                                                                                            justifyContent: "flex-start"
                                                                                        }}
                                                                                        onClick={onClickSeeHistory}
                                                                                    >
                                                                                        <img
                                                                                            style={{
                                                                                                width: "20px",
                                                                                                height: "20px",
                                                                                                marginRight: "10px",
                                                                                                marginLeft: "18px"
                                                                                            }}
                                                                                            src={histotyDarkIcon}
                                                                                            alt="History"
                                                                                        />
                                                                                        <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "History" : "Historial"} </p>
                                                                                    </button>
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            : null}
                                                        </div>

                                                        {/** Sale heather */}
                                                        <div className="unselectable" style={{ display: "flex", width: "100%", marginBottom: "20px" }}>
                                                            <div
                                                                className={`shoppo-cart-cust-card-div ${appTheme.dark ? "shoppo-cart-cust-card-div-dark" : "shoppo-cart-cust-card-div-light"}`}
                                                                style={{
                                                                    display: "flex",
                                                                    paddingLeft: "10px",
                                                                    borderRadius: "100px",
                                                                    margin: "0px 10px 0 20px",
                                                                    width: "calc(100% - 40px)",
                                                                    height: "40px",
                                                                    minHeight: "36px",
                                                                }}
                                                            >
                                                                {sale ? <Fragment> 
                                                                    <img
                                                                        style={{
                                                                            width: "25px",
                                                                            height: "25px",
                                                                            marginLeft: "0px",
                                                                        }}
                                                                        src={getSaleIcon(sale.status)}
                                                                        alt="Status"
                                                                    />

                                                                    {pageWidth > 490 ?
                                                                        <p style={{ whiteSpace: "nowrap", fontSize: "16px", fontWeight: 500, marginLeft: "15px" }}>
                                                                            {getSaleStatus(sale.status)}
                                                                        </p>
                                                                    : null}
                                                                    
                                                                    <span
                                                                        onClick={copySaleId}
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            padding: "6px 10px",
                                                                            marginRight: "5px"
                                                                        }}
                                                                        className={`sale-copy-id-successful ${isTouchEnabled() ? "sale-copy-id-successful-no-hover" : "sale-copy-id-successful-hover"}`}
                                                                    >

                                                                        <p style={{ marginRight: "10px" }}>{sale.id}</p>

                                                                        <img
                                                                            src={appTheme.dark ? copyIconDark : copyIconLight}
                                                                            style={{ width: "16px", height: "16px" }}
                                                                            alt="Copy"
                                                                        />
                                                                    </span>

                                                                    <div style={{flexGrow: 4}} />

                                                                    <span
                                                                        onClick={onClickMoreDetails}
                                                                        className={`shopping-cart-search-for-btn ${isTouchEnabled() ? (appTheme.dark ? "shopping-cart-search-for-btn-dark-no-hover" : "shopping-cart-search-for-btn-light-no-hover") : (appTheme.dark ? "shopping-cart-search-for-btn-dark-hover" : "shopping-cart-search-for-btn-light-hover")}`}
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            padding: "8px 10px",
                                                                            margin: "0px",
                                                                            marginRight: "2px",
                                                                            width: "auto",
                                                                            boxShadow: "none",
                                                                        }}
                                                                    >

                                                                        <p style={{whiteSpace: "nowrap", marginRight: "10px" }}>{appLanguage.en ? "More details" :  "Más detalles"}</p>

                                                                        <img
                                                                            src={appTheme.dark ? arrowLineWhiteIcon : arrowLineIcon}
                                                                            style={{ width: "16px", height: "16px" }}
                                                                            alt="See more"
                                                                        />
                                                                    </span>
                                                                </Fragment> : null}
                                                            </div>
                                                        </div>

                                                        <SalesSummaryTable
                                                            onScroll={onScroll}
                                                            headerHeight={desktopView ? (270 + headerHeight) : (225 + headerHeight)}
                                                            results={sale ? sale.items : []}
                                                            onClickLink={onClickOpenProduct}
                                                            dropDownShareOpen={dropDownShareOpen}
                                                            dropDownMoreOpen={dropDownMoreOpen}
                                                        />

                                                        {/** Bottons and prices */}
                                                        <div style={{ display: "flex", width: "100%", flexWrap: "nowrap" }}>

                                                            <div
                                                                className="product-details-page-botttons-section unselectable"
                                                                style={{
                                                                    justifyContent: "flex-start",
                                                                    alignItems: "center",
                                                                    flexWrap: "nowrap",
                                                                    margin: "10px 0px 0px 20px",
                                                                }}
                                                            >
                                                                {sale && (sale.status === "pending") && accessTo && accessTo.sales ?
                                                                    <button
                                                                        onClick={onCollectPayment}
                                                                        className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                        style={{
                                                                            margin: "10px 0px 10px 0px",
                                                                            height: "40px",
                                                                            minWidth: pageWidth > 400 ? "150px" : "100px"
                                                                        }}
                                                                    >
                                                                        <img src={dollarSighDarkIcon} alt="Charge" />

                                                                        {appLanguage.en ? "Charge" : "Cobrar"}
                                                                    </button>
                                                                : null}
                                                            </div>

                                                            <div style={{ margin: "20px" }}>
                                                                <table cellPadding="10" cellSpacing="8">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap" }}>Subtotal: </td>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(sale.subtotal)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap", }}>Desc{sale.discount ? (sale.discount.type === "percentage" ? ` ${sale.discount.value}%` : "") : ""}: </td>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(sale.desc)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap" }}>ITBIS: </td>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(sale.itbis)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ textAlign: "right", fontSize: "18px", fontWeight: "400", whiteSpace: "nowrap" }}>Total: </td>
                                                                            <td style={{ textAlign: "right", fontSize: "18px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(sale.total)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fragment>
                                            : null)
                                    )
                                }
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default SaleViwePage;