import { Fragment, useContext, useEffect, useState } from "react";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { AppContext } from "../../../context/AppContext";

import Spinner from "../../../components/spinner/spinner";

const ComprobantesTable = ({
    headerHeight,
    loading,
    comprobantes,
    onClickLink,
    activeId,
    setActiveReference,
}) => {

    const {
        appTheme,
        appLanguage,
        dropDownCartOpen,
        dropDownProfileMenuOpen,
        isTouchEnabled,
    } = useContext(AppContext);

    const { desktopView } = useContext(SideMenuContext);

    const [tableHeight, setTableHeight] = useState("calc(100vh - 320px)");

    useEffect(() => {
        if (headerHeight) {
            setTableHeight(`calc(100vh - ${headerHeight + 90}px)`)
        }
    }, [headerHeight, desktopView]);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownCartOpen, dropDownProfileMenuOpen]);

    const borderDark = "2px solid rgba(255, 255, 255, 0.075)";
    const borderLight = "2px solid rgba(0, 0, 0, 0.075)";

    const border2Dark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Light = "2px solid rgba(0, 0, 0, 0.075)";

    const widthCheck = "20px";
    const widthCode = "350px";
    const widthStatus = "130px";
    const widthTotal = "130px";

    const getStatusText = (status, isActive) => {
        if (status === "notSupported") {
            return appLanguage.en ? "Not Supported" : "No Soportado"
        } else {
            if (isActive) {
                return appLanguage.en ? "Active" : "Activo"
            } else {
                return appLanguage.en ? "Inactive" : "Inactivo"
            }
        }
    }

    const getStatusBackgroundColor = (status, isActive) => {
        if (status === "notSupported") {
            return "rgba(128, 128, 128, 0.151)";
        } else {
            if (isActive) {
                return appTheme.dark ? "rgba(0, 128, 0, 0.700)" : "rgba(0, 253, 0, 0.35)";
            } else {
                return "rgba(128, 128, 128, 0.151)";
            }
        }
    }

    const isFavorite = (comprobante) => {
        if (comprobante.status === "notSupported" || (!comprobante.isActive) || !comprobante.isFavorite) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div
            className={`inventory-table-container unselectable ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
            style={{
                maxHeight: tableHeight,
                height: tableHeight,
                colorScheme: appTheme.dark ? "dark" : "light",
                borderRadius: "10px"
            }}
        >
            <div className="inventory-table-section-1" style={{ overflow: loading ? "hidden" : "auto" }}>
                {loading ?
                    <div style={{ marginTop: "50px", display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <Spinner />
                    </div>
                    : <Fragment>
                       
                            <table className="inventory-table-select-table">
                                <thead>
                                    <tr>
                                        <th className={`inventory-table-select-th inventory-table-select-td-sticky`} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? borderDark : borderLight, minWidth: widthCheck, maxWidth: widthCheck, backgroundColor: appTheme.dark ? "#405163" : "#a1b7ce" }}>
                                            <span style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}></span>
                                        </th>

                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthCode, maxWidth: widthCode }}>
                                            <p>{"Tipo de comprobante"}</p>
                                        </th>

                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthStatus, maxWidth: widthStatus }}>
                                            <p>{appLanguage.en ? "Status" : "Estado"}</p>
                                        </th>

                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthTotal, maxWidth: widthTotal, borderTopRightRadius: "10px" }}>
                                            <p>{appLanguage.en ? "Available" : "Disponibles"}</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {comprobantes ?
                                        comprobantes.map((comprobante) => {
                                            return (
                                                <tr
                                                    className={appTheme.dark ? (isTouchEnabled() ? "inventory-table-select-tr-dark-no-hover " : "inventory-table-select-tr-dark-hover") : (isTouchEnabled() ? "inventory-table-select-tr-light-no-hover" : "inventory-table-select-tr-light-hover")}
                                                    ref={comprobante.id === activeId ? setActiveReference : null}
                                                    key={comprobante.id}
                                                    onClick={() => onClickLink(comprobante)}
                                                    style={
                                                        (comprobante.id === activeId) ?
                                                            {
                                                                cursor: "pointer",
                                                                backgroundColor: "#62789036",
                                                                border: "1px solid #627890a9"
                                                            }
                                                            : { cursor: "pointer", }
                                                    }
                                                >
                                                    <td className={`inventory-table-select-td inventory-table-select-th-sticky ${appTheme.dark ? "inventory-table-select-td-sticky-dark" : "inventory-table-select-td-sticky-light"}`} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? border2Dark : border2Light, minWidth: widthCheck, maxWidth: widthCheck }}>
                                                        <span style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                                            {isFavorite(comprobante) ? "⭐" : ""}
                                                        </span>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthCode, maxWidth: widthCode }}>
                                                        <p style={{ whiteSpace: "nowrap" }}><b>{comprobante.id}</b> - {comprobante.name}</p>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthStatus, maxWidth: widthStatus }}>
                                                        <p  
                                                            style={{ 
                                                                whiteSpace: "nowrap", 
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <span className="customer-detaines-info-out-of-stock-span-cont" style={{ fontSize: "13px", color: appTheme.dark ? "white" : "black" , background: getStatusBackgroundColor(comprobante.status, comprobante.isActive), fontWeight: "500" }}>{getStatusText(comprobante.status, comprobante.isActive)}</span>
                                                        </p>
                                                    </td>

                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthTotal, maxWidth: widthTotal }}>
                                                        <p style={{ whiteSpace: "nowrap", textAlign: "center" }}>{comprobante.quantity}</p>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : null}
                                </tbody>
                            </table>
                    </Fragment>}
            </div>

            <div style={{padding: "15px"}}>
                <p>{appLanguage.en ? "For more information visit" : "Para más información visitar"}: <a className={appTheme.dark ? "settings-security-second-section-link-dark" : "settings-security-second-section-link-light"} style={{ whiteSpace: "nowrap"}} href="https://dgii.gov.do/cicloContribuyente/facturacion/comprobantesFiscales/Paginas/default.aspx" target="_blank" rel="noreferrer"> DGII: Comprobantes Fiscales </a></p>
            </div>
        </div>
    )
}

export default ComprobantesTable;