import React, { Fragment, useContext, useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";

import firebase from "../../../firebase";
import { onSnapshot, doc } from "firebase/firestore";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import editIcon from "../../../icons/signature-dark.png";

import closeLightIcon from '../../../icons/cross.png';
import closeDarkIcon from '../../../icons/cross-white.png';

import saveIcon from "../../../icons/check-dark.png";

import copyIconDark from "../../../icons/copy.png";
import copyIconLight from "../../../icons/copyLight.png";

import completedIcon from "../../../icons/sale-completed.png";
import pendingIcon from "../../../icons/sale-pending.png";
import cancelIcon from "../../../icons/sale-cancel.png";

import { uid } from "uid";
import Spinner from "../../../components/spinner/spinner";

import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import { AlertContext } from "../../../components/alert/alertContext";

const SaleMoreDetailsPage = () => {
    const { appTheme, appLanguage, isTouchEnabled, formatRationalNumber, getTimeInString } = useContext(AppContext);
    const { accountData, accessTo, authorizedUsers, setSaleListener, setAuthorizedUsersListener } = useContext(AuthContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const { alertActive, setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    // This prevent the page send a request twice to the backend
    const [generalState] = useState("0000");

    useEffect(() => {
        setSaleListener(true);
        setAuthorizedUsersListener(true);
        // eslint-disable-next-line
    }, [generalState]);

    const location = useLocation();

    const [saleId, setSaleId] = useState(null);

    const [request, setRequest] = useState("0000");


    const [loading, setLoading] = useState(false);

    const [sale, setSale] = useState(null);

    // Now I have to listeng to the product doc
    useEffect(() => {
        if (saleId && (request !== "0000")) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/sales`, saleId.id), (doc) => {
                const saleData = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete saleData.searchKeys;

                if (doc.data()) {
                    setSale(saleData);
                }
            }, err => {
                console.error(err);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [request]);

    const navigate = useNavigate();

    useEffect(() => {
        if (location.state) {
            setSaleId({
                "id": location.state.id
            });
            setRequest(uid());
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!accessTo.salesConsult) {
                navigate("/", { replace: true })
            }

            if (!accessTo.salesSup) {
                setChangeSellerActive(false);
                if (sale) {
                    setSoldBy(sale.soldBy);
                } else {
                    setSoldBy("");
                }
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        navigate(null, { replace: true, state: saleId });
        // eslint-disable-next-line
    }, [saleId]);

    const getSaleIcon = (status) => {
        switch (status) {
            case "completed":
                return completedIcon;
            case "canceled":
                return cancelIcon;
            default:
                return pendingIcon;
        }
    }

    const getSaleStatus = (status) => {
        switch (status) {
            case "completed":
                return appLanguage.en ? "Completed" : "Completa";
            case "canceled":
                return appLanguage.en ? "Canceled" : "Cancelada";
            default:
                return appLanguage.en ? "Pending" : "Pendiente";
        }
    }

    const copySaleId = () => {
        try {
            const text = sale.id;
            navigator.clipboard.writeText(text);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'The code has been copied',
                    es: 'El código ha sido copiado',
                }
            });
        } catch {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Copy error',
                    es: 'Error al copiar',
                }
            });
        }
    }

    const getDateInString = (date) => {
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const meses = appLanguage.en ? ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"] : ["Ene", "Feb", "Mar", "Abr", "Mayo", "Jun", "Jul", "Ago", "Sept", "Oct", "Nov", "Dic"];
        return `${day} ${meses[month]}, ${year}`;
    }

    const getDate = (date) => {
        const dateObj = new Date(date)
        return getDateInString(dateObj);
    }

    const getTime = (date) => {
        const dateObj = new Date(date)
        return getTimeInString(dateObj);
    }

    const getAuthUserName = (userId) => {
        if (authorizedUsers) {
            const user = authorizedUsers.find(user => user.id === userId);
            return user ? user.name : "--";
        } else {
            return "--"
        }
    }

    const isCustomerSelected = () => {
        if (sale) {
            if (sale.customerId) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const onOpenCustomer = () => {
        if (isCustomerSelected()) {
            navigate("/ver-cliente", { state: customer })
        }
    }

    const [customerId, setCustomerId] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [loadingCustomer, setLoadingCustomer] = useState(true);

    useEffect(() => {
        if (sale) {
            setCustomerId(sale.customerId);
            setSoldBy(sale.soldBy);
        }
    }, [sale]);

    useEffect(() => {
        if (customerId && accessTo) {
            if (accessTo.customers) {
                const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/customers`, customerId), (doc) => {
                    const consumerUpdated = {
                        ...doc.data(),
                        "id": doc.id,
                    };

                    delete consumerUpdated.searchKeys;

                    if (doc.data()) {
                        setCustomer(consumerUpdated);
                        setLoadingCustomer(false);
                    }
                }, err => {
                    console.error(err);
                    setLoadingCustomer(false);
                });
                return () => unsub();
            } else {
                setLoadingCustomer(false);
            }
        }
        // eslint-disable-next-line
    }, [customerId]);

    const getCustomerInfo = () => {
        if (sale && sale.invoice && sale.invoice.finalConsumerName) {
            return sale.invoice.finalConsumerName;
        } else {
            return appLanguage.en ? "End consumer (default)" : "Consumidor final (predeterminado)";
        }
    }

    const canChangeSeller = () => {
        return accessTo && accessTo.salesSup;
    }

    let menuRef = useRef();
    let pageObserverRef = useRef(null);

    const [pageWidth, setPageWidth] = useState(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return windowDimension.width;
            } else {
                try {
                    const width = pageObserverRef.current.offsetWidth
                    return width;
                } catch {
                    return windowDimension.width;
                }
            }
        } else {
            return windowDimension.width;
        }
    });

    useEffect(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (pageObserverRef.current.offsetWidth !== pageWidth) {
                        setPageWidth(pageObserverRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(pageObserverRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [pageObserverRef.current, windowDimension, isSideMenuMinimized]);

    const [changeSellerActive, setChangeSellerActive] = useState(false);

    useEffect(() => {
        if (changeSellerActive) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    if ((!alertActive) && (!loading)) {
                        setChangeSellerActive(false);
                        if (sale) {
                            setSoldBy(sale.soldBy);
                        } else {
                            setSoldBy("");
                        }
                    }
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickEditSeller = () => {
        setChangeSellerActive(true);
    }

    const onClickClose = () => {
        setChangeSellerActive(false);
        if (sale) {
            setSoldBy(sale.soldBy);
        } else {
            setSoldBy("");
        }
    }

    const [disabled, setDisabled] = useState(true);

    const [soldBy, setSoldBy] = useState("");

    useEffect(() => {
        if (sale && (sale.soldBy === soldBy)) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [sale, soldBy])

    const onChangeSoldBy = (e) => {
        setSoldBy(e.target.value);
    }

    const onSave = async () => {
        setLoading(true);

        try {
            const response = await firebase.useUpdateSaleSeller({
                "soldBy": soldBy,
                "saleId": sale.id,
                "businessId": accountData.id,
            });

            if (response.msg === "authorizedUserNotFound") {
                setAlertData({
                    type: 'error',
                    title: {
                        en: `Authorized user not found`,
                        es: `Usuario autorizado no encontrado`,
                    },
                    code: "",
                    description: {
                        en: `The salesperson you are trying to assign this sale to was not found in this business account. Please refresh the page, ensure you select a valid authorized user, and try again.`,
                        es: `El vendedor al que intentas asignar esta venta no fue encontrado en esta cuenta de negocios. Por favor, refresca la página, asegúrate de seleccionar un usuario autorizado válido y vuelve a intentarlo.`
                    }
                });
                setAlertActive(true);
            } else {
                setSale(response);

                setSoftAlertActive(true);
                setSoftAlertData({
                    type: 'sucess',
                    text: {
                        en: 'Changes saved.',
                        es: 'Cambios guardados.',
                    }
                });

                setChangeSellerActive(false);
            }

            setLoading(false);
        } catch (error) {
            const code = "error";
            setAlertData({
                type: 'error',
                title: {
                    en: `Error when trying to change the assigned seller.`,
                    es: `Error al intentar cambiar el vendedor asignado.`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "More sales details" : "Más detalles de venta"}</title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef} className={`page-choose-account-item`}>
                            {accessTo.salesConsult ? <Fragment>

                                <SellerPicker
                                    changeSellerActive={changeSellerActive}
                                    pageWidth={pageWidth}
                                    menuRef={menuRef}
                                    loading={loading}
                                    onClickClose={onClickClose} 
                                    authorizedUsers={authorizedUsers} 
                                    soldBy={soldBy}
                                    onChangeSoldBy={onChangeSoldBy} 
                                    disabled={disabled}
                                    onSave={onSave}
                                />

                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "More sales details" : "Más detalles de venta"}
                                    />
                                    : null}

                                {sale ?
                                    <Fragment>
                                        <div
                                            style={{
                                                marginTop: "25px",
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "center",
                                                minHeight: `calc(100vh - ${132 - (desktopView ? 0 : 40)}px)`,
                                                maxHeight: `calc(100vh - ${132 - (desktopView ? 0 : 40)}px)`,
                                                overflow: "auto",
                                                colorScheme: appTheme.dark ? "dark" : "light"
                                            }}
                                        >
                                            {/** Main container for seccion maxWidth: "745px" */}
                                            <div style={{ width: "100%", maxWidth: "740px" }}>
                                                {/** Sale heather */}
                                                <div className="unselectable" style={{ display: "flex", width: "100%", marginBottom: "20px" }}>
                                                    <div
                                                        className={`shoppo-cart-cust-card-div ${appTheme.dark ? "shoppo-cart-cust-card-div-dark" : "shoppo-cart-cust-card-div-light"}`}
                                                        style={{
                                                            display: "flex",
                                                            paddingLeft: "10px",
                                                            borderRadius: "100px",
                                                            margin: "0px 20px 0 20px",
                                                            width: "calc(100% - 40px)",
                                                            height: "40px",
                                                            minHeight: "36px",
                                                        }}
                                                    >
                                                        {sale ? <Fragment>
                                                            <img
                                                                style={{
                                                                    width: "25px",
                                                                    height: "25px",
                                                                    marginLeft: "0px",
                                                                }}
                                                                src={getSaleIcon(sale.status)}
                                                                alt="Status"
                                                            />

                                                            <p style={{ whiteSpace: "nowrap", fontSize: "16px", fontWeight: 500, marginLeft: "15px" }}>
                                                                {getSaleStatus(sale.status)}
                                                            </p>

                                                            <span
                                                                onClick={copySaleId}
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    padding: "6px 10px",
                                                                    marginRight: "5px"
                                                                }}
                                                                className={`sale-copy-id-successful ${isTouchEnabled() ? "sale-copy-id-successful-no-hover" : "sale-copy-id-successful-hover"}`}
                                                            >
                                                                <p style={{ marginRight: "10px" }}>{sale.id}</p>

                                                                <img
                                                                    src={appTheme.dark ? copyIconDark : copyIconLight}
                                                                    style={{ width: "16px", height: "16px" }}
                                                                    alt="Copy"
                                                                />
                                                            </span>
                                                        </Fragment> : null}
                                                    </div>
                                                </div>

                                                <div>
                                                    <div
                                                        className={`customer-sub-section-cont ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
                                                        style={{
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                            margin: "10px 20px 0 20px",
                                                            width: "calc(100% - 50px)",
                                                        }}
                                                    >
                                                        {sale ? <Fragment>

                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexWrap: "wrap",
                                                                }}
                                                            >
                                                                <div style={{
                                                                    margin: "20px",
                                                                    width: "calc(50% - 40px)",
                                                                    minWidth: "225px",
                                                                }}>
                                                                    <p><b>{appLanguage.en ? "Creation date" : "Fecha de creación"}</b>:</p>
                                                                    <p>{getTime(sale.time)} - {getDate(sale.time)}</p>
                                                                </div>

                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <div style={{ margin: "20px", maxWidth: canChangeSeller() ? "150px" : "246px" }}>
                                                                        <p><b>{appLanguage.en ? "Assigned seller" : "Vendedor asignado"}</b>:</p>
                                                                        <p
                                                                            style={{
                                                                                "display": "-webkit-box",
                                                                                "WebkitBoxOrient": "vertical",
                                                                                "WebkitLineClamp": "1",
                                                                                "lineClamp": "1",
                                                                                "overflow": "hidden",
                                                                                "textOverflow": "ellipsis"
                                                                            }}
                                                                        >
                                                                            {sale.soldBy ? getAuthUserName(sale.soldBy) : <b>--</b>}
                                                                        </p>
                                                                    </div>

                                                                    {canChangeSeller() ?
                                                                        <div>
                                                                            <button
                                                                                onClick={onClickEditSeller}
                                                                                className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                                style={{
                                                                                    margin: "0px",
                                                                                    height: "40px",
                                                                                }}
                                                                            >
                                                                                <img style={{ margin: "0px" }} src={editIcon} alt="Change Seller" />
                                                                            </button>
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>

                                                            <div style={{ margin: "20px" }}>
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    <p><b>{appLanguage.en ? "Customer" : "Cliente"}</b>:</p>

                                                                    <div className="unselectable" style={{ display: "flex", width: "100%" }}>
                                                                        {isCustomerSelected() && customer ?
                                                                            <div
                                                                                className="shoppo-cart-cust-card-div"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    borderRadius: "100px",
                                                                                    margin: "1px 0px 0 10px",
                                                                                    width: "calc(100% - 10px)",
                                                                                    justifyContent: "space-between",
                                                                                    height: "36px",
                                                                                    minHeight: "36px",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    onClick={onOpenCustomer}
                                                                                    className={`${appTheme.dark ? (isTouchEnabled() ? "shoppo-cart-cust-card-div-dark-NOHOVER" : "shoppo-cart-cust-card-div-dark-HOVER") : (isTouchEnabled() ? "shoppo-cart-cust-card-div-light-NOHOVER" : "shoppo-cart-cust-card-div-light-HOVER")}`}
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        borderRadius: "100px",
                                                                                        paddingLeft: "10px",
                                                                                        width: "100%",
                                                                                        alignItems: "center",
                                                                                        height: "36px",
                                                                                        minHeight: "36px",
                                                                                    }}
                                                                                >
                                                                                    <p
                                                                                        style={{
                                                                                            "display": "-webkit-box",
                                                                                            "WebkitBoxOrient": "vertical",
                                                                                            "WebkitLineClamp": "1",
                                                                                            "lineClamp": "1",
                                                                                            "overflow": "hidden",
                                                                                            "textOverflow": "ellipsis"
                                                                                        }}
                                                                                    >
                                                                                        {customer.id} • {customer.name}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div
                                                                                className={`shoppo-cart-cust-card-div ${appTheme.dark ? "shoppo-cart-cust-card-div-dark" : "shoppo-cart-cust-card-div-light"}`}
                                                                                style={{
                                                                                    display: "flex",
                                                                                    paddingLeft: "10px",
                                                                                    borderRadius: "100px",
                                                                                    margin: "0px 10px 0 20px",
                                                                                    width: "calc(100% - 40px)",
                                                                                    height: "36px",
                                                                                    minHeight: "36px",
                                                                                }}
                                                                            >
                                                                                {loadingCustomer && (customerId) ?
                                                                                    <p>{appLanguage.en ? "Loading..." : "Cargando..."}</p>
                                                                                    :
                                                                                    <p
                                                                                        style={{
                                                                                            "display": "-webkit-box",
                                                                                            "WebkitBoxOrient": "vertical",
                                                                                            "WebkitLineClamp": "1",
                                                                                            "lineClamp": "1",
                                                                                            "overflow": "hidden",
                                                                                            "textOverflow": "ellipsis"
                                                                                        }}
                                                                                    >
                                                                                        {getCustomerInfo()}
                                                                                    </p>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    margin: "20px",
                                                                    marginTop: "40px",
                                                                    display: "flex",
                                                                    width: "calc(100% - 40px)",
                                                                    justifyContent: "flex-end"
                                                                }}
                                                            >
                                                                <table cellPadding="10" cellSpacing="8">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap" }}>Subtotal: </td>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(sale.subtotal)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap", }}>Desc{sale.discount ? (sale.discount.type === "percentage" ? ` ${sale.discount.value}%` : "") : ""}: </td>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(sale.desc)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", whiteSpace: "nowrap" }}>ITBIS: </td>
                                                                            <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(sale.itbis)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ textAlign: "right", fontSize: "18px", fontWeight: "400", whiteSpace: "nowrap" }}>Total: </td>
                                                                            <td style={{ textAlign: "right", fontSize: "18px", fontWeight: "bold", whiteSpace: "nowrap", minWidth: "100px" }}>$ {formatRationalNumber(sale.total)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Fragment> : null}
                                                    </div>
                                                </div>
                                                <div style={{ minHeight: "20px" }} />
                                            </div>
                                        </div>
                                    </Fragment>
                                    : null}
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

const SellerPicker = ({
    changeSellerActive, 
    pageWidth, 
    menuRef, 
    loading, 
    onClickClose, 
    authorizedUsers, 
    soldBy, 
    onChangeSoldBy, 
    disabled, 
    onSave
}) => {
    const { appTheme, appLanguage } = useContext(AppContext);

    return (
        <div
            style={{
                WebkitUserSelect: "auto",
                WebkitTouchCallout: "auto",
                MozUserSelect: "auto",
                MsUserSelect: "auto",
                userSelect: "auto",
            }}
        >
            {changeSellerActive ? <div className='picker-date-lock-screen' style={{ width: pageWidth, minHeight: "calc(100vh - 65px)" }} /> : null}

            <div ref={menuRef} className={`picker-date-genaral-card ${changeSellerActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pageWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                <div className='picker-date-general-despcrition' style={{ display: "flex", justifyContent: "space-between", minHeight: "250px" }}>
                    {loading ?
                        <div style={{ marginTop: "40px" }}><Spinner /></div>
                        :
                        <Fragment>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                                <h4 style={{ marginLeft: "15px" }}>
                                    <span style={{ fontWeight: "600" }}>{appLanguage.en ? "Change assigned salesperson" : "Cambiar vendedor asignado"}</span>
                                </h4>

                                <button
                                    onClick={onClickClose}
                                    className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                                >
                                    <img
                                        className="see-details-transations-img"
                                        src={appTheme.dark ? closeDarkIcon : closeLightIcon}
                                        alt="close"
                                    />
                                </button>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        width: "calc(100% - 30px)",
                                        flexDirection: "column",
                                        margin: "10px 0px"
                                    }}
                                >
                                    <label className="filter-labels-inputs" htmlFor="soldBy">{appLanguage.en ? "Select the seller" : "Seleccione el vendedor"}:</label>
                                    <select
                                        style={{
                                            colorScheme: appTheme.dark ? "dark" : "light",
                                            background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                            fontSize: "15px",
                                            fontWeight: "500",
                                        }}
                                        className={`filter-select-input-sales ${appTheme.dark ? "dar-ter-bg" : "lig-sec-bg"} ${appTheme.dark ? "input-border-box-dark" : "input-border-box-light"}`}
                                        name="soldBy"
                                        id="soldBy"
                                        value={soldBy}
                                        onChange={e => onChangeSoldBy(e)}
                                    >
                                        {authorizedUsers ? <Fragment>
                                            {authorizedUsers.length > 0 ?
                                                <Fragment>
                                                    {authorizedUsers.filter((record) => record.status !== "removed").map((record) => {
                                                        return (
                                                            <option key={record.id} value={record.id}>{record.name}</option>
                                                        );
                                                    })}
                                                </Fragment>
                                                : null}
                                        </Fragment> : null}
                                    </select>
                                </div>

                                <button
                                    onClick={onSave}
                                    disabled={disabled}
                                    className="new-customer-bottons-button-save"
                                    style={disabled ? {
                                        backgroundColor: "#3f526698",
                                        color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                        cursor: "not-allowed",
                                        margin: "35px 20px 20px 20px",
                                        minWidth: "200px"
                                    } : {
                                        margin: "35px 20px 20px 20px",
                                        minWidth: "200px"
                                    }}
                                >
                                    <img src={saveIcon} alt="Save" />
                                    {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                </button>

                            </div>

                        </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default SaleMoreDetailsPage;